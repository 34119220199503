.container{
    width:100%;
    height:100%; 
    background-color: #3F5552;
    padding-top: 80px;
    padding-bottom: 80px;
}

.login_wrapper{
	margin: 1% 8%;
	width: 84%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1% 0;
	background-repeat: no-repeat;
	background-size: 100%;
	background-attachment: scroll;
	background-position: top center;
	background-origin: border-box;
}

.login_heading h1{
	font-weight: 500;
	font-size: 45px;
	letter-spacing: 3px;
    color: white;
}

.main_form{
	margin: 0 0 0 0;
	width: 70%;
	height: 700px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.left_form{
	width: 50%;
	height: 650px;
	border-radius: 30px 0 0 30px;
}
.left_form img{
	width: 100%;
	height: 650px;
	border-radius: 30px 0 0 30px;
}


.right_form{
	width: 50%;
	height: 650px;
	border-radius: 0 30px 30px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
    background-color: white;
}

.login_logo img{
	width: 100%;
	border-radius: 0 30px 0 0;
    margin-top: 3%;
}

.login_form form{
    margin-top: 3%;
	width: 100%;
	text-align: center;
}

.login_form form label{
	display: block;
	text-align: left;
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    line-height: 30px;
    
}

.login_form form input{
	width: 100%;
}

.login_btn{
    background-color:#FB9F00;
    border-radius: 90px;
    border: none; 
    color: white;
    padding: 8px 30px;
    font-size: 20px;
    text-align: center;
    margin-top: 3%;
}

.otheroption{
    text-align: center;
    margin-top: 10%;
}

.otheroption p{
    margin-top: 5%;
    font-size: 1em;
}

.signup_btn{
	font-size: 20px;
	padding: 8px 30px;
	border-radius: 30px;
	border: 2px solid #FB9F00;
	cursor: pointer;
	color: #FB9F00;
    width: 70%;
    margin-top: 7%;
}

a.forget_password{
	display: block;
	text-align: right;
	text-decoration: none;
	margin-top: 5px;
}

@media screen and (max-width: 1200px){
    .login_wrapper{
		margin: 1% 7%;
		width: 86%;
		margin-top: 80px;
		background-size: 120% 100%;
	}
	.login_form{
		width: 80%;
	}
	.login_heading h1{
		letter-spacing: 2px;
	}
	.main_form{
		width: 100%;
	}
}

@media screen and (max-width: 990px){	
    .login_wrapper{
		margin: 1% 6%;
		width: 88%;
		margin-top: 80px;
	}
	.login_heading h1{
		font-size: 40px;
		letter-spacing: 1px;
	}
	.main_form{
		width: 100%;
		height: 650px;
	}
	.login_form{
		width: 85%;
	}
	.left_form{
		height: 620px;
	}
	.left_form img{
		height: 620px;
	}
	.right_form{
		height: 620px;
	}
}

@media screen and (max-width: 890px){
    .login_wrapper{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		background-size: 100% 100%;
		padding: 0;
	}
	.login_heading h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.main_form{
		margin: 0;
		height: 650px;
	}
	.login_form{
		width: 90%;
	}
	.left_form{
		height: 650px;
	}
	.left_form img{
		height: 650px;
	}
	.right_form{
		height: 650px;
	}
}


@media screen and (max-width: 700px){
    .login_wrapper{
		background-size: 100% 100%;
		padding: 0;
	}
	.login_heading h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.main_form{
		margin: 0;
		height: 630px;
    }
	.left_form{ 
        height: 580px; 
    }
	.left_form img{ 
        height: 580px; 
    }
	.right_form{ 
        height: 580px; 
    }

}

@media screen and (max-width: 600px){
    .login_wrapper{
		background-size: 100% 100%;
		padding: 0;
		background: none;
	}
	.login_heading h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.main_form{
		margin-top: 2%;
		height: 650px;
		display: flex;
		flex-direction: column;
		/* background-image: url(".././images/registerImg1.jpeg"); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-attachment: scroll;
		background-position: top center;
		background-origin: border-box;
		border-radius: 30px;
	}
	.left_form{
		display: none;
	}
	.left_form img{
		height: none;
	}
	.right_form{
		width: 80%;
		height: 630px;
		border-radius: 30px;
	}
	.login_logo img{
		width: 100%;
		border-radius:30px;
	}
}


@media screen and (max-width: 480px){
    .login_wrapper{
		background-size: 100% 100%;
		padding: 0;
		background: none;
	}
	.login_heading h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.main_form{
		margin-top: 2%;
		height: 640px;
		display: flex;
		flex-direction: column;
		/* background-image: url(".././images/registerImg1.jpeg"); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-attachment: scroll;
		background-position: top center;
		background-origin: border-box;
		border-radius: 30px;
	}
	.left_form{
		display: none;
	}
	.left_form img{
		height: none;
	}
	.right_form{
		width: 80%;
		height: 620px;
		border-radius: 30px;
	}
	.login_logo img{
		width: 100%;
		border-radius:30px;
	}
}



.formBackgronContainer{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    padding-top: 5%;
    padding-bottom: 5%;
}

.backendimage{
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    text-align: center;
    width: 100%;
}

.loginBox{ 
    grid-row: 1;
    grid-column: 1;
    z-index: 7;
}

.loginBox h1{
    font-family: 'Arapey', serif;
    color: white;
    text-align: center;
}

.formContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 20px;
}

.loginleftImg img{
    max-width: 110%;
    border-top-left-radius:18px;
    border-bottom-left-radius:18px;
}

.formbox{
    margin-top: 10%;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
}

.inputBox{
    width: 100%;
    height: 35px;
    margin: 0 auto;
    /* border: none; <-- This thing here */
    border:solid 1px #ccc;
    box-shadow: 1px 2px #c8c8c8;
    border-radius: 6px;
    font-size: 20px;
}

.formbox label{
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    line-height: 15px;
}

.sighupwith{
    border:none;
    background: none;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 4%;
	display: inline-block;
}


.loginbtn{
    background-color:#FB9F00;
    width: 60%;
    border-radius: 90px;
    border: none; 
    color: white;
    padding: 3%;
    font-size: 20px;
    text-align: center;
}

.sighupbtn{
    background-color:whitesmoke;
    border-radius: 90px;
    font-size: 20px;
    border: none;
    border:solid 1px #FB9F00;
    color:  #FB9F00;
    padding: 3%;
    width: 60%;

}
