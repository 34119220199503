.details_container{
	width: 100%;
	height: auto;
}

.details{
	display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	padding: 1%;
}

.details h1{
	padding: 0.8rem 0.5rem;
}
.details p{
	padding: 0 0.5rem 0.5rem 0.5rem;
	line-height: 1.4rem;
	font-size: 1rem;
}

.week_no p{
	line-height: 0px;
}

.irrigation_form{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
}


.equal_field{
	width: 33%;
	min-width: 300px;
	padding: 1%;
}

.equal_field label{
	width: 100%;
	display: block;
	padding-bottom: 2px;
	font-size: 18px;
}

.equal_field select{
	width:100%;
	padding: 10px;
	font-size: 16px;
	box-shadow: 0 2px 5px 2px grey;
	border: none;
	border-radius: 10px;
} 

.equal_field_input{
	width:100%;
	padding: 7px;
	font-size: 16px;
	box-shadow: 0 2px 5px 2px grey;
	border: none;
	border-radius: 10px;
} 

.scroll_area{
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 300px;
	overflow-y: scroll;
	border-radius: 10px;
}
.scroll_area::-webkit-scrollbar{
	width: 15px;
}
.scroll_area::-webkit-scrollbar-track{
	background: white;
    border-radius: 35px;
}
.scroll_area::-webkit-scrollbar-thumb{
	background: rgba(62, 110, 30, 1);
    border-radius: 35px;
}
.scroll_area::-webkit-scrollbar-thumb:over{
	background: #555;
}

.stage_no{
	width: 100%;
	padding: 0 10px;
	/* border-radius: 20px 20px 0 0; */
    display: grid;
    grid-column: 1fr 1fr;
}
.stage_no > h3{
	padding: 10px 0 10px 0 ;
}

.week_no{
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 5px 0;
}

.stage_no:nth-child(odd){
	background: rgba(255, 238, 184, 0.9);
}
.stage_no:nth-child(even){
	background: rgba(252, 252, 212, 1);
}
.week_no div{
	width: 50%;
}

.irrigation_description{
    display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	border-radius: 20px;
	margin: 0;
	padding: 0;
	background: rgba(252, 252, 212, 1);
}

@media screen and (max-width: 1200px){
    .main_wrapper_item{
		margin: 80px 6% 2% 6%;
		width: 88%;
	}
	.item_details h1{
		font-size: 2rem;
		letter-spacing: 0.1rem;
		padding: 0 0 0.5rem 0;
	}
	.item_details h2{
		font-size: 1rem;
		letter-spacing: 0.030rem;
		padding: 0.5rem 0;
	}
	h3{
		font-size: 2rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.item_details h2 span{
		font-size: 1.2rem;
		letter-spacing: 0.040rem;
		padding: 0.5rem 0.7rem;
	}
	.tab_btn{
		padding: 0.6rem 1.3rem;
		font-size: 1.2rem;
		letter-spacing: 0.150rem
	}

}

@media screen and (max-width: 990px){	
    .main_wrapper_item{
		margin: 80px 4% 2% 4%;
		width: 92%;
	}
	.tab_btn2{
		padding: 0.6rem 1.7rem;
		font-size: 1.5rem;
		letter-spacing: 0.1rem;
	}
}


@media screen and (max-width: 890px){
    .main_wrapper_item{
		margin: 80px 1% 2% 1%;
		width: 98%;
	}
	.item_image{
		width: 25%;
		max-height: 300px;
	}
	.item_details{
		width: 70%;
		height: auto;
		padding: 1rem 1rem;
	}
	.item_details h1{
		font-size: 2rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.item_details h2{
		font-size: 1.3rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	h3{
		font-size: 2rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.item_details h2 span{
		font-size: 1.2rem;
		letter-spacing: 0;
		padding: 0.3rem 0.7rem;
	}
	.tab_btn{
		padding: 0.5rem 1rem;
		font-size: 1.1rem;
		letter-spacing: 0.1;
	}
	.tab_btn2{
		padding: 0.5rem 1.3rem;
		font-size: 1.3rem;
		letter-spacing: 0.1rem;
	}
}


@media screen and (max-width: 700px){
    .main_wrapper_item{
		margin: 80px 1% 2% 1%;
		width: 98%;
	}
	.top_item{
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.tab_btn2{
		padding: 0.6rem 1.7rem;
		font-size: 1.5rem;
		letter-spacing: 0.1rem;
	}
	.item_image{
		width: 50%;
	}
	.item_details{
		width: 100%;
		height: auto;
		padding: 1.5% 5%;
	}
	.item_details h1{
		font-size: 40px;
	}
	.item_details h2{
		font-size: 22px;
	}

	.item_details h2 span{
		font-size: 20px;
	}

	h3{
		font-size: 1.5rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.middle_tabs{
		flex-wrap: wrap;
	}
	.tab_btn{
		width: 60%;
		margin: 2% auto;
	}
	.equal_field{
		width: 100%;
		padding: 1%;
	}
	.stage_no{
		display: flex;
		flex-direction: column;
	}
	.stage_no h3{
		font-size: 1.3rem;
	}
	.stage_no p{
		font-size: 1.3rem;
		line-height: 2px;
	}
	.week_no{
		display: flex;
		height: auto;
		flex-direction: column;
	}
	.week_no div{
		width: 100%;
	}

}

@media screen and (max-width: 600px){
    .main_wrapper_item{
		margin: 80px 3% 2% 3%;
	}
	.item_image{
		width: 60%;
	}

	.item_details h2{
		font-size: 1.2rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.item_details h2 span{
		font-size: 1.2rem;
		letter-spacing: 0;
		padding: 0.3rem 1rem;
	}
	.middle_tabs{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 2rem 0;
	}
	.details_all{
		top: 23rem;
	}
	.details_images{
		flex-direction: column;
		justify-content: center;
	}
	.details_images img{
		width: 70%;
	}
	.bottom_tabs{
		flex-direction: column;
	}
	.tab_btn2{

		padding: 0.8rem 3rem;
		font-size: 1.6rem;
		margin: 20px 0;
	}

}


.submitbtn{
    background: #EEA21B;
    border: 2px solid #EEA21B;
    box-sizing: border-box;
    border-radius: 50px;
    color: white;
    padding: 2% 6%;
    /* margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%; */
}
