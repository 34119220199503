.userInfoEditContainer{
    background-color: #FFF7CD;
    width: 70%;
    padding: 3% 5%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.userTitle{
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding-bottom: 3%;
}

.userTitle h1{
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.userTitle img{
    width: 200px;
    height: 200px;
    border-radius: 50%;

}

.gridtwo{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
}

.buttonContainer{
    text-align: center;
}

.submitbtn{
    font-weight: 400;
    border: none;
    background-color: #FB9F00;
    font-size: 16px;
    text-align: center;
    border-radius: 25px ;
    color: white;
    padding: 2% 5%;
    margin: 3%;
   
}

.cancelbtn{
    font-weight: 400;
    border: 2px solid #FB9F00;
    background-color: white;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    border-radius: 25px ;
    color: #FB9F00;
    padding: 2% 5%;
    margin: 3%;
}


/********************* Update password ***********************/

.UpdatePasswordContainer{
    margin-top: 80px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}