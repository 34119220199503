.main_wrapper_item{
    margin: 90px 10% 10% 10%;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    background-color: #DFE7B4;
    border-radius: 30px;
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.top_item{
	width: 98%;
	height: auto;
	margin: 1%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.item_image{
	width: 60%;
	max-height: 350px;
	text-align: center;
    padding: 3%;
}
.item_image img{
	width: 100%;
	max-height: 350px;
	border-radius: 30px;
}

.item_details{
	width: 65%;
	height: auto;
	padding: 1rem 2rem;
}

.item_details h3{
	font-family: 'Arapey', serif;
    font-size: 40px;
    color: #FB9F00;
    line-height: 70px;
}
.item_details p{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.item_details span{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    padding: 0.3rem 0.3rem;
}

.middle_tabs{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 4% ;
}

.tab_btn{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    padding: 0.1rem 2rem;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	background-color: #3F5552;
	color: white;
	border: none;
    border-radius: 90px;
	cursor: pointer;
    margin: 3%;
}

.tab_btn:hover{
	background-color: rgba(63, 85, 82, 0.7);
}

.tabs_toggle{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    padding: 0.1rem 2rem;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	background-color: white;
	color:  #3F5552;
	border: none;
    border-radius: 90px;
	cursor: pointer;
    margin: 3%;
    border: 3px solid #3F5552 ;
}

/* under buttom */
.bottom_tabs{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 70%;
	height: auto;
	margin: 1%;
}

.tab_btn2{
	padding: 0.8rem 2rem;
	font-size: 1rem;
	letter-spacing: 0.2rem;
	background-color: rgba(250, 169, 5, 1);
	color: white;
	border: none;
	border-radius: 50px;
	cursor: pointer;
}

.tab_btn2:hover{
	background-color: white;
    color: rgba(250, 169, 5, 1);
    border: 3px solid rgba(250, 169, 5, 1) ;
}


.active_btn2{
	padding: 0.8rem 2rem;
	font-size: 1rem;
	letter-spacing: 0.2rem;
	background-color: white;
	color: rgba(250, 169, 5, 1);
	border: none;
	border-radius: 50px;
    border: 3px solid rgba(250, 169, 5, 1) ;
}

.active_btn2:hover{
	background-color: rgba(250, 169, 5, 1) ;
	color: white;
    text-decoration: none;
}

.scroll_area{
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 300px;
	overflow-y: scroll;
}

/* .sectionOneContainer{
    display: grid;
    grid-template-columns: 4fr 5fr;
}
.cropPageImageContainer{
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.cropPageImage{
    width: 350px;
    border-radius: 30px;
    
}

.titleContent h3{
    font-family: 'Arapey', serif;
    font-size: 40px;
    color: #FB9F00;
    line-height: 70px;
}

.titleContent p{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.titleContent span{
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
}

.cropButton{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    align-items: center;
    text-align: center;
    background-color: #3F5552;
    color: white;
    border-radius: 90px;
    margin: 5% 10%;
    
}

.cropButton:active{
    font-family: 'Quicksand', sans-serif;
    background-color: white;
    color: #3F5552;
    border: 2px solid #4A5E58;
}

.cropInfoButton{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 4% ;
    
}

.decisionContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.NotMyVegbtn{
    background: #FFFFFF;
    opacity: 0.9;
    border: 2px solid #EEA21B;
    box-sizing: border-box;
    border-radius: 90px;
    color: #EEA21B;
    padding: 4%;
    margin: 5%;
}

.AddToGarden{
    background: #EEA21B;
    opacity: 0.9;
    border: 2px solid #EEA21B;
    box-sizing: border-box;
    border-radius: 90px;
    color: white;
    padding: 4%;
    margin: 5%;
} */


@media screen and (max-width: 990px){	
    .main_wrapper_item{
        margin: 80px 6% 2% 6%;
    }

}
/********************* crop page **********************/


/* ################### Start cropBase ############ */
@media screen and (max-width: 990px){	
.CropBaseContent{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
}