.growingAndKc{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.databox{
    padding-left: 10%;
    padding-right: 10%;
}

.calculateContainer{
    text-align: center;
}
.calculateContainer button{
    width: 50%;
}

.tempContaner{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}