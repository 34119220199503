.main_wrapper_item{
    margin: 90px 10% 2% 10%;
	width: 80%;
	height: auto;
	background-color: #DFE7B4;
	border-radius: 50px;
	padding: 1% 2%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.top_item{
	width: 98%;
	height: auto;
	margin: 1%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.item_image{
	width: 60%;
	/* max-height: 350px; */
	text-align: center;
    padding: 2%;
}

.item_image img{
	width: 100%;
	min-width: 180px;
	max-height: 350px;
	border-radius: 30px;
	border: 1px solid lightgrey;
}

.item_details{
	width: 70%;
	height: auto;
	padding: 1.5% 5%;
}

.item_details h3{
	font-family: 'Arapey', serif;
    font-size: 40px;
    color: #FB9F00;
    line-height: 50px;
}

.item_details p{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}

.item_details span{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    padding: 0.3rem 0.3rem;
}


/* middle button  */

.middle_tabs{
    display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 98%;
	height: auto;
	margin: 2%;
	flex-wrap: wrap;
}

.tab_btn{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    padding: 0.5rem 2.5rem;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	background-color: #3F5552;
	color: white;
	border: none;
    border-radius: 90px;
	cursor: pointer;
    /* margin: 3%; */
}

.tab_btn:hover{
	background-color: rgba(63, 85, 82, 0.7);
}

.tabs_toggle{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    padding: 0.5rem 2rem;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	background-color: white;
	color:  #3F5552;
	border: none;
    border-radius: 90px;
	cursor: pointer;
    margin: 3%;
    border: 3px solid #3F5552 ;
}

/* under buttom */
.bottom_tabs{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 70%;
	height: auto;
	margin: 1%;
}

.tab_btn2{
	padding: 0.8rem 2rem;
	font-size: 1.2rem;
	letter-spacing: 0.2rem;
	background-color: rgba(250, 169, 5, 1);
	color: white;
	border: none;
	border-radius: 50px;
	cursor: pointer;
}

.tab_btn2:hover{
	background-color: white;
    color: rgba(250, 169, 5, 1);
    border: 3px solid rgba(250, 169, 5, 1) ;
}


.active_btn2{
	padding: 0.8rem 2rem;
	font-size: 1rem;
	letter-spacing: 0.2rem;
	background-color: white;
	color: rgba(250, 169, 5, 1);
	border: none;
	border-radius: 50px;
    border: 3px solid rgba(250, 169, 5, 1) ;
}

.active_btn2:hover{
	background-color: rgba(250, 169, 5, 1) ;
	color: white;
    text-decoration: none;
}

/* detail area */

.details{
    display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 94%;
	height: 100%;
	margin: 1%;
	overflow-y: scroll;
    margin-left: auto;
    margin-right: auto;
}

.details::-webkit-scrollbar{
	width: 15px;
    border-radius: 20px;
}
.details::-webkit-scrollbar-track{
	background: #f1f1f1;
}
.details::-webkit-scrollbar-thumb{
	background: #3F5552;
}
.details::-webkit-scrollbar-thumb:over{
	background: #555;
}

.scroll_area{
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	height: 300px;
	overflow-y: scroll;
	margin-left: auto;
	margin-right: auto;
}

.scroll_area::-webkit-scrollbar{
	width: 15px;
}
.scroll_area::-webkit-scrollbar-track{
	background: white;
	border-radius: 90px;
}
.scroll_area::-webkit-scrollbar-thumb{
	background: rgba(62, 110, 30, 1);
	border-radius: 90px;
}
.scroll_area::-webkit-scrollbar-thumb:over{
	background: #555;
}

.nodata{
	display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    width: 300%;
	height: 30px;
	margin: 1%;
}

/* Irrigation */
.irrigation_form{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	margin: 0 3%;
	flex-wrap: wrap;
}

/* Pest & Disease */
.title{
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    padding-left: 3%;
}

.pestInfo{
    display: grid;
    grid-template-columns: 3fr 2fr;
}

.pestName{
    font-size: 25px;
}

.pestInfo img{
    height: 180px;
    border-radius: 20px;
}

.pestInfoImage{
    text-align: center;
    margin-top: 3%;
}

/* comment component */

.messagesAllContainer{
    margin-left: 4%;
    margin-right: 4%;
}

.comments_section{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 98%;
	margin: 0 auto;
}
.comments{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	background: rgba(255, 238, 184, 0.9);
	width: 100%;
	padding: 1%;
	margin-bottom: 1%;
	border-radius: 15px;
}
.comment_profile{
	width: 20%;
	height: auto;
	text-align: center;
}
.comment_profile img{
	border-radius: 50%;
}
.comment_body{
	width: 80%;
	height: auto;
}
.leave_comment{
	width: 100%;
	height: auto;
}
.leave_comment form textarea{
	width: 100%;
	height: 5em;
	border-radius: 10px;
	font-size: 1.5em;
	padding: 1%;
	border: none;
}



@media screen and (max-width: 1200px){
    .main_wrapper_item{
		margin: 90px 10% 2% 10%;
	}

	.tab_btn{
		padding: 0.6rem 1.3rem;
		font-size: 1.2rem;
		letter-spacing: 0.150rem
	}
}


@media screen and (max-width: 990px){	
    .main_wrapper_item{
		margin: 90px 4% 2% 4%;
		width: 92%;
	}
	.tab_btn2{
		padding: 0.6rem 1.7rem;
		font-size: 1.5rem;
		letter-spacing: 0.1rem;
	}
}

@media screen and (max-width: 890px){
    .main_wrapper_item{
		margin: 90px 4% 2% 4%;
	}
	.item_image{
		width: 60%;
		max-height: 300px;
	}
	.item_details{
		width: 70%;
		height: auto;
		padding: 1rem 1rem;
	}


	.item_details p{
		font-family: 'Quicksand', sans-serif;
		font-weight: 700;
		font-size: 20px;
		line-height: 20px;
	}
	.tab_btn{
		padding: 0.5rem 1rem;
		font-size: 1.1rem;
		letter-spacing: 0.1;
	}
	.tab_btn2{
		padding: 0.5rem 1.3rem;
		font-size: 1.1rem;
		letter-spacing: 0.1rem;
	}
}

@media screen and (max-width: 700px){
    .main_wrapper_item{
		margin: 90px 4% 2% 4%;
	}
	.top_item{
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.item_image{
		width: 60%;
		max-height: 350px;
		margin: 1rem 0;
	}

	.item_details{
		width: 100%;
		padding: 1rem 1rem;
	}

	.item_details p{
		font-family: 'Quicksand', sans-serif;
		font-weight: 700;
		font-size: 18px;
		line-height: 20px;
	}

	.middle_tabs{
		justify-content: flex-start;
		align-items: flex-start;
	}
	.tab_btn{
		padding: 0.8rem 1.5rem;
		font-size: 1.2rem;
		letter-spacing: 0.2rem;
		margin: 1rem 1rem;
	}
	.details h1{
		font-size: 2rem;
	}
	.details p{
		line-height: 1.4rem;
		font-size: 1.3rem;
		line-height: 25px;
	}
	.details_images img{
		width: 40%;
	}
	.tab_btn2{
		padding: 0.8rem 1.5rem;
		font-size: 1.2rem;
		letter-spacing: 0.1rem;
	}
}

@media screen and (max-width: 600px){
    .main_wrapper_item{
		margin: 90px 3% 2% 3%;
	}
	.item_image{
		width: 90%;
	}

	.middle_tabs{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 2rem 0;
	}
	.details_all{
		top: 23rem;
	}

    .pestInfo{
        display: grid;
        grid-template-columns: 1fr;
    }

	.details_images{
		flex-direction: column;
		justify-content: center;
	}
	.details_images img{
		width: 70%;
	}
	.bottom_tabs{
		flex-direction: column;
	}
	.tab_btn2{
		padding: 0.8rem 3rem;
		font-size: 1.2rem;
		margin: 20px 0;
	}
}