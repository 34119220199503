.weatherContainer{
    margin-top: 80px;
    margin-left: 3%;
    margin-right: 3%;
}



.locationSetting{
    margin: 3%;
    display: grid;
    grid-template-columns: 1fr 3fr;
}


.locationSelect{
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    background-color: #FFF7CD;
    border-radius: 25px;
    padding: 3%;
}

.IBMWeatherContent{
    text-align: center;
}