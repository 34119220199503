/******************** banner area ***************/
.Hero_section {
    margin: 80px 7% 2% 7%;
    background-size: 100% 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #fff3d7;
    border-radius: 30px;
}

.left_area {
    margin: 20px;
    width: 50%;
}

.left_area h1 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 1px;
    padding-bottom: 20px;
}

.left_area p {
    font-size: 16px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

a.btn_hero {
    text-decoration: none;
    padding: 15px 50px;
    border: 3px solid green;
    border-radius: 50px;
    color: black;
    font-weight: 700;
}

a.btn_hero:hover {
    border-radius: 50px;
	padding: 15px 50px;
    background: green;
    color: white;
}


.right_area {
    width: 50%;
    /* height: 70%; */
    margin-left: 20px;
    margin-right: 20px;
}

.right_area img {
    border-radius: 20px 20px 100px 20px;
    width: 100%;
    height: 450px;
}

.hero_social_links {
    padding: 15px 0;
    margin-top: 50px;
}

.hero_social_links i {
    font-size: 30px;
    color: blue;
    border-radius: 5px;
    z-index: 10;
}

.hero_social_links a {
    padding: 0 20px;
}

/* ************************* About Section ****************** */

.about_section {
    margin: 0 8%;
    display: flex;
    justify-content: center;
    border-radius: 30px;
}

.about_image {
    margin: 1% 1%;
    width: 48%;
    height: auto;
    float: left;
}

.about_image_1 {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.content_area {
    width: 48%;
    margin: 1% 1%;
}

.about_content {
    padding: 0% 1%;
    border-radius: 20px;
    width: 100%;
    height: auto;
    float: right;
}

.about_content h1 {
    color: orange;
    font-weight: bold;
    line-height: 40px;
    padding: 10px 10px 0 10px;
    text-transform: uppercase;
}

.divider_about {
    border: 3px solid #2fe7eb;
    width: 110px;
    margin-left: 10px;
    border-radius: 10px;
}

.about_content h2 {
    font-size: 18px;
    font-weight: bold;
    padding: 15px 0px 10px 10px;
}

.about_content p {
    line-height: 24px;
    font-size: 16px;
    text-align: justify;
    padding: 10px 10px;
}

/*********************** our services *****************************/

.our_services{
	padding: 10px;
	margin:1% 7%;
	text-align: center;
	height: auto;
	display: block;
}

.our_services h1 {
	text-align: center; 
	font-weight:bold;
	line-height: 40px;
	padding: 10px 10px 0 10px;
	text-transform: capitalize;
}

.divider_services {
	border: 3px solid #2016db;
	width: 150px;
	margin-left: 10px;
	border-radius: 10px;
	margin:0 auto;
}

.our_services p {
	font-size: 20px;
	font-weight: 500;
	text-align: center; 
	padding: 20px 0;
}

.service_item {
	float: left;
	width:26%; 
	height:auto; 
	margin: 2% 3%;
	padding:5px;
	border-radius:10px;
}

.service_item:hover {
	box-shadow: 0px 0px 2px 5px #f1f2e9 inset;
}

.service_item img{
	width: 75%;
	padding: 5px;;
}

.service_item p {
	font-size: 16px;
	text-align: justify;
	line-height: 24px;
	padding: 5px;
}

.service_item h2{
	padding: 20px 0 0 0;
	text-transform: capitalize;
}

/******************************* How it wrok Section ****************************/

.Gridlove_section{
	display: block;
	padding:1% 1%;
	height: auto;
	margin: 1% 8%; 
	border-radius: 30px;
}

.number-title h1{
	padding: 10px 0px 10px 80px;
	font-weight:bold;
	line-height: 40px;
}

.description_sidelove p{
	line-height:25px;
}


.left_girdlove{
	width: 50%;
	height: auto;
	display: block;
	float: left;
}

.circle_sidelove{
	margin:0px;
	padding:0px;
	width:50px;
	height:50px;
	color:white;
	background-color:#e2bb38;
	border:1px solid white;
	border-radius:50%;
	text-align:center;
	font-weight:700;
	line-height:50px;
}

.description_sidelove{
	margin-left: 80px;
	margin-top: -45px;
	margin-bottom: 20px;
	text-align: justify;
}

.main_grid_love{ 
	margin: 30px 0;
}

.right_sidelove{
	float:right;
	width: 45%;
    margin-left: auto;
    margin-right: auto;
}

.right_sidelove img{ 
	width: 500px; 
	height: 500px;
}


/******************************* Our Team ************************************/

.our_team{
	width: 84%;
	text-align: center;
	padding: 1% 1%;
	margin:1% 8%;
	border-radius:30px;
}

.our_team h1{
	padding:10px 10px 0 10px;
}

.divider_team {
	border: 3px solid #2016db;
	width: 100px;
	margin: 0 auto;
	border-radius:10px;	
}

.profile_area {
	padding: 10px 0 0px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.main_profile {
	/* margin: 3%; */
	/* width: 25%; */
	/* padding:1%; */
	
}

.profile_image img {
	border-radius: 90%;
}

.profile_name{
	font-weight:bold;
	font-size: 18px;
	padding: 10px 5px 5px 5px;
}

.profile_designation{
	font-size: 16px;
	padding:5px;
}

.profile_social_links{
	padding: 10px;
}

.profile_social_links span{
	padding: 0px 10px;
}


@media screen and (max-width: 1200px) {
    .Hero_section {
        margin: 80px 6% 2% 6%;
    }

    .left_area h1 {
        line-height: 40px;
        letter-spacing: 1px;
        padding-bottom: 18px;
    }

    .left_area p {
        font-size: 15px;
        padding-bottom: 18px;
        margin-bottom: 18px;
    }

    .right_area {
        width: 50%;
        margin: 20px;
    }

    .hero_social_links {
        padding: 15px 0;
        margin-top: 50px;
    }

    a.btn_hero {
        padding: 10px 30px;
    }

    .hero_social_links i {
        font-size: 26px;
    }

    /* ************************* About Section ****************** */

    .about_section {
        margin: 0 6%;
    }

    .about_image {
        margin: 0;
        width: 50%;
    }

    .content_area {
        width: 50%;
        margin: 0;
    }

    .about_content {
        padding: 2%;
    }

    .about_content h1 {
        line-height: 30px;
        font-size: 30px;
        padding: 8px;
    }

    .about_content h2 {
        font-size: 16px;
        font-weight: bold;
        padding: 15px 0px 10px 10px;
    }

    .about_content p {
        line-height: 24px;
        font-size: 16px;
        text-align: justify;
        padding: 5px;
    }

    /*************** services *********************/
	.our_services{
		margin:1% 6%;
		text-align: center;
	}

	.our_services p{
		padding-top: 16px;
	}
	
	.profile_area {
		padding-top: 15px;
	}

    /**************** how it work**********************/
	.Gridlove_section{
		margin: 1% 4%; 
	}
	
	.number-title h1{
		font-size: 30px;
		line-height: 35px;
	}

	.description_sidelove p{
		line-height:22px;
	}
	
    /************** our team **********************/
	.our_team{
		width: 88%;
		margin:1% 6%;
	}


}




@media screen and (max-width: 990px) {
    .Hero_section {
        margin: 80px 4% 2% 4%;
    }

    .left_area h1 {
        font-size: 30px;
        letter-spacing: 0px;
        padding-bottom: 16px;
    }

    .left_area p {
        padding-bottom: 16px;
        margin-bottom: 16px;
    }

    .right_area {
        width: 50%;
        margin: 20px;
    }

    .hero_social_links {
        padding: 10px 0;
        margin-top: 40px;
    }

    .hero_social_links i {
        font-size: 24px;
    }

    /************************ about section **************/

    .content_area {
		display: block;
		width: 100%;
		margin: 1% 0 2% 0;
	}

	.about_image{
		width: 100%;
		text-align: center;
	}

	.about_image_1{
		width: 100%;
		margin-bottom: 10px;
	}
	
	.about_section{
		display: block;
		margin: 0 4%

	}
	
	.about_content{
		width: 100%;
		margin: 2%;
	}

    /**************** services *********************/
	.our_services{
		margin:1% 0%;
	}
	
	.service_item {
		width:27%; 
		margin: 2% 3%;
	}

    /***************** how it work ********************/
	.Gridlove_section{
		margin: 1% 2%;
		padding: 2%;		
	}
	
	.main_grid_love{
		width: 100%;
	}
	
	.left_girdlove{
		width: 100%;
	}

	.description_sidelove{
		margin-left: 60px;
		margin-top: -45px;
		margin-bottom: 20px;
		text-align: justify;
	}
		
	.description_sidelove{
		width: 90%;
	}

	.right_sidelove{
		width: 96%;
		text-align: center;
		margin:2% 0;
	}

	.right_sidelove img{ 
		width: 60%; 
        height: 60%;
	}

	
    /************* our team**********************/
	.our_team{
		width: 92%;
		margin:1% 4%;
	}
	
	.profile_image img {
		width: 90%;
	}	

}

@media screen and (max-width: 890px) {
    .Hero_section {
        border-radius: 30px;
        margin: 80px 4% 2% 4%;
        flex-direction: column;
        height: auto;
        /* background-image: none; */
        background-color: #fff3d7;
        border-radius: 30px;
    }

    .left_area {
        order: 2;
        flex: block;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .left_area h1 {
        text-align: center;
        letter-spacing: 1px;
        padding-bottom: 25px;
    }

    .left_area p {
        font-size: 18px;
        text-align: center;
    }

    a.btn_hero {
        margin-left: 35%;
    }

    a.btn_hero:hover {
        padding: 10px 40px;
    }

    .right_area {
        order: 1;
        width: 100%;
        margin-left: 12%;
    }

    .right_area img {
        border-radius: 10%;
        width: 90%;
        height: auto;
    }

    .hero_social_links {
        text-align: center;
    }

    .hero_social_links a {
        padding: 10px;
        background: none;
        border: none;
    }
    /**************** about section *******************/
	/* .about_section{
		margin: 0 4%;
	} */

	.about_image{
		width: 90%;
	}

	.about_image_1{
		width: 80%;
		border-radius: 20px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.content_area {
		display: block;
		width: 95%;
		margin: 2% 0 2% 0;
	}
	
	.about_content{
		width: 95%;
		margin: 0;
	}

    /**************** services *********************/
	.our_services{
		margin:1% 3%;
	}
	
	.service_item {
		float: left;
		width: 28%; 
		height:auto; 
		margin: 2% 2%;
		padding:2px;
		border-radius:10px;
	}

	.service_item p {
		font-size: 14px;
		padding: 2px;
	}

	.service_item h2{
		font-size:	20px;
	}

    /**************our team*********************/
	.our_team{
		width: 94%;
		margin:1% 3%;
	}


	.profile_image img {
		width: 90%;
	}	
}

@media screen and (max-width: 700px){

    /**************** services *********************/

    .our_services{
		margin:1% 3%;
	}

	.service_item {
		display: block;
		width:90%; 
		margin:3% 3%;
		/* padding: 2%; */
	}

	.service_item img{
		width: 50%;
		padding: 1px;;
	}

	.service_item p {
		font-size: 16px;
	}

	.service_item h2{
		font-size: 25px;
	}

    /***************** how it work *********************/

    .Gridlove_section{
		margin: 1% 4%;
	}


    .circle_sidelove{
        margin:0px;
        padding:0px;
        width:40px;
        height:40px;
        line-height:40px;
    }


    /***************** our team *********************/
	.our_team{
		width: 94%;
		margin: 1% 3%;
	}
	
	.profile_image img {
		width: 90%;
	}


}


@media screen and (max-width: 480px){

    .Gridlove_section{
		margin: 1% 3%;
		padding: 2%;		
	}
	
    .description_sidelove p{
       font-size: 15px;

    }

	.main_grid_love{
		width: 90%;
	}
	
	.left_girdlove{
		width: 90%;
	}

	.description_sidelove{
		margin-left: 55px;
		margin-top: -40px;
		margin-bottom: 30px;
		text-align: justify;
	}
		
	.description_sidelove{
		width: 90%;
	}

	.right_sidelove{
		width: 96%;
		text-align: center;
		margin:2% 0;
	}

	.right_sidelove img{ 
		width: 80%; 
        height: 80%;
	}


}