*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
}



.ck-editor__editable_inline {
    min-height: 300px;
}

h1,h2,h3{
    font-family: 'Arapey', serif;
}

p,h5,label{
    font-family: 'Quicksand', sans-serif;
}

body, html {
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

