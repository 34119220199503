.langagebar{
    z-index: 10;
    position: fixed;
    padding: 1%; 
    right: 3%; 
}

.langagedropdown{
    /* position: relative; */
    background-color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}

