.inputContainer{
    width: 60%;
    padding: 3%;
    /* border: 4px solid gray; */
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    background-color: #FFF7CD;
}

.charContainer{
    /* padding: 3% 5% 0% 5%; */
}
.dispalyContainer{
    padding-top: 3%;
}

.chartBtn{
    border: 2px solid #3F5552;
    color: #3F5552;
    padding: 1% 2% ;
    background-color: white;
    border-radius: 40px;
    margin: 1%;
}

.cardsBtn{
    border: none;
    color: white;
    padding: 1% 2%  ;
    background-color:  #3F5552;
    border-radius: 40px;
    margin: 1%;
}

.annualContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.annualConfirmBtn{
    background-color: #FB9F00;
    border: none;
    padding: 2%;
    border-radius: 50px;
    color: white;
}

.bottomspace{
    height: 80px;
}

.graphBoarder{
    border: 8px solid #DFE7B4;
    padding: 3%;
    margin: 3%;
    border-radius: 20px;
}