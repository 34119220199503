

/************************** forgotpassword ******************************/

.forgetpassword_Container{
	margin-top: 10%;
    text-align: center;
}

.forgetpassword_Container p{
    font-size: 20px;
}

.forgetPasswordBox{
    padding: 3% 3% 5% 3%;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 2%;
    background-color: #3F5552;
    text-align: center;
    border-radius: 10px;
}

.emailInputBox{
    margin-top: 4%;
    font-size: 20px;
    margin-bottom: 4%;
}

.emailInputBox input{
    width: 60%;
    border-radius: 5px;
    padding-left: 5px;
    
}

.emailInputBox label{
    color: white;
    padding-right: 10px;
}

.sendEmailbtn{
    color: white;
    background-color: #FB9F00;
    padding: 1% 8%;
    border: none;
    border-radius: 25px;
    font-size: 20px;
}

.sendEmailbtn:disabled{
    border: 2px solid #FB9F00;
    background-color: #f8d18e;
   
}

.forgotpasswordback{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
    height: 100vh;
}

.ResetPasswordInputBox{
    margin-top: 3%;
    font-size: 20px;
    margin-bottom: 4%;
    margin-right: 5%;
    text-align: right;
}


.ResetPasswordInputBox input{
    width: 60%;
    border-radius: 5px;
    padding-left: 5px;
    margin-bottom: 4%;
    
}

.ResetPasswordInputBox label{
    color: white;
    padding-right: 10px;
}

@media screen and (max-width: 990px){
    .logoimage{
        width: 400px;
    }
    .ResetPasswordInputBox{
        text-align: center;
        margin-right: 0%;
        
    }
    .ResetPasswordInputBox input{
        width: 70%;
    }
}



@media screen and (max-width: 780px){
    .forgetpassword_Container{
        margin-top: 20%;
        margin-left: 4%;
        margin-right: 4%;
    }
    .logoimage{
        width: 400px;
    }

    .forgetPasswordBox{
        width: 90%;
        margin-top: 5%;
        padding-bottom: 7%;
    }

    
}

@media screen and (max-width: 450px){
    .forgetpassword_Container{
        margin-top: 30%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .logoimage{
        width: 300px;
    }

    .forgetPasswordBox{
        width: 90%;
        margin-top: 8%;
        padding-bottom: 7%;
    }
}