.profileCardContainer{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    border: 5px solid #e2bb38;;
    background-color: #fff3d7;
    border-radius: 20px;
    padding: 2%;
}

.cardChild{
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.cardChild img{
    width: 300px;
}

.cropCards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
}

.cropimg {
    float: center;
    width:  150px;
    height: 150px;
    object-fit: cover;
}

.cropComponent{
    display: grid;
    grid-template-rows: 3fr 1fr;
    text-align: center;
}

@media only screen and (max-width: 980px){
    .profileCardContainer{
        width: 90%;
        padding: 1%;
    }

    .cardChild{
        display: grid;
        grid-template-columns: 1fr;
    }

    .cropCards{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }

    .cropimg {
        width:  250px;
        height: 250px;
    }
}