.NavbarContainer{
    width: 100%;
    height: 80px;
    display: flex;
    background-color: white;
    position: fixed;
    top:0;
    z-index: 10;
}

.NavbarContainer .rightNav{
    flex: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5%;
}

.NavbarContainer .rightNav .links{
    max-height: 80px;
}

.NavbarContainer .rightNav button{
    display: none;
}

.NavbarContainer .rightNav #hidden{
    display: flex;
}

.NavbarContainer .rightNav .links a{
    text-decoration: none;
    font-size: 20px;
    margin-right: 20px;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
}

.NavbarContainer .rightNav .links a:hover{
    text-decoration: none;
    color: white;
	padding: 25px 13px;
	background: darkgreen;
	transition: all .5s ease;
}

.NavbarContainer .leftNav{
    flex: 40%;
    justify-content: center;
    align-items: center;
}

.logoImage{
	width: 250px;
    margin-top: 10px;
}

@media only screen and (max-width: 850px){
    .NavbarContainer .rightNav .links a{
        font-size: 18px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 750px){
    .NavbarContainer .rightNav .links a{
        font-size: 18px;
        margin-right: 10px;
    }
}



@media only screen and (max-width: 980px){
    .NavbarContainer .rightNav button{
        display: flex;
    }

    .NavbarContainer .rightNav .links{
        display: none;
    }

    .NavbarContainer .rightNav #hidden {
        position:absolute;
        left: 0px;
        top:80px;
        width: 100%;
		max-height: 100vh;
        background: #DFE7B4;
        z-index: 20;
        align-items: center;
        display: flex;
        flex-direction: column;
        transition: all .5s ease;
        text-align: center;
        
    }

    .NavbarContainer .rightNav #hidden a{
        margin: 0px;
        font-size: 25px;
        color: black;
        display:inline-block;
        width: 100%;
        padding: 5%;
    }

    .NavbarContainer .rightNav #hidden a:hover{
        display: block;
        background-color:  #FFF6C8;
    }
    

    .NavbarContainer .rightNav button{
        border: none;
        font-size: 25px;
        background-color: white;
    }
}

@media only screen and (max-width: 980px){
    
}