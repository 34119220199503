.forum_wrapper{
	margin-top: 1% 6%;
    margin-left: auto ;
    margin-right: auto;
	width: 80%;
	margin-top: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	padding: 1% 0;
	/* background-image: url(".././images/undraw_sunny.png");  */
	background-size: 92% 100%;
	background-repeat: no-repeat;
	background-origin: border-box;
	background-position: top center;
	background-attachment: scroll;
}
.forum{
	width: 84%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;	
}
.left_forum{
	width: 32%;
	height: auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding: 0 2%;
}
.forum_profile_image{
	width: 90%;
	height: 90%;
	padding: 1%;
	text-align: center;
}

.forum_profile_image img{
	width: 200px;
	height: 200px;
	border-radius: 50%;
}
.forum_profile_status{
	margin-top: 10%;
	width: 100%;
}
.forum_profile_status span{
	display: block;
	margin: 3% 0;
	font-size: 18px;
	letter-spacing: 2px; 
}
.forum_profile_community{
	margin-top: 10%;
	width: 100%;
}
.forum_profile_community a, p{
	display: block;
	margin: 3% 0;
	letter-spacing: 2px;
	font-size: 18px;
}
.forum_profile_question{
	margin-top: 10%;
	width: 100%;
}

.forum_questions{
    margin-top: 10%;
	width: 100%;
}
.forum_profile_question p{
	font-size: 18px;
	letter-spacing: 2px;
}
.forum_profile_question form{
	width: 100%;
}
.forum_profile_question form textarea{
	width: 100%;
	height: 400px;
	resize: none;
	border: 1px solid black;
	border-radius: 20px;
	padding: 5%;
	font-size: 18px;
}
.right_forum{
	width: 68%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}
.right_forum h1{
	margin-top: 15%;
	font-size: 40px;
	letter-spacing: 2px;
}
.comment_box{
	width: 100%;
	height: auto;
	margin: 2% 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}
.question_image_btn{
	width: 100%;
	text-align: right;
	padding: 2%;
}
.question_send_btn{
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 50px;
	border: 1px solid grey;
	margin-left: 10px;
	box-shadow: 0px 1px 3px grey; 
	color: #fff;
	background: orange;
	cursor: pointer;
	float: right;
}
.question_image_btn i{
	font-size: 30px;
	display: block;
	float: right;
}
.lets_talk{
	width: 100%;
	margin-bottom: 1%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}
.lets_talk a{
	padding: 10px 20px;
	font-size: 16px;
	border-radius: 50px;
	border: 1px solid grey;
	margin-left: 10px;
	box-shadow: 0px 1px 3px grey; 
	cursor: pointer;
}
.grey_btn{
	background-color: #4A5E58;
	color: #fff;
    text-decoration: none;
	width: 30px;
    /* background-color: #4A5E58; */
}

.grey_btn:hover{
	background-color: #4A5E58;
	text-decoration: none;
}

.comment_details{
	width: 100%;
	display: flex;
    background: rgba(255, 235, 125, 0.5);
    border-radius: 20px 20px 0 0;
    padding: 2% 0;
}
.comment_profile{
	width: 20%;
	height: auto;
    border-radius: 20px 0px 0 0;
	padding: 1% 0;
}
.comment_profile img{
	width: 80%;
	height: 100px;
}
.comment_body{
	width: 80%;
	height: auto;
	padding: 1%;
    border-radius: 0px 20px 0 0;
    line-height: 25px;
}.comment_body span{
	position: relative;
	top: 0;
	left: 85%;
	font-size: 20px;
	padding-bottom: 2%;
}
.comment_area{
	width: 100%;
	height: 100px;	
	margin-bottom: 10%;
}
.comment_area form{
	width: 100%;
	height: 100%;
}
.comment_area form textarea{
	width: 100%;
	height: 100%;
	resize: none;
	font-size: 20px;
	padding: 1%;
	border: 1px solid grey;
	box-shadow: 0 1px 3px grey;
	border-radius: 0 0 20px 20px;
}
.send_btn{
	padding: 10px 20px;
	font-size: 16px;
	border-radius: 50px;
	border: 1px solid grey;
	margin-left: 10px;
	box-shadow: 0px 1px 3px grey; 
	color: #fff;
	background: orange;
	position: relative;
	top: 0;
	left: 80%;
	cursor: pointer;
}
.sub_comment_details{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
    background: rgba(254, 231, 125, 0.2);
}
.sub_comment_p{
	width: 20%;
	padding: 2% 0;
	font-weight: bold;
}
.sub_comment_profile{
	width: 15%;
	text-align: center;
	padding: 2% 0;
}
.sub_comment_profile img{
	width: 80%;
	height: 70px;
	border-radius: 50%;
}
.comment_body img{
	width: 30%;
	height: 120px;
	border: 1px solid lightgrey;
	border-radius: 20px;
}
.sub_comment_body{
	width: 75%;
	padding: 0  2%;
}
.sub_comment_body p{
	font-size: 16px;
	line-height: 22px;
}
.sub_comment_body i{
	 position: relative;
	 top: 0; 
	 left: 90%; 
	 font-size: 20px;
	 padding-bottom: 2%;
}


@media screen and (max-width: 1200px){
    .forum_wrapper{
		margin: 1% 6%;
		width: 88%;
		margin-top: 80px;
		background-size: 100% 100%;
	}
	.forum{
		width: 100%;	
	}
	.left_forum{
		width: 30%;
		padding: 0;
	}
	.forum_profile_image{
		width: 84%;
	}
	.forum_profile_image img{
		width: 70%;
        height: 70%;
	}
	.forum_profile_status span{
		letter-spacing: 1px; 
	}
	.forum_profile_community a, p{
		letter-spacing: 1px;
	}
	.forum_profile_question{
		width: 90%;
	}
	.right_forum{
		width: 70%;
	}
	.right_forum h1{
		letter-spacing: 1px;
	}
}

@media screen and (max-width: 990px){	
    .forum_wrapper{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
	}
	.forum_profile_image{
		width: 70%;
		height: 70%;
	}
	.forum_profile_image img{
		width:70%;
		height: 70%;
	}
}

@media screen and (max-width: 700px){
    .forum_wrapper{
		background-image: none;
	}
	.forum{
		width: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;	
	}
	.left_forum{
		width: 100%;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		padding: 0 2%;
	}
	.forum_profile_image{
		width: 80%;
		height: 80%;
        text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	.forum_profile_image img{
        width: 80%;
		/* height: 80%; */
        text-align: center;
	}
	.forum_profile_status{
		margin-top: 5%;
		text-align: center;
	}
	.forum_profile_status span{
		display: block;
		margin: 3% 0;
		font-size: 25px;
		letter-spacing: 2px; 
	}
	.forum_profile_community{
		margin-top: 5%;
		text-align: center;
	}
	.forum_profile_community a, p{
		margin: 3% 0;
		letter-spacing: 2px;
		font-size: 25px;
	}
	.forum_profile_question{
		margin-top: 5%;
		text-align: center;
	}
	.forum_profile_question p{
		font-size: 25px;
		letter-spacing: 2px;
	}
	.forum_profile_question form textarea{
		height: 200px;
		padding: 5%;
		font-size: 22px;
	}
	.right_forum{
		width: 100%;
		height: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
	}
	.right_forum h1{
		margin-top: 10%;
		margin-bottom: 5%;
		font-size: 40px;
		letter-spacing: 2px;
	}
	.comment_box{
		margin: 2% 0;
		align-items: center;
	}
	.question_image_btn{
		width: 100%;
		text-align: right;
		padding: 2%;
	}
	.lets_talk{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.lets_talk div{
		margin: 2% 0;
	}
	.lets_talk div h2{
		font-size: 30px;
		letter-spacing: 1px;
	}
	.lets_talk button{
		font-size: 18px;
		padding: 10px 30px;
		font-size: 20px;	
	}
	.comment_details{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.comment_profile{
		width: 100%;
	}
	.comment_profile img{
		width: 45%;
		height: 240px;
	}
	.comment_body{
		width: 100%;
	    line-height: 24px;
	}
	.comment_body p{
		font-size: 20px;
	}
	.comment_body span{
		font-size: 18px;
	}

	.sub_comment_profile p{
		font-size: 16px;
	}
	.sub_comment_profile img{
		width: 80%;
		height: 70px;
		border-radius: 50%;
	}
	.comment_body img{
		text-align: center;
		width: 50%;
		height: 200px;
		border: 1px solid lightgrey;
		border-radius: 20px;
	}
	.sub_comment_details .sub_comment_p p{
		font-size: 16px;
	}
}







/* askQuestion.js */
.question_container{
    display: grid;
    grid-template-columns: 2fr 2fr 4fr 3fr;
    grid-gap: 1em;
}


.user_info{
    grid-column: 2;
}
.question_map{
    grid-column: 3;
}

/* userProfile */
.userImage{
    text-align: center;
    margin-bottom: 30px;
}

.nameEdit{
    display: grid;
    grid-template-columns: 1fr 2fr
}


/* quesiton input box */
.questionInput{
    margin-top: 10%;
    width: 90%;
}

.questionInput textarea{
    font-size: .8rem;
    letter-spacing: 1px;
    width: 100%;
    height: 300px;
    line-height: 1.5;
    padding: 10px;
    border: 1px solid #3F5552;
    border-radius: 10px;
}

.like_and_message{
    display: inline;
}

.image_sent{
    /* display: inline; */
    float:right;
}


/* questionCard */
.allAndMyQuestionBtn{
    text-align: right;
    text-decoration: none;
}

.allQuestion{
    font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: white;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    width: 100px;
    color: #4A5E58;
    padding: 1% 5%;
    
}

.myQuestion{
    font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: #4A5E58;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    padding: 1% ;
    color: white;
    margin: 1%;

}

.allquestioninAll{
	color: #4A5E58;
}



.allquestioninAll:hover{
	text-decoration: none;
	font-weight: 800;
	color: #4A5E58;;
}


.myquestionInAll:hover{
	text-decoration: none;
	font-weight: 700;
	color: white;
}

.myquestionInAll{
	font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: #4A5E58;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    padding: 1% ;
    color: white;
}

.allQuestionInMy{
    font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: #4A5E58;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    padding: 1% 5%;
    color: white;
    margin: 1%;
}

.myQuestionInMy{
    font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: white;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    width: 100px;
    color: #4A5E58;
    padding: 1% ;
}

.questionnbutton{
    display: block;
    width: 100%;
    margin-top : 3%;
    cursor: pointer;
    text-align: left;
    background-color: #FFF7CD;
    border: none;
    padding: 1%;
    border-top-right-radius: 20px 20px;
    border-top-left-radius: 20px 20px;
}



.textContent{
    display: grid;
    grid-template-columns: 1fr 4fr;

}

.commonCount{
    text-align: right;
    
}

.commonCountinner{
}

.answertextarea textarea{
    border-bottom-right-radius: 20px 20px;
    border-bottom-left-radius: 20px 20px;
    height: 80px;
    line-height: 1.5;
}

.submitbtn{
    text-align: right;
}

.submitbtn input{
    background: #FB9F00;
    opacity: 0.9;
    border-radius: 20px;
    border: none;
    color: white;
    padding: 3px 2%;
    box-shadow: 2px 2px #c1c1c1;
    margin: 1% 0px;
    text-align: right;
}

.questionSubmitbtn{
    background: #FB9F00;
    opacity: 0.9;
    border-radius: 20px;
    width: 100px;
    border: none;
    color: white;
    padding: 3% 4%;
}

.contentOnly{
    margin-top: 1%;
}

.questionImage{
    display: block;
    width: 150px;
    border-radius: 20px;
}

.questionnbutton img{
    max-width: 100%; 
    display:block;

}

.answerdiv{
    background-color: rgba(255, 247, 205, 0.6);
    display: grid;
    grid-template-columns: 1fr 1fr 4fr;
    padding-top: 1%;
}

.answeruser{
    grid-column: 2;
    text-align: center;
}

/* userCard */
.userImage{
    width: 100px;
}