/* .editDiaryImageBox{
	display: grid;
	grid-template-columns: 1fr 2fr;
} */

.DiaryCard {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
	width: 100%;
}

.DiaryCard:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.diarybtn{
	background: #FB9F00;
    opacity: 0.9;
    border-radius: 20px;
    border: none;
    color: white;
    padding: 3px 2%;
    box-shadow: 2px 2px #c1c1c1;
    margin: 1% 0px;
    text-align: right;
	padding:3% 5%;
}

.diaryDeleteBtn{
	background: white;
	border:solid red;
    opacity: 0.9;
    border-radius: 20px;
    border: none;
    color: red;
	font-weight: 700;
    padding: 3px 2%;
    box-shadow: 2px 2px #c1c1c1;
    margin: 1% 0px;
    text-align: right;
	margin: 5%;
	padding:3% 5%;
}

/*##################### Product Section ########################*/
.product_section {
	margin: 80px 8% 2% 8%;
	width: 84%;
	height: auto;
	border: none;
}

.product_page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 8rem;
	width: 90%;
	background: rgba(240, 240, 240, 0.4);
	height: auto;
	border-radius: 50px;
}

.product_top {
	width: 60%;
	height: auto;
	padding: 0 4%;
}

.product_description h1 {
	text-transform: uppercase;
	margin: 10% 0 5% 0;
}

.product_description p {
	font-size: 17px;
	line-height: 25px;
	margin: 5% 0;
}

/* .product_order {
	margin-top: 40px;
} */

/* .product_order h2 {
	font-size: 30px;
	margin: 4% 0;
	color: rgba(242, 71, 65, 1);
} */

.product_order a {
	background: rgba(242, 71, 65, 1);
	padding: 10px 30px;
	text-transform: uppercase;
	color: white;
	font-weight: bold;
	text-decoration: none;
	border-radius: 5px;
}

.product_description span {
	padding: 10px;
	color: rgba(242, 71, 65, 1);
	font-weight: bold;

}

.product_bottom {
	display: flex;
	justify-content: space-between;
	width: 96%;
	height: auto;
	margin: 3% 2% 2% 2%;
}

.product_items {
	width: 40%;
	height: 100%;
	padding: 1%;
}

.product_items h1 {
	padding: 1% 0 0 0;
	text-transform: uppercase;
}

.product_items li {
	list-style: none;
	padding-top: 2%;
}

.product_items li i {
	padding-right: 2%;
	color: rgba(242, 71, 65, 1);
}

.product_divider {
	border: 3px solid black;
	width: 100px;
	margin-bottom: 10px;
	border-radius: 10px;
}

.product_video {
	width: 40%;
	height: 100%;
	padding: 1%;
}

.product_video h1 {
	padding: 1% 0 0 0;
	text-transform: uppercase;
}

.product_video video {
	margin-top: 2%;
	width: 280px;
	height: 200px;
}

.product_video h2 {
	padding: 2% 0;
}

.carousel_container {
	display: flex;
	border-radius: 30px;
	overflow: hidden;
	width: 40%;
	z-index: 0;
	height: 55%;
	position: absolute;
	top: 80px;
	left: 52%;
}

/* Hide the images by default */
.mySlides {
	/* display: none; */
	height: 100%;
	width: 100%;
}

.mySlides img {
	display: block;
	height: 100%;
	width: 100%;
}

/* image gradient overlay [optional] */
/* .mySlides::after {
content: "";
position: absolute;
inset: 0 0 0 0;
background-image: linear-gradient(-45deg, rgba(110, 0, 255, .1), rgba(70, 0, 255, .2));
} */

/* Next & previous buttons */
.prev,
.next {
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	width: auto;
	padding: 20px;
	color: white;
	font-weight: bold;
	font-size: 24px;
	border-radius: 0 8px 8px 0;
	background: rgba(173, 216, 230, 0.1);
	user-select: none;
}

.next {
	right: 0;
	border-radius: 8px 0 0 8px;
}

.prev:hover,
.next:hover {
	ackground-color: rgba(173, 216, 230, 0.3);
}

/* Caption text */
.text {
	color: #f2f2f2;
	background-color: rgba(10, 10, 20, 0.4);
	backdrop-filter: blur(6px);
	border-radius: 10px;
	font-size: 20px;
	padding: 8px 12px;
	position: absolute;
	bottom: 60px;
	left: 50%;
	transform: translate(-50%);
	text-align: center;
}

/* Number text (1/3 etc) */
.number {
	color: #f2f2f2;
	font-size: 16px;
	background-color: rgba(173, 216, 230, 0.3);
	backdrop-filter: blur(6px);
	border-radius: 10px;
	padding: 8px 12px;
	position: absolute;
	top: 10px;
	left: 10px;
}

.dots_container {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translate(-50%);
}

/* The dots/bullets/indicators */
.dots {
	cursor: pointer;
	height: 14px;
	width: 14px;
	margin: 0 4px;
	background-color: rgba(173, 216, 230, 0.8);
	backdrop-filter: blur(2px);
	border-radius: 50%;
	display: inline-block;
	transition: background-color 0.3s ease;
}

.active,
.dots:hover {
	background-color: rgba(173, 216, 230, 0.8);
}

/* transition animation */
.animate {
	-webkit-animation-name: animate;
	-webkit-animation-duration: 1s;
	animation-name: animate;
	animation-duration: 2s;
}

@keyframes animate {
	from {
		transform: scale(1.1) rotateY(10deg);
	}

	to {
		transform: scale(1) rotateY(0deg);
	}
}

@media screen and (max-width: 1200px) {
	.product_section {
		margin: 50px 6% 2% 6%;
		width: 88%;
	}

	.product_page {
		margin-top: 3rem;
	}

	.product_top {
		width: 58%;
	}

	.product_description h1 {
		font-size: 28px;
	}

	.product_description p {
		font-size: 16px;
	}

	.product_order {
		margin-top: 20px;
	}

	.product_order h2 {
		font-size: 26px;
	}

	.product_order a {
		padding: 10px 20px;
	}

	.product_order span {
		padding: 10px;
		font-size: 16px;
	}

	.product_items h1 {
		font-size: 28px;
	}

	.product_items li {
		font-size: 15px;
	}

	.product_divider {
		width: 85px;
	}

	.product_video video {
		width: 280px;
		height: 200px;
	}

	.product_video h1 {
		font-size: 28px;
	}

	.product_video h2 {
		font-size: 22px;
	}

	.carousel-container {
		width: 42%;
		height: 45%;
	}
}


@media screen and (max-width: 990px) {

	.carousel_container {
		display: block;
		border-radius: 10px;
		overflow: hidden;
		width: 80%;
		z-index: 0;
		height: auto;
		position: relative;
		top: 20px;
		left: 10%;
	}

	.DiaryCard {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		transition: 0.3s;
		width: 88%;
	} 

	.product_section {
		margin: 80px 4% 2% 4%;
		width: 92%;
	}

	.product_top {
		width: 60%;
	}

	.product_description h1 {
		font-size: 25px;
	}

	.product_description p {
		font-size: 20px;
		line-height: 23px;
		margin: 2% 0;
		;
	}

	.product_order {
		margin-top: 10px;
	}

	.product_order h2 {
		font-size: 22px;
	}

	.product_order a {
		padding: 8px 16px;
		font-size: 14px;
	}

	.product_order span {
		padding: 10px;
		font-size: 14px;
	}

	.product_items h1 {
		font-size: 25px;
	}

	.product_items li {
		font-size: 14px;
	}

	.product_divider {
		width: 85px;
	}

	.product_video video {
		width: 280px;
		height: 200px;
	}

	.product_video h1 {
		font-size: 25px;
	}

	.product_video h2 {
		font-size: 18px;
	}

	.carousel-container {
		width: 44%;
		height: 40%;
	}
}

@media screen and (max-width: 890px) {
	.product_section {
		margin: 10px 3% 2% 3%;
		width: 94%;
	}

	.product_top {
		width: 59%;
	}

	.product_description h1 {
		font-size: 25x;
	}

	.product_description p {
		font-size: 20px;
		margin: 1% 0;
		;
	}

	.product_order {
		margin-top: 8px;
	}

	.product_order h2 {
		font-size: 18px;
	}

	.product_order a {
		padding: 7px 10px;
		font-size: 13px;
	}

	.product_order span {
		padding: 6px;
		font-size: 13px;
	}

	.product_items h1 {
		font-size: 20px;
	}

	.product_items li {
		font-size: 13px;
	}

	.product_divider {
		width: 60px;
	}

	.product_video video {
		width: 280px;
		height: 200px;
	}

	.product_video h1 {
		font-size: 20px;
	}

	.product_video h2 {
		font-size: 15px;
	}

	.carousel-container {
		width: 45%;
		height: 40%;
	}
}


@media screen and (max-width: 700px) {
	.product_section {
		margin: 50px 3% 2% 3%;
		width: 94%;
	}

	.product_page {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 0;
		width: 100%;
	}

	.product_top {
		width: 100%;
	}

	.product_description h1 {
		font-size: 28px;
	}

	.product_description p {
		font-size: 16px;
		margin: 2% 0;
		;
	}

	.product_order {
		margin-top: 20px;
	}

	.product_order h2 {
		font-size: 22px;
	}

	.product_order a {
		padding: 10px 30px;
		font-size: 15px;
	}
/* 
	.product_order span {
		padding: 10px;
		font-size: 15px;
	} */

	.product_bottom {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.product_items {
		margin-top: 15px;
		width: 100%;
		height: auto;
		order: 2;
	}

	.product_items h1 {
		font-size: 28px;
	}

	.product_items li {
		font-size: 15px;
	}

	.product_divider {
		width: 80px;
	}

	.product_video {
		margin-top: 15px;
		width: 100%;
		height: 100%;
		order: 1;
	}

	.product_video video {
		width: 96%;
		height: auto;
	}

	.product_video h1 {
		font-size: 28px;
	}

	.product_video h2 {
		font-size: 20px;
	}

	.carousel-container {
		position: relative;
		top: 0;
		left: 0;
		margin: 1% auto;
		width: 100%;
		height: 60vh;
	}
}