.garden_plan{
	margin: 3% 8%;
	width: 80%;
	height: auto;
	margin-top: 80px;
	background-repeat: no-repeat;
	background-position: 15% 100%;
	background-origin: border-box;
	background-attachment: scroll;
	background-size: 40% 50%;
}
.planmygarden{
    margin-top: 5%;
    margin-left: 10%;
    font-size: 50px;
    font-family: 'Arapey', serif;
}

.garden_plan h1{
	font-weight: 500;
	font-size: 40px;
	padding: 10px;
}
.my_veg_id{
	width: 60%;
	height: auto;
	position: relative;
	top: 0;
	left: 40%;
	margin: 2% 0;
	background: #FFF6C8;
	border-radius: 20px;
	padding: 4%;
}

.vegID{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 30px;

}
form.veg_form{
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-end;
}
.each_field_plan{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	flex-direction: row;
	flex-wrap: nowrap;
}
.each_field_plan h1{
	padding: 5% 0;
	color: orange;
	letter-spacing: 3px;
	font-weight: 500;
	font-size: 40px;
}
.each_field_plan div{
	width: 50%;
	margin-bottom: 3%;
	padding: 0 2%;
}
div.each_field_plan label{
	font-size: 22px
}
div.each_field_plan select{
	padding: 8px;
	width: 95%;
	border-radius: 5px;
	border: none;
	box-shadow: 0px 2px 3px 0px black;
	margin: 1%;
}

.useCropinputbox{
    padding: 8px;
	width: 95%;
	border-radius: 5px;
	border: none;
	box-shadow: 0px 2px 3px 0px black;
	margin: 1%;
}



.each_field_plan img{
	width: 80%;
	height: 180px;
	border-radius: 10px;
	padding: 1%;
}
.each_field_plan .btn_2{
	padding: 0.8rem 2rem;
	font-size: 1.5rem;
	letter-spacing: 0.2rem;
	background-color: rgba(250, 169, 5, 1);
	color: white;
	border: none;
	border-radius: 50px;
	cursor: pointer;
	text-align: center;
}
.each_field_plan .btn_2:hover{
	background-color: rgba(250, 169, 5, 0.8);
}
.each_field_plan .btn_1{
	padding: 0.8rem 2rem;
	font-size: 1.5rem;
	letter-spacing: 0.2rem;
	background-color: rgba(245, 240, 250, 0.5);
	color: rgba(250, 169, 5, 1);
	border: none;
	border-radius: 50px;
	box-shadow: 0 2px 1px 1px rgba(250, 169, 5, 1);
	cursor: pointer;
}
.each_field_plan .btn_1:hover{
	background-color: rgba(245, 240, 250, 0.8);
}

#city{
	background-color: #fff; 
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-position: 98% center;
	background-size: 15px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

@media screen and (max-width: 1200px){
    .garden_plan{
		margin: 1% 5%;
		width: 90%;
		margin-top: 80px;
		background-position: 12% 95%;
		background-size: 30% 35%;
	}
	.garden_plan h1{
		font-size: 38px;
	}
	.my_veg_id{
		width: 70%;
		left: 30%;
	}
	.each_field_plan img{
		width: 75%;
		height: 170px;
		border-radius: 10px;
		padding: 1%;
		border: 1px solid lightgrey;
	}
	.each_field_plan .btn_2{
		padding: 0.8rem 1.5rem;
		font-size: 1.3rem;
	}
	.each_field_plan .btn_1{
		padding: 0.8rem 1.5rem;
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 990px){
    .garden_plan{
		margin: 1% 3%;
		width: 94%;
		margin-top: 80px;
		background-position: 0 97%;
		background-size: 30% 35%;
	}
	.garden_plan h1{
		font-size: 36px;
	}
	.my_veg_id{
		width: 80%;
		left: 20%;
	}
}

@media screen and (max-width: 890px){
    .garden_plan{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		background: none;
	}
	.garden_plan h1{
		font-size: 36px;
	}
	.my_veg_id{
		width: 100%;
		left: 0;
	}
}

@media screen and (max-width: 600px){
    .garden_plan{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		background: none;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.my_veg_id{
		width: 100%;
		margin: 2% 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.my_veg_id > h1{
		padding: 4% 0;
	}
	form.veg_form{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.each_field_plan{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.each_field_plan div{
		width: 100%;
	}
	#o-1{
		order: 1;
	}
	#o-1 img{
		width: 60%;
		height: 220px;
	}
	#o-2{
		order: 2;
	}
	#o-2 h1{
		text-align: center;
		font-weight: bold;
	}
	.each_field_plan .btn_2{
		width: 60%;
		padding: 0.8rem 2rem;
		font-size: 1.3rem;
	}
	.each_field_plan .btn_1{
		width: 60%;
		padding: 0.8rem 2.6rem;
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 480px){
    .garden_plan{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		background: none;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.my_veg_id{
		width: 100%;
		margin: 2% 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.my_veg_id > h1{
		padding: 4% 0;
	}
	form.veg_form{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.each_field_plan{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.each_field_plan div{
		width: 100%;
	}
	#o-1{
		order: 1;
	}
	#o-1 img{
		width: 60%;
		height: 220px;
	}
	#o-2{
		order: 2;
	}
	#o-2 h1{
		text-align: center;
		font-weight: bold;
	}
	.each_field_plan .btn_2{
		width: 60%;
		padding: 0.8rem 2rem;
		font-size: 1.3rem;
	}
	.each_field_plan .btn_1{
		width: 60%;
		padding: 0.8rem 2.6rem;
		font-size: 1.3rem;
	}
}
