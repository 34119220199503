.FertilizeContainer{
    margin-left: 6%;
    margin-right: 6%;
}

.title{
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
}

.details_container{
	width: 100%;
	height: auto;
}

.details{
	display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	padding: 1%;
}

.scroll_area{
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 300px;
	overflow-y: scroll;
}

.scroll_area::-webkit-scrollbar{
	width: 15px;
}
.scroll_area::-webkit-scrollbar-track{
	background: #f1f1f1;
}
.scroll_area::-webkit-scrollbar-thumb{
	background: rgba(62, 110, 30, 1);
}
.scroll_area::-webkit-scrollbar-thumb:over{
	background: #555;
}