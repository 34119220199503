@import url(https://fonts.googleapis.com/css?family=Lato|ZCOOL+KuaiLe&display=swap);
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
}



.ck-editor__editable_inline {
    min-height: 300px;
}

h1,h2,h3{
    font-family: 'Arapey', serif;
}

p,h5,label{
    font-family: 'Quicksand', sans-serif;
}

body, html {
    margin: 0;
    padding: 0;
    min-height: 100vh;
}



.homepage_Hero_section__3e8v-{
	margin: 80px 7% 2% 7%;
	background-size:100% 100%;
	height:75vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding:20px;
    background-color: #fff3d7;
	border-radius: 30px;
}

.homepage_left_area__3PhwA{
	margin: 20px;
	width: 50%;
}

.homepage_left_area__3PhwA h1{
	font-family: 'Quicksand', sans-serif;
	font-weight: 700;
	line-height: 50px;
	letter-spacing: 1px;
	padding-bottom: 20px;
}

.homepage_left_area__3PhwA p{
	font-size: 16px;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

a.homepage_btn_hero__2AjnK{
	text-decoration: none;
	padding: 15px;
	border: 3px solid green;
	border-radius: 50px;
	color: black;
	font-weight: 700;
}

a.homepage_btn_hero__2AjnK:hover{
	border-radius: 50px;
	background: green;
	color: white;
}


.homepage_right_area__3t6Hv{
	width: 50%;
    /* height: 70%; */
	margin-left: 40px;
    margin-right: 20px;
}

.homepage_right_area__3t6Hv img{
	border-radius:20px 20px 100px 20px;
	width: 90%;
	/* height:450px; */
}

.homepage_hero_social_links__3zcwB {
	 padding: 15px 0; 
	 margin-top: 50px;
}

.homepage_hero_social_links__3zcwB i{
	font-size: 30px;
	color: blue;
	border-radius: 5px;
	z-index: 10;
}
.homepage_hero_social_links__3zcwB a{
	padding: 0 20px;
}


/* ************************* About Section ****************** */

.homepage_about_section__1mbKn{
	margin: 0 8%;
	display: flex;
	justify-content: center;
	border-radius: 30px;
}

.homepage_about_image__148Zo{
	margin: 1% 1%;
	width: 48%;
	height: auto;	
	float: left;
}

.homepage_about_image_1__1K36A{
	width: 100%;
	height:auto; 
	border-radius: 20px;
}
	
.homepage_content_area__1hZTQ {
	width: 48%;
	margin: 1% 1%;
}

.homepage_about_content__1JYny{
	padding: 0% 1%;
	border-radius: 20px;
	width: 100%;
	height: auto;	
	float: right;
}

.homepage_about_content__1JYny h1 {
	color: orange; 
	font-weight:bold;
	line-height: 40px;
	padding: 10px 10px 0 10px;
	text-transform: uppercase;
}

.homepage_divider_about__2EyIR {
	border: 3px solid #2fe7eb;
	width: 110px;
	margin-left: 10px;
	border-radius:10px;
}

.homepage_about_content__1JYny h2 {
	font-size: 18px;
	font-weight:bold;
	padding: 15px 0px 10px 10px;
}

.homepage_about_content__1JYny p {
	line-height: 24px;
	font-size: 16px;
	text-align: justify; 
	padding: 10px 10px;
}


/*********************** our services *****************************/

.homepage_our_services__MPzJn{
	padding: 10px;
	margin:1% 7%;
	text-align: center;
	height: auto;
	display: block;
}

.homepage_our_services__MPzJn h1 {
	text-align: center; 
	font-weight:bold;
	line-height: 40px;
	padding: 10px 10px 0 10px;
	text-transform: capitalize;
}

.homepage_divider_services__1MDnB {
	border: 3px solid #2016db;
	width: 150px;
	margin-left: 10px;
	border-radius: 10px;
	margin:0 auto;
}

.homepage_our_services__MPzJn p {
	font-size: 20px;
	font-weight: 500;
	text-align: center; 
	padding: 20px 0;
}

.homepage_service_item__2wUtX {
	float: left;
	width:26%; 
	height:auto; 
	margin: 2% 3%;
	padding:5px;
	border-radius:10px;
}

.homepage_service_item__2wUtX:hover {
	box-shadow: 0px 0px 2px 5px #f1f2e9 inset;
}

.homepage_service_item__2wUtX img{
	width: 75%;
	padding: 5px;;
}

.homepage_service_item__2wUtX p {
	font-size: 16px;
	text-align: justify;
	line-height: 24px;
	padding: 5px;
}

.homepage_service_item__2wUtX h2{
	padding: 20px 0 0 0;
	text-transform: capitalize;
}

/*******************************Start of Number Section ****************************/

.homepage_Gridlove_section__2ySzY{
	display: block;
	padding:1% 1%;
	height: auto;
	margin: 1% 8%; 
	border-radius: 30px;
}

.homepage_number-title__1wVDS h1{
	padding: 10px 0px 10px 80px;
	font-weight:bold;
	line-height: 40px;
}

.homepage_description_sidelove__e_Upv p{
	line-height:25px;
}


.homepage_left_girdlove__3ckNk{
	width: 48%;
	height: auto;
	display: block;
	float: left;
}

.homepage_circle_sidelove__2u0Ax{
	margin:0px;
	padding:0px;
	width:50px;
	height:50px;
	color:white;
	background-color:#e2bb38;
	border:1px solid white;
	border-radius:50%;
	text-align:center;
	font-weight:700;
	line-height:50px;
}

.homepage_description_sidelove__e_Upv{
	margin-left: 80px;
	margin-top: -45px;
	margin-bottom: 20px;
	text-align: justify;
}

.homepage_main_grid_love__3OgLC{ 
	margin: 30px 0;
}

.homepage_right_sidelove__3scUY{
	float:right;
	width: 48%;

}

.homepage_right_sidelove__3scUY img{ 
	width: 100%; 
	height: 400px;
	border-radius: 20px;
}


/******************************* Our Team ************************************/

.homepage_our_team__2g_8e{
	width: 84%;
	text-align: center;
	padding: 1% 1%;
	margin:1% 8%;
	border-radius:30px;
}

.homepage_our_team__2g_8e h1{
	padding:10px 10px 0 10px;
}

.homepage_divider_team__1Kd-H {
	border: 3px solid #2016db;
	width: 100px;
	margin: 0 auto;
	border-radius:10px;	
}

.homepage_profile_area__KUtMQ {
	padding: 10px 0 0px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.homepage_main_profile__3yLmw {
	margin: 3%;
	width: 25%;
	padding:1%;
	
}

.homepage_profile_image__2gdsp img {
	border-radius: 90%;
}

.homepage_profile_name__3vjpD{
	font-weight:bold;
	font-size: 18px;
	padding: 10px 5px 5px 5px;
}

.homepage_profile_designation__3kUcI{
	font-size: 16px;
	padding:5px;
}

.homepage_profile_social_links__2odeg{
	padding: 10px;
}

.homepage_profile_social_links__2odeg span{
	padding: 0px 10px;
}

/************************************* Newsletter Section ***************************/
.homepage_news_letter__kfW1N{
	width: 84%;
	margin: 2% 8%;
	padding: 2% 5%;
	border-radius: 50px;
	background:rgba(255, 243, 215, 0.8);
}
.homepage_main_box__2-5T7{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.homepage_left_area_box__22Y4m{
	width: 40%;
}
.homepage_left_area_box__22Y4m img{
	width: 100%;
	border-radius: 30px;
}
.homepage_right_area_box__2JQqx{
	width: 50%;
	padding: 20px;
}
.homepage_right_area_box__2JQqx h1{
	font-size: 30px;
	padding: 10px 0;
}
.homepage_right_area_box__2JQqx p{
	font-size: 16px;
	padding: 0 0 10px 0;
}
.homepage_sub_input__2oskw{
	width: 70%;
	padding: 10px;
	margin: 10px 0;
	border: 1px solid #ccc;
	box-sizing: border-box;
	border-radius: 20px;
}
.homepage_sub_btn__3vVhM {
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 20px;
  border: none;
  margin: 10px;
}

.homepage_sub_btn__3vVhM:hover {
  opacity: 0.6;
}

/*************************************End of Newsletter Section ***************************/


/*############################ Footer ############################*/

.homepage_footer__3zxG0{
	margin:0 5%;
	/* background-image: url("../images/footer.png");  */
	width: 90%;
	min-height: 40vh;
	/* background-size: 100% 100%; */
	padding-left: 8%;
	padding-right: 8%;
	background-color: #f0f6ed;
	border-radius: 50px;
}

.homepage_footer_area__1lkgl{
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 6% 0 5% 0;
}

.homepage_footer_box__L17fk{
	width: 25%;
	margin: 1%;
}

.homepage_footer_brand__36c-k {
	text-align: center;
	line-height: 25px;
}

.homepage_footer_brand__36c-k h1{
	font-size: 26px;
	padding-bottom: 20px;
}

.homepage_footer_brand__36c-k p{
	font-size: 14px;
	padding-bottom: 15px;
}

.homepage_footer_social_links__1TjDg {
	 padding:20px 5px 10px 0px;; 
}

.homepage_footer_social_links__1TjDg a{
	padding: 0 10px;
	font-size: 30px;
	border-radius: 5px;
	z-index: 10;
}

.homepage_footer_social_links__1TjDg a:hover{
	color: darkgreen;
}

.homepage_footer_company__pyhrS {
	text-align: center;
	width: 25%;
	margin: 1%;
}
.homepage_footer_company__pyhrS h1{
	font-size: 20px;
	padding-bottom: 15px;
}
.homepage_footer_company__pyhrS li{
	list-style: none;
}
.homepage_footer_company__pyhrS  li{
	padding: 10px;
}
.homepage_footer_company__pyhrS  li a{
	text-decoration: none;
	color: black;
}
.homepage_footer_company__pyhrS  li a:hover{
	color: darkgreen;
	font-weight: bold;
}

.homepage_footer_services__15moY{
	text-align: center;
	width: 25%;
	margin: 1%;
}

.homepage_footer_services__15moY h1{
	font-size: 20px;
	padding-bottom: 15px;
}
.homepage_footer_services__15moY li {
	list-style: none;
}
.homepage_footer_services__15moY  li{
	padding: 10px;
}
.homepage_footer_services__15moY  li a{
	text-decoration: none;
	color: black;
}
.homepage_footer_services__15moY  li a:hover{
	color: darkgreen;
	font-weight: bold;
}

.homepage_footer_address__d9RsL {
	text-align: center;
	width: 25%;
	margin: 1%;
}
.homepage_footer_address__d9RsL h1{
	font-size: 20px;
	padding-bottom: 15px;
}

.homepage_footer_address__d9RsL p{
	font-size: 16px;
	padding-bottom: 15px;
}

footer{
	margin-top: 10px;
	height: 50px;
	width: 100%;
	background: white;;
	/* text-align: ; */
}
footer p{
	text-align: center;
	padding: 20px;
	font-weight: bold;
}


@media screen and (max-width: 1200px){
    label.homepage_logo__3LInL{
		font-size: 23px;
	}
	
	label.homepage_logo__3LInL img{
		width: 180px;
	}
	
	nav{
		width: 100%;
	}
	
	nav ul{
		margin: 0;
	}

	nav ul li{
		margin: 0;
	}

	nav ul li a{
		font-size: 15px;
	}
	
/**********************************/
	.homepage_Hero_section__3e8v-{
		margin: 80px 6% 2% 6%;
	}

	.homepage_left_area__3PhwA h1{
		line-height: 40px;
		letter-spacing: 1px;
		padding-bottom: 18px;
	}

	.homepage_left_area__3PhwA p{
		font-size: 15px;
		padding-bottom: 18px;
		margin-bottom: 18px;
	}
	.homepage_right_area__3t6Hv{
		width: 50%;
		margin: 20px;
	}
	.homepage_hero_social_links__3zcwB {
		 padding: 15px 0; 
		 margin-top: 50px;
	}
	a.homepage_btn_hero__2AjnK{
		padding: 10px;
	}

	.homepage_hero_social_links__3zcwB i{
		font-size: 26px;
	}
	
/**********************************/
	.homepage_about_section__1mbKn{
		margin: 0 6%;
	}
	
	.homepage_about_image__148Zo{
		margin: 0;
		width: 50%;
	}
	
	.homepage_content_area__1hZTQ {
		width: 50%;
		margin: 0;
	}
	
	.homepage_about_content__1JYny{
		padding: 2%;
	}

	.homepage_about_content__1JYny h1 {
		line-height: 30px;
		font-size: 30px;
		padding: 8px;
	}

	.homepage_about_content__1JYny h2 {
		font-size: 16px;
		font-weight:bold;
		padding: 15px 0px 10px 10px;
	}

	.homepage_about_content__1JYny p {
		line-height: 24px;
		font-size: 16px;
		text-align: justify; 
		padding: 5px;
	}

/************** our team **********************/
	.homepage_our_team__2g_8e{
		width: 88%;
		margin:1% 6%;
	}

/*************** services *********************/
	.homepage_our_services__MPzJn{
		margin:1% 6%;
		text-align: center;
	}

	.homepage_our_services__MPzJn p{
		padding-top: 16px;
	}
	
	.homepage_profile_area__KUtMQ {
		padding-top: 15px;
	}
	
/**************************************/
	.homepage_Gridlove_section__2ySzY{
		margin: 1% 0%; 
	}
	
	.homepage_number-title__1wVDS h1{
		font-size: 30px;
		line-height: 35px;
	}

	.homepage_description_sidelove__e_Upv p{
		line-height:22px;
	}

/**************************************/
	.homepage_profile_image__2gdsp img {
		/* width: 90%; */
	}	

/**************************************/
	.homepage_news_letter__kfW1N{
		width: 88%;
		margin: 2% 6%;
	}
	.homepage_sub_input__2oskw{
		width: 70%;
	}
	.homepage_right_area_box__2JQqx h1{
		font-size: 25px;
		padding: 10px 0;
	}
/**************************************/
	.homepage_footer__3zxG0{
		margin:0 1%;
		width: 98%;
	}
/**************************************/
	.homepage_product_section__1sLPd{
		margin: 80px 6% 2% 6%;
		width: 88%;
	}
	.homepage_product_page__NBSsl{
		margin-top: 3rem;
	}
	.homepage_product_top__5H77P{
		width: 58%;
	}
	.homepage_product_description__3pGol h1{
		font-size: 28px;
	}

	.homepage_product_description__3pGol p{
		font-size: 16px;
	}
	.homepage_product_order__14oUe{
		margin-top: 20px;
	}

	.homepage_product_order__14oUe h2{
		font-size:26px;
	}

	.homepage_product_order__14oUe a{
		padding: 10px 20px;
	}

	.homepage_product_order__14oUe span{
		padding: 10px;
		font-size: 16px;
	}
	.homepage_product_items__2BBx7 h1{
		font-size: 28px;
	}
	.homepage_product_items__2BBx7 li{
		font-size: 15px;
	}
	.homepage_product_divider__1HbUy {
		width: 85px;
	}
	.homepage_product_video__2-Zx0 video {
		width: 280px;
		height: 200px;
	}
	.homepage_product_video__2-Zx0 h1{
		font-size: 28px;
	}
	.homepage_product_video__2-Zx0 h2{
		font-size: 22px;
	}
	.homepage_carousel-container__20-Y5 {
		width: 42%;
		height: 45%;
	}
}

@media screen and (max-width: 990px){	
    label.homepage_logo__3LInL{
		font-size: 25px;
	}
	
	label.homepage_logo__3LInL img{
		width: 150px;
	}
	
	nav{
		width:100%;
	}
	
	nav ul{
		margin: 0;
	}

	nav ul li{
		margin: 0;
	}

	nav ul li a{
		font-size: 12px;
	}
	
/**********************************/
	.homepage_Hero_section__3e8v-{
		margin: 80px 4% 2% 4%;
	}

	.homepage_left_area__3PhwA h1{
		font-size: 30px;
		letter-spacing: 0px;
		padding-bottom: 16px;
	}

	.homepage_left_area__3PhwA p{
		padding-bottom: 16px;
		margin-bottom: 16px;
	}
	.homepage_right_area__3t6Hv{
		width: 50%;
		margin: 20px;
	}
	.homepage_hero_social_links__3zcwB {
		 padding: 10px 0; 
		 margin-top: 40px;
	}
	
	.homepage_hero_social_links__3zcwB i{
		font-size: 24px;
	}
		
/**************************************/
	.homepage_content_area__1hZTQ {
		display: block;
		width: 100%;
		margin: 1% 0 2% 0;
	}

	.homepage_about_image__148Zo{
		width: 100%;
		text-align: center;
	}

	.homepage_about_image_1__1K36A{
		width: 75%;
		margin-bottom: 10px;
	}
	
	.homepage_about_section__1mbKn{
		display: block;
		margin: 0 4%

	}
	
	.homepage_about_content__1JYny{
		width: 100%;
		margin: 2%;
	}

/**************** services *********************/
	.homepage_our_services__MPzJn{
		margin:1% 0%;
	}
	
	.homepage_service_item__2wUtX {
		width:27%; 
		margin: 2% 3%;
	}
	
/***************** how it work ********************/
	.homepage_Gridlove_section__2ySzY{
		margin: 1% 0%;
		padding: 2%;		
	}
	
	.homepage_main_grid_love__3OgLC{
		width: 100%;
	}
	
	.homepage_left_girdlove__3ckNk{
		width: 80%;
	}

	.homepage_description_sidelove__e_Upv{
		margin-left: 60px;
		margin-top: -45px;
		margin-bottom: 20px;
		text-align: justify;
	}
		
	.homepage_description_sidelove__e_Upv{
		width: 90%;
	}

	.homepage_right_sidelove__3scUY{
		width: 96%;
		text-align: center;
		margin:2% 0;
	}

	.homepage_right_sidelove__3scUY img{ 
		width: 80%; 
	}

/***********************************/
	.homepage_our_team__2g_8e{
		width: 92%;
		margin:1% 4%;
	}
	
	.homepage_main_profile__3yLmw {
		margin: 3%;
		width: 30%;
	}

	.homepage_profile_image__2gdsp img {
		/* width: 90%; */
	}	
	
/***********************************/
	.homepage_news_letter__kfW1N{
		width: 92%;
		margin: 2% 4%;
	}
	.homepage_right_area_box__2JQqx h1{
		font-size: 23px;
		padding: 10px 0;
	}
	.homepage_right_area_box__2JQqx p{
		font-size: 15px;
	}
/***********************************/
	.homepage_footer__3zxG0{
		margin:0 1%;
		width: 98%;
	}
	.homepage_footer_brand__36c-k h1{
		font-size: 22px;
	}

	.homepage_footer_brand__36c-k p{
		font-size: 14px;
	}
	.homepage_footer_social_links__1TjDg i{
		padding: 0 5px;
		font-size: 20px;
	}
	.homepage_footer_company__pyhrS h1{
		font-size: 18px;
	}
	.homepage_footer_company__pyhrS ul{
		font-size: 14px;
	}
	.homepage_footer_services__15moY h1{
		font-size: 18px;
	}
	.homepage_footer_services__15moY ul{
		font-size: 14px;
	}
	.homepage_footer_address__d9RsL h1{
		font-size: 18px;
	}

	.homepage_footer_address__d9RsL p{
		font-size: 14px;
	}
/***********************************/
	.homepage_product_section__1sLPd{
		margin: 80px 4% 2% 4%;
		width: 92%;
	}
	.homepage_product_top__5H77P{
		width: 60%;
	}
	.homepage_product_description__3pGol h1{
		font-size: 25px;
	}

	.homepage_product_description__3pGol p{
		font-size: 15px;
		line-height: 23px;
		margin: 2% 0;; 
	}
	.homepage_product_order__14oUe{
		margin-top: 10px;
	}

	.homepage_product_order__14oUe h2{
		font-size:22px;
	}

	.homepage_product_order__14oUe a{
		padding: 8px 16px;
		font-size: 14px;
	}

	.homepage_product_order__14oUe span{
		padding: 10px;
		font-size: 14px;
	}
	.homepage_product_items__2BBx7 h1{
		font-size: 25px;
	}
	.homepage_product_items__2BBx7 li{
		font-size: 14px;
	}
	.homepage_product_divider__1HbUy {
		width: 85px;
	}
	.homepage_product_video__2-Zx0 video {
		width: 280px;
		height: 200px;
	}
	.homepage_product_video__2-Zx0 h1{
		font-size: 25px;
	}
	.homepage_product_video__2-Zx0 h2{
		font-size: 18px;
	}
	.homepage_carousel-container__20-Y5 {
		width: 44%;
		height: 40%;
	}
}

@media screen and (max-width: 890px){
    .homepage_checkbtn__3ZJTv{
		display: block;
	}
	
	ul{
		position: fixed;
		width: 100%;
		height: 100vh;
		background: #2c3e50;
		top: 80px;
		left: -100%;
		text-align: center;
		transition: all .5s ease;
	}
	nav{
		width: 100%;
	}
	nav ul li{
		display: block;
		margin:50px 0;
		line-height: 30px;
	}
	
	nav ul li a{
		font-size: 18px;
		color: white;
	}
	nav a:hover{
		background: black;
		color: white;
		padding:20px;
		border: 2px solid yellow;
		border-radius:10px;
	}
	
	#homepage_check__3nnZN:checked ~ ul{
		left: 0;
	}
	
	#homepage_hidden__1rD42{
		position: absolute;
		left : 0px;
		top: 80px;
		width: 100%;
		background-color: #DFE7B4;
		display: flex;
		flex-direction: column;
		align-items: center;

	}
/**********************************/
	
	.homepage_Hero_section__3e8v-{
		border-radius: 30px;
		margin: 80px 4% 2% 4%;
		flex-direction: column;
		height: auto;
		/* background-image: none; */
		background-color: #fff3d7;
		border-radius: 30px;
	}

	.homepage_left_area__3PhwA{
		order: 2;
		flex: block 1;
		justify-content:center;
		align-items:center;
		width: 100%;
	}

	.homepage_left_area__3PhwA h1{
		text-align: center;
		letter-spacing: 1px;
		padding-bottom: 25px;
	}

	.homepage_left_area__3PhwA p{
		font-size: 18px;
		text-align: center;
	}

	a.homepage_btn_hero__2AjnK{
		margin-left: 35%;
	}

	a.homepage_btn_hero__2AjnK:hover{
		padding: 10px;
	}

	.homepage_right_area__3t6Hv{
		order: 1;
		width: 100%;
		margin-left: 12%;
	}

	.homepage_right_area__3t6Hv img{
		border-radius:10%;
		width: 90%;
		height:auto;
	}

	.homepage_hero_social_links__3zcwB {
		text-align: center;
	}

	.homepage_hero_social_links__3zcwB a{
		padding: 10px;
		background: none;
		border: none;
	}

/***********************************/
	.homepage_about_section__1mbKn{
		margin: 0 3%;
	}

	.homepage_about_image__148Zo{
		margin: 0;
		width: 100%;
	}

	.homepage_about_image_1__1K36A{
		width: 75%;
		border-radius: 20px;
	}
	
	.homepage_content_area__1hZTQ {
		display: block;
		width: 100%;
		margin: 2% 0 2% 0;
	}
	
	.homepage_about_content__1JYny{
		width: 100%;
		margin: 0;
	}

/**************** services *********************/
	.homepage_our_services__MPzJn{
		margin:1% 3%;
	}
	
	.homepage_service_item__2wUtX {
		float: left;
		width: 28%; 
		height:auto; 
		margin: 2% 2%;
		padding:2px;
		border-radius:10px;
	}

	.homepage_service_item__2wUtX p {
		font-size: 14px;
		padding: 2px;
	}

	.homepage_service_item__2wUtX h2{
		font-size:	20px;
	}
/***********************************/
	.homepage_right_sidelove__3scUY img{ 
		width: 90%;;
	}
/***********************************/
	.homepage_our_team__2g_8e{
		width: 94%;
		margin:1% 3%;
	}
	
	.homepage_main_profile__3yLmw {
		margin: 3%;
		width: 35%;
	}

	.homepage_profile_image__2gdsp img {
		/* width: 90%; */
	}	
/***********************************/
	.homepage_news_letter__kfW1N{
		width: 94%;
		margin: 2% 3%;
	}
	.homepage_main_box__2-5T7{
		flex-direction: column;
	}
	.homepage_left_area_box__22Y4m{
		width: 100%;
		margin: 2%;
		text-align: center;
	}
	.homepage_left_area_box__22Y4m img{
		width: 75%;
	}
	.homepage_right_area_box__2JQqx{
		width: 100%;
		margin: 2%;
		text-align: center;
	}
	.homepage_right_area_box__2JQqx h1{
		font-size: 32px;
	}
	.homepage_right_area_box__2JQqx p{
		font-size: 16px;
	}
/***********************************/
	.homepage_footer__3zxG0{
		margin:0 1%;
		width: 98%;
	}
	.homepage_footer_brand__36c-k h1{
		font-size: 20px;
	}
	.homepage_footer_company__pyhrS h1{
		font-size: 16px;
	}
	.homepage_footer_company__pyhrS li{
		font-size: 13px;
	}
	.homepage_footer_services__15moY h1{
		font-size: 16px;
	}
	.homepage_footer_services__15moY li{
		font-size: 13px;
	}
	.homepage_footer_address__d9RsL h1{
		font-size: 16px;
	}
	.homepage_footer_address__d9RsL p{
		font-size: 13px;
	}
/**************************************/
	.homepage_product_section__1sLPd{
		margin: 80px 3% 2% 3%;
		width: 94%;
	}
	.homepage_product_top__5H77P{
		width: 59%;
	}
	.homepage_product_description__3pGol h1{
		font-size: 20px;
	}

	.homepage_product_description__3pGol p{
		font-size: 13px;
		margin: 1% 0;; 
	}
	.homepage_product_order__14oUe{
		margin-top: 8px;
	}

	.homepage_product_order__14oUe h2{
		font-size:18px;
	}

	.homepage_product_order__14oUe a{
		padding: 7px 10px;
		font-size: 13px;
	}

	.homepage_product_order__14oUe span{
		padding: 6px;
		font-size: 13px;
	}
	.homepage_product_items__2BBx7 h1{
		font-size: 20px;
	}
	.homepage_product_items__2BBx7 li{
		font-size: 13px;
	}
	.homepage_product_divider__1HbUy {
		width: 60px;
	}
	.homepage_product_video__2-Zx0 video {
		width: 280px;
		height: 200px;
	}
	.homepage_product_video__2-Zx0 h1{
		font-size: 20px;
	}
	.homepage_product_video__2-Zx0 h2{
		font-size: 15px;
	}
	.homepage_carousel-container__20-Y5 {
		width: 45%;
		height: 40%;
	}
}

@media screen and (max-width: 700px){
    .homepage_our_services__MPzJn{
		margin:1% 3%;
	}

	.homepage_service_item__2wUtX {
		display: block;
		width:90%; 
		margin:3% 3%;
		/* padding: 2%; */
	}

	.homepage_service_item__2wUtX img{
		width: 50%;
		padding: 1px;;
	}

	.homepage_service_item__2wUtX p {
		font-size: 16px;
	}

	.homepage_service_item__2wUtX h2{
		font-size: 25px;
	}

/**************************************/
	.homepage_Gridlove_section__2ySzY{
		margin: 1% 0%;
	}
/**************************************/
	.homepage_our_team__2g_8e{
		width: 94%;
		margin: 1% 3%;
	}
	
	.homepage_main_profile__3yLmw {
		margin: 3%;
		width: 50%;
	}

	.homepage_profile_image__2gdsp img {
		/* width: 90%; */
	}
/**************************************/
	.homepage_right_area_box__2JQqx h1{
		font-size: 25px;
	}
/**************************************/
	.homepage_footer__3zxG0{
		background-image: none; 
		height: 530px;
		background: #f0f6ed;
		border-radius: 50px;
	}
	.homepage_footer_area__1lkgl{
		display: block;
	}
	.homepage_footer_box__L17fk{
		float: left;
		width: 40%;
		margin: 1% 5%;
	}
	.homepage_footer_brand__36c-k h1{
		font-size: 24px;
	}
	.homepage_footer_brand__36c-k p{
		font-size: 16px;
	}
	.homepage_footer_brand__36c-k i{
		font-size: 24px;
	}

	.homepage_footer_company__pyhrS{
		float: left;
		width: 40%;
		margin: 1% 5%;
	}

	.homepage_footer_company__pyhrS h1{
		font-size: 20px;
	}

	.homepage_footer_company__pyhrS li{
		font-size: 16px;
	}

	.homepage_footer_services__15moY{
		float: left;
		width: 40%;
		margin: 1% 5%;
	}

	.homepage_footer_services__15moY h1{
		font-size: 20px;
	}
	.homepage_footer_services__15moY li{
		font-size: 16px;
	}

	.homepage_footer_address__d9RsL{
		float: left;
		width: 40%;
		margin: 1% 5%;
	}
	.homepage_footer_address__d9RsL h1{
		font-size: 20px;
	}
	.homepage_footer_address__d9RsL p{
		font-size: 16px;
	}

/*************************************/
	.homepage_product_section__1sLPd{
		margin: 80px 3% 2% 3%;
		width: 94%;
	}
	.homepage_product_page__NBSsl{
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 1% 0;
		width: 100%;
	}
	.homepage_product_top__5H77P{
		width: 100%;
	}
	.homepage_product_description__3pGol h1{
		font-size: 28px;
	}
	.homepage_product_description__3pGol p{
		font-size: 16px;
		margin: 2% 0;; 
	}
	.homepage_product_order__14oUe{
		margin-top: 20px;
	}
	.homepage_product_order__14oUe h2{
		font-size:22px;
	}
	.homepage_product_order__14oUe a{
		padding: 10px 30px;
		font-size: 15px;
	}
	.homepage_product_order__14oUe span{
		padding: 10px;
		font-size: 15px;
	}
	.homepage_product_bottom__tZRjD{
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.homepage_product_items__2BBx7{
		margin-top: 15px;
		width:100%;
		height: auto;
		order: 2;
	}
	.homepage_product_items__2BBx7 h1{
		font-size: 28px;
	}
	.homepage_product_items__2BBx7 li{
		font-size: 15px;
	}
	.homepage_product_divider__1HbUy {
		width: 80px;
	}
	.homepage_product_video__2-Zx0{
		margin-top: 15px;
		width: 100%;
		height: 100%;
		order: 1;
	}
	.homepage_product_video__2-Zx0 video {
		width: 96%;
		height: auto;
	}
	.homepage_product_video__2-Zx0 h1{
		font-size: 28px;
	}
	.homepage_product_video__2-Zx0 h2{
		font-size: 20px;
	}
	.homepage_carousel-container__20-Y5 {
		position: relative;
		top: 0;
		left: 0;
		margin: 1% auto;
		width: 100%;
		height: 60vh;
	}
}


@media screen and (max-width: 600px){
    .homepage_footer__3zxG0{
		height:auto;
	}
	.homepage_footer_area__1lkgl{
		display: block;
	}
	.homepage_footer_box__L17fk{
		float: none;
		width: 70%;
		margin: 0 15% 5% 15%;
	}

	.homepage_footer_company__pyhrS{
		float: none;
		width: 70%;
		margin: 0 15% 5% 15%;
	}

	.homepage_footer_services__15moY{
		float: none;
		width: 70%;
		margin: 0 15% 5% 15%;
	}

	.homepage_footer_address__d9RsL{
		float: none;
		width: 70%;
		margin: 0 15% 5% 15%;
	}
	section .homepage_thumbnail__2fvRv{
	    width: 100%;
	}

	section .homepage_thumbnail__2fvRv img{
	    width: 100%;
	    height: AUTO;
	}
	.homepage_product-details__h63UO h2{
	    font-size: 1.5rem;
	}
	section.homepage_m_container__3CJLd .homepage_m_thumbnail__j8XAk{
	    width: 70%;
	}
	.homepage_month__1dwll{
	    padding: 1.5rem 2.3rem;
	}

	.homepage_month__1dwll h2{
	    font-size: 1.6rem;
	}

}


@media screen and (max-width: 480px){
	.homepage_description_sidelove__e_Upv{
		margin-left: 20%;
		margin-top: -45px;
		margin-bottom: 20px;
		text-align: justify;
	}
}


/**************** comming up Page *****************/
.homepage_commingupImg__PmYuq {
	margin-top: 80px;
	text-align: center;
}

.homepage_commingupImg__PmYuq img{
	width:500px;
	margin-left: auto;
	margin-right: auto;
}

@media screen and (max-width: 680px){
	.homepage_commingupImg__PmYuq img{
		width:300px;
		
	}
}
#croplist_city__1wL-2{
	background-color: #fff; 
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-position: 98% center;
	background-size: 15px;
	-webkit-appearance: none;
	appearance: none;
}

/* .image_details p{ margin:0; padding: 0;} */

.croplist_main_search_wrapper__17pDJ{
	margin: 0 8%;
	margin-top: 80px;
	width: 84%;
}
.croplist_search_heading__aGVLy h1{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
	margin: 0.7rem 0;
	font-size: 2.6rem;
	padding: 1% 1% 0 1%;
}
.croplist_search_wrapper__3ZCQ1{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}
.croplist_search_wrapper__3ZCQ1 h1{
	font-size: 2.3rem;
	padding: 1rem 0;
    padding: 0.3rem 0 1rem 0;
}
.croplist_search_wrapper__3ZCQ1 p{
	font-size: 18px;
}
.croplist_search_filter__1uh8_{
	width: 30%;
	height: auto;
	padding: 1% 0 1% 1%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.croplist_search_section_1__1zJsl, .croplist_search_section_2__1KPQV{
	width: 100%;
}
.croplist_search_timing__1TAWm i{
	font-size: 2.5rem;
	margin-left: 2rem;
	margin-bottom: 1.5rem;
	color: #fff;
	background-color: black;
	border-radius: 50px;
	padding: 1px 3px;
}
.croplist_search_demand__2xQc7 i{
	font-size: 2.5rem;
	margin-left: 2rem;
	margin-bottom: 1.5rem;
	color: #000;
}
.croplist_search_form__3GYDr{
	width: 100%;
	height: auto;
}
form.croplist_search_corp_form__2cH9g{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.croplist_each_search_field__gTjqc{
	width: 100%;
	margin-bottom: 1%;
}
.croplist_each_search_field__gTjqc label{
	width: 100%;
	font-size: 18px;
	letter-spacing: 2px;
}
form.croplist_search_corp_form__2cH9g select{
	width: 100%;
	padding: 0.4rem 0.5rem;
	margin-bottom: 1%;
	border-radius: 5px;
	border: none;
	box-shadow: 1px 3px 5px 1px rgb(154, 153, 153);
}
.croplist_search_content__gFzOE{
	width: 68%;
	height: auto;
	padding: 1% 0 1% 1%;
	display: flex;
	flex-direction: column;
}
.croplist_each_content__2k48X{
	width: 100%;
	height: auto;
	display: flex;
	margin-bottom: 4%;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	padding: 3%;
	background: #fff8ce;
	border-radius: 20px;
}
.croplist_search_image__kDkcD{
	width: 230px;
	height: auto;
}
.croplist_search_image__kDkcD img{
	width: 100%;
	height: auto;
	border-radius: 20px;
}
.croplist_image_details__2YTko{
	width: 65%;
	height: auto;
}
i.croplist_heart_icon_black__1vEew{
	position: relative;
	top: 0;
	left: 90%;
	font-size: 2rem;
	color: red;
}
.croplist_heart_icon_red__1rKf6{
	position: relative;
	top: 0;
	left: 90%;
	font-size: 1.5rem;
	color: red;
	background-color: transparent;
    background-repeat: no-repeat;
    border: none;
}
.croplist_image_details__2YTko h5{
    font-family: 'Arapey', serif;
	font-size: 2rem;
	letter-spacing: 0.2rem;
}
.croplist_image_details__2YTko p{
	font-size: 1rem;
    /* line-height: 20px; */
}
.croplist_image_details__2YTko p i{
	font-size: 2rem;
	color: #386dd7;
	margin: 0 1rem 0 0.3rem;
}
i.croplist_emot__1lCcv{
	position: relative;
	left: 2%;
	background: rgb(230, 132, 5);
	border-radius: 50px;
	padding: 1px 2px;
	margin: 0 auto;
}
.croplist_read_more__3bFyR{
	font-size: 1rem;
	padding: 0.5rem 2rem;
	text-align: end;
	margin: 2%;
	position: relative;
	bottom: 0;
	left: 65%;
	border-radius: 50px;
	border: none;
	color: white;
	background: #FB9F00;
	box-shadow: 0px 1px 1px 0px gray;
	cursor: pointer;
}
.croplist_read_more__3bFyR:hover{
	background: rgba(222, 165, 65, 0.5);
    text-decoration: none;
}


.croplist_searchBtn__2Xt15{
	background-color:#FB9F00;
    border-radius: 90px;
    border: none; 
    color: white;
    padding: 8px 30px;
    font-size: 20px;
    text-align: center;
    margin-top: 3%;
}

@media screen and (max-width: 1200px){
    .croplist_main_search_wrapper__17pDJ{
		margin: 0 2%;
		margin-top: 80px;
		width: 96%;
	}
	.croplist_main_search_wrapper__17pDJ > h1{
		margin: 0.5rem 0;
		font-size: 2.3rem;
	}
	.croplist_search_wrapper__3ZCQ1 h1{
		font-size: 2rem;
	}
	.croplist_search_wrapper__3ZCQ1 p{
		font-size: 18px;
	}
	.croplist_search_timing__1TAWm i{
		font-size: 2.2rem;
		margin-left: 1.5rem;
	}
	.croplist_search_demand__2xQc7 i{
		font-size: 2.2rem;
		margin-left: 1.5rem;
	}
	.croplist_search_wrapper__3ZCQ1 label{
		font-size: 1.3rem;
	}
	.croplist_search_form__3GYDr form select{
		width: 100%;
	}
	.croplist_each_content__2k48X{
		padding: 2% 2%;
	}
	i.croplist_heart_icon_black__1vEew{
		font-size: 2.2rem;
	}
	i.croplist_heart_icon_red__1rKf6{
		font-size: 2.2rem;
	}
	.croplist_image_details__2YTko h5{
		font-size: 2rem;
	}
	.croplist_image_details__2YTko p{
		font-size: 1rem;
		padding: 0 0 0.7rem 0;
        /* line-height: 7px; */
	}
	.croplist_image_details__2YTko p i{
		font-size: 1.6rem;
	}
	.croplist_read_more__3bFyR{
		font-size: 1.2rem;
		padding: 0.5rem 1.8rem;
	}
}

@media screen and (max-width: 990px){	
    .croplist_main_search_wrapper__17pDJ{
		margin: 0 1%;
		margin-top: 80px;
		width: 98%;
	}
	.croplist_search_wrapper__3ZCQ1 h1{
		font-size: 1.8rem;
	}
	.croplist_search_wrapper__3ZCQ1 p{
		font-size: 1.2rem;
	}
	.croplist_search_timing__1TAWm i{
		font-size: 2rem;
		margin-left: 1.3rem;
	}
	.croplist_search_demand__2xQc7 i{
		font-size: 2rem;
		margin-left: 1.3rem;
	}
	.croplist_search_wrapper__3ZCQ1 label{
		font-size: 1.2rem;
	}
	.croplist_each_content__2k48X{
		padding: 2% 2%;
	}
	i.croplist_heart_icon_black__1vEew{
		font-size: 2rem;
	}
	i.croplist_heart_icon_red__1rKf6{
		font-size: 2rem;
	}
	.croplist_image_details__2YTko h5{
		font-size: 2rem;
		letter-spacing: 0.1rem;
        padding-left: 0.6em;
	}
	.croplist_image_details__2YTko p{
		font-size: 1rem;
		/* padding: 0 0 0.6rem 0; */
        /* line-height: 7px; */
        padding-left: 1.5em;
	}
	.croplist_image_details__2YTko p i{
		font-size: 1.6rem;
	}
	.croplist_read_more__3bFyR{
		font-size: 1.1rem;
		padding: 0.4rem 1.6rem;
	}
	
}


@media screen and (max-width: 890px){
    .croplist_main_search_wrapper__17pDJ{
		margin: 0 1%;
		margin-top: 80px;
		width: 98%;
	}
	.croplist_main_search_wrapper__17pDJ > h1{
		font-size: 2.6rem;
		padding: 1%;
		text-align: center;
	}
	.croplist_search_wrapper__3ZCQ1{
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.croplist_search_wrapper__3ZCQ1 h1{
		font-size: 2.3rem;
		padding: 1rem 0;
	}
	.croplist_search_wrapper__3ZCQ1 p{
		font-size: 1.6rem;
		padding: 0.3rem 0 1rem 0;
	}
	.croplist_search_filter__1uh8_{
		width: 100%;
		padding: 1%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.croplist_search_section_1__1zJsl{
		width: 40%;
	}
	.croplist_search_section_2__1KPQV{
		width: 60%;
		margin-top: 3%;	
	}
	.croplist_each_search_field__gTjqc label{
		width: 60%;
		font-size: 24px;
	}
	.croplist_search_content__gFzOE{
		width: 100%;
		padding: 1%;
	}

    .croplist_image_details__2YTko h5{
        padding-left: 0.8em;
		font-size: 2em;
	}
	.croplist_image_details__2YTko p{
        padding-left: 1.5em;
		font-size: 1rem;
		/* line-height: 1px; */
	}

	.croplist_image_details__2YTko p i{
		font-size: 2rem;
	}


}

@media screen and (max-width: 700px){
    .croplist_search_section_1__1zJsl{
		width: 40%;
	}
	.croplist_search_section_2__1KPQV{
		width: 60%;
		margin-top: 4%;
	}
	.croplist_search_content__gFzOE{
	width: 100%;
	padding: 1%;
	}
	.croplist_each_content__2k48X{
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2%;
	}
	.croplist_search_image__kDkcD{
		width: 100%;
		height: 180px;
		text-align: center;
	}
	.croplist_search_image__kDkcD img{
		width: auto;
		height: 180px;
	}
	.croplist_image_details__2YTko{
		width: 100%;
        text-align: center;
	}
	i.croplist_heart_icon_black__1vEew{
		font-size: 2.5rem;
	}
	i.croplist_heart_icon_red__1rKf6{
		font-size: 2.5rem;
	}
	.croplist_image_details__2YTko h5{
		text-align: center;
        padding-left: 1em;
		font-size: 2em;
	}
	.croplist_image_details__2YTko p{
		font-size: 1.2rem;
		/* line-height: 7px; */
	}

	.croplist_image_details__2YTko p i{
		font-size: 2rem;
	}
	i.croplist_emot__1lCcv{
		padding: 1px 1px;
	}
	.croplist_read_more__3bFyR{
		font-size: 1.2rem;
		padding: 0.6rem 2.5rem;
		position: relative;
		bottom: 0;
		left: 0%;
	}

}

@media screen and (max-width: 600px){
    .croplist_search_filter__1uh8_{
		width: 100%;
		padding: 1%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.croplist_search_section_1__1zJsl{
		width: 100%;
		text-align: center;
	}
	.croplist_search_section_2__1KPQV{
		width: 100%;
		margin-top: 4%;
	}
	.croplist_search_content__gFzOE{
		width: 100%;
		padding: 1%;
	}
	.croplist_each_content__2k48X{
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2%;
	}
	.croplist_search_image__kDkcD{
		width: 100%;
		height: 180px;
		text-align: center;
	}
	.croplist_search_image__kDkcD img{
		width: auto;
		height: 180px;
	}
	.croplist_image_details__2YTko{
		width: 100%;
        text-align: center;
	}
	i.croplist_heart_icon_black__1vEew{
		font-size: 2.5rem;
	}
	i.croplist_heart_icon_red__1rKf6{
		font-size: 2.5rem;
	}
	.croplist_image_details__2YTko h5{
		text-align: center;
		font-size: 2rem;
	}
	.croplist_image_details__2YTko p{
		font-size: 1.3rem;
		letter-spacing: 0.1rem;
        /* line-height: 7px; */
	}
	.croplist_image_details__2YTko p i{
		font-size: 2rem;
       
	}
	i.croplist_emot__1lCcv{
		padding: 1px 2px;
	}
	.croplist_read_more__3bFyR{
		font-size: 1.2rem;
		padding: 0.5rem 2.3rem;
		position: relative;
		bottom: 0;
		left: 0%;
	}

}

@media screen and (max-width: 480px){
    .croplist_search_filter__1uh8_{
		width: 100%;
		padding: 1%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.croplist_search_section_1__1zJsl{
		width: 100%;
		text-align: center;
	}
	.croplist_search_section_2__1KPQV{
		width: 100%;
		margin-top: 4%;
	}
	.croplist_search_content__gFzOE{
		width: 100%;
		padding: 1%;
	}
	.croplist_each_content__2k48X{
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2%;
	}
	.croplist_search_image__kDkcD{
		width: 100%;
		height: 200px;
		text-align: center;
	}
	.croplist_search_image__kDkcD img{
		width: 60%;
		height: 200px;
	}
	.croplist_image_details__2YTko{
		width: 100%;
	}
	i.croplist_heart_icon_black__1vEew{
		font-size: 2.5rem;
	}

	.croplist_heart_icon_red__1rKf6{
		position: relative;
		top: 0;
		left: 45%;
		font-size: 1.5rem;
		color: red;
	}

	i.croplist_heart_icon_red__1rKf6{
		font-size: 2.5rem;
	}
	.croplist_image_details__2YTko h5{
		text-align: center;
		font-size: 2rem;
	}
	.croplist_image_details__2YTko p{
		font-size: 1rem;
		letter-spacing: 0.1rem;
	}
	.croplist_image_details__2YTko p i{
		font-size: 2rem;
	}
	i.croplist_emot__1lCcv{
		padding: 1px 2px;
	}
	.croplist_read_more__3bFyR{
		font-size: 1rem;
		padding: 0.5rem 2.3rem;
		position: relative;
		bottom: 0;
		left: 0%;
	}
	
}

.croplist_cropListContainer__1vVnr{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.croplist_cropListContainer__1vVnr h3{
    font-family: 'Arapey', serif;
    font-size: 50px;
    color: black;
    line-height: 70px;
    margin-bottom: 3%;
}

.croplist_cropContainer__2wCN1{
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.croplist_formContainer__1zDKU{
    text-align: center;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}




/******************** banner area ***************/
.twhomepage_Hero_section__22OxE {
    margin: 80px 7% 2% 7%;
    background-size: 100% 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #fff3d7;
    border-radius: 30px;
}

.twhomepage_left_area__4Hq65 {
    margin: 20px;
    width: 50%;
}

.twhomepage_left_area__4Hq65 h1 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 1px;
    padding-bottom: 20px;
}

.twhomepage_left_area__4Hq65 p {
    font-size: 16px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

a.twhomepage_btn_hero__2f8Af {
    text-decoration: none;
    padding: 15px 50px;
    border: 3px solid green;
    border-radius: 50px;
    color: black;
    font-weight: 700;
}

a.twhomepage_btn_hero__2f8Af:hover {
    border-radius: 50px;
	padding: 15px 50px;
    background: green;
    color: white;
}


.twhomepage_right_area__3ucOm {
    width: 50%;
    /* height: 70%; */
    margin-left: 20px;
    margin-right: 20px;
}

.twhomepage_right_area__3ucOm img {
    border-radius: 20px 20px 100px 20px;
    width: 100%;
    height: 450px;
}

.twhomepage_hero_social_links__SHHhC {
    padding: 15px 0;
    margin-top: 50px;
}

.twhomepage_hero_social_links__SHHhC i {
    font-size: 30px;
    color: blue;
    border-radius: 5px;
    z-index: 10;
}

.twhomepage_hero_social_links__SHHhC a {
    padding: 0 20px;
}

/* ************************* About Section ****************** */

.twhomepage_about_section__51THr {
    margin: 0 8%;
    display: flex;
    justify-content: center;
    border-radius: 30px;
}

.twhomepage_about_image__1G65p {
    margin: 1% 1%;
    width: 48%;
    height: auto;
    float: left;
}

.twhomepage_about_image_1__K82F- {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.twhomepage_content_area__5MS5T {
    width: 48%;
    margin: 1% 1%;
}

.twhomepage_about_content__1cgzY {
    padding: 0% 1%;
    border-radius: 20px;
    width: 100%;
    height: auto;
    float: right;
}

.twhomepage_about_content__1cgzY h1 {
    color: orange;
    font-weight: bold;
    line-height: 40px;
    padding: 10px 10px 0 10px;
    text-transform: uppercase;
}

.twhomepage_divider_about__3-8P4 {
    border: 3px solid #2fe7eb;
    width: 110px;
    margin-left: 10px;
    border-radius: 10px;
}

.twhomepage_about_content__1cgzY h2 {
    font-size: 18px;
    font-weight: bold;
    padding: 15px 0px 10px 10px;
}

.twhomepage_about_content__1cgzY p {
    line-height: 24px;
    font-size: 16px;
    text-align: justify;
    padding: 10px 10px;
}

/*********************** our services *****************************/

.twhomepage_our_services__mqJsT{
	padding: 10px;
	margin:1% 7%;
	text-align: center;
	height: auto;
	display: block;
}

.twhomepage_our_services__mqJsT h1 {
	text-align: center; 
	font-weight:bold;
	line-height: 40px;
	padding: 10px 10px 0 10px;
	text-transform: capitalize;
}

.twhomepage_divider_services__W5E4j {
	border: 3px solid #2016db;
	width: 150px;
	margin-left: 10px;
	border-radius: 10px;
	margin:0 auto;
}

.twhomepage_our_services__mqJsT p {
	font-size: 20px;
	font-weight: 500;
	text-align: center; 
	padding: 20px 0;
}

.twhomepage_service_item__25W1Z {
	float: left;
	width:26%; 
	height:auto; 
	margin: 2% 3%;
	padding:5px;
	border-radius:10px;
}

.twhomepage_service_item__25W1Z:hover {
	box-shadow: 0px 0px 2px 5px #f1f2e9 inset;
}

.twhomepage_service_item__25W1Z img{
	width: 75%;
	padding: 5px;;
}

.twhomepage_service_item__25W1Z p {
	font-size: 16px;
	text-align: justify;
	line-height: 24px;
	padding: 5px;
}

.twhomepage_service_item__25W1Z h2{
	padding: 20px 0 0 0;
	text-transform: capitalize;
}

/******************************* How it wrok Section ****************************/

.twhomepage_Gridlove_section__3Y1yl{
	display: block;
	padding:1% 1%;
	height: auto;
	margin: 1% 8%; 
	border-radius: 30px;
}

.twhomepage_number-title__2YdNA h1{
	padding: 10px 0px 10px 80px;
	font-weight:bold;
	line-height: 40px;
}

.twhomepage_description_sidelove__28Owm p{
	line-height:25px;
}


.twhomepage_left_girdlove__23r8p{
	width: 50%;
	height: auto;
	display: block;
	float: left;
}

.twhomepage_circle_sidelove__2ekoj{
	margin:0px;
	padding:0px;
	width:50px;
	height:50px;
	color:white;
	background-color:#e2bb38;
	border:1px solid white;
	border-radius:50%;
	text-align:center;
	font-weight:700;
	line-height:50px;
}

.twhomepage_description_sidelove__28Owm{
	margin-left: 80px;
	margin-top: -45px;
	margin-bottom: 20px;
	text-align: justify;
}

.twhomepage_main_grid_love__25xvp{ 
	margin: 30px 0;
}

.twhomepage_right_sidelove__2CwP7{
	float:right;
	width: 45%;
    margin-left: auto;
    margin-right: auto;
}

.twhomepage_right_sidelove__2CwP7 img{ 
	width: 500px; 
	height: 500px;
}


/******************************* Our Team ************************************/

.twhomepage_our_team__3_C5f{
	width: 84%;
	text-align: center;
	padding: 1% 1%;
	margin:1% 8%;
	border-radius:30px;
}

.twhomepage_our_team__3_C5f h1{
	padding:10px 10px 0 10px;
}

.twhomepage_divider_team__3n0wY {
	border: 3px solid #2016db;
	width: 100px;
	margin: 0 auto;
	border-radius:10px;	
}

.twhomepage_profile_area__3FmFj {
	padding: 10px 0 0px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.twhomepage_main_profile__3PQ2R {
	/* margin: 3%; */
	/* width: 25%; */
	/* padding:1%; */
	
}

.twhomepage_profile_image__2f0SP img {
	border-radius: 90%;
}

.twhomepage_profile_name__BNF1R{
	font-weight:bold;
	font-size: 18px;
	padding: 10px 5px 5px 5px;
}

.twhomepage_profile_designation__3wr-O{
	font-size: 16px;
	padding:5px;
}

.twhomepage_profile_social_links__2HqSO{
	padding: 10px;
}

.twhomepage_profile_social_links__2HqSO span{
	padding: 0px 10px;
}


@media screen and (max-width: 1200px) {
    .twhomepage_Hero_section__22OxE {
        margin: 80px 6% 2% 6%;
    }

    .twhomepage_left_area__4Hq65 h1 {
        line-height: 40px;
        letter-spacing: 1px;
        padding-bottom: 18px;
    }

    .twhomepage_left_area__4Hq65 p {
        font-size: 15px;
        padding-bottom: 18px;
        margin-bottom: 18px;
    }

    .twhomepage_right_area__3ucOm {
        width: 50%;
        margin: 20px;
    }

    .twhomepage_hero_social_links__SHHhC {
        padding: 15px 0;
        margin-top: 50px;
    }

    a.twhomepage_btn_hero__2f8Af {
        padding: 10px 30px;
    }

    .twhomepage_hero_social_links__SHHhC i {
        font-size: 26px;
    }

    /* ************************* About Section ****************** */

    .twhomepage_about_section__51THr {
        margin: 0 6%;
    }

    .twhomepage_about_image__1G65p {
        margin: 0;
        width: 50%;
    }

    .twhomepage_content_area__5MS5T {
        width: 50%;
        margin: 0;
    }

    .twhomepage_about_content__1cgzY {
        padding: 2%;
    }

    .twhomepage_about_content__1cgzY h1 {
        line-height: 30px;
        font-size: 30px;
        padding: 8px;
    }

    .twhomepage_about_content__1cgzY h2 {
        font-size: 16px;
        font-weight: bold;
        padding: 15px 0px 10px 10px;
    }

    .twhomepage_about_content__1cgzY p {
        line-height: 24px;
        font-size: 16px;
        text-align: justify;
        padding: 5px;
    }

    /*************** services *********************/
	.twhomepage_our_services__mqJsT{
		margin:1% 6%;
		text-align: center;
	}

	.twhomepage_our_services__mqJsT p{
		padding-top: 16px;
	}
	
	.twhomepage_profile_area__3FmFj {
		padding-top: 15px;
	}

    /**************** how it work**********************/
	.twhomepage_Gridlove_section__3Y1yl{
		margin: 1% 4%; 
	}
	
	.twhomepage_number-title__2YdNA h1{
		font-size: 30px;
		line-height: 35px;
	}

	.twhomepage_description_sidelove__28Owm p{
		line-height:22px;
	}
	
    /************** our team **********************/
	.twhomepage_our_team__3_C5f{
		width: 88%;
		margin:1% 6%;
	}


}




@media screen and (max-width: 990px) {
    .twhomepage_Hero_section__22OxE {
        margin: 80px 4% 2% 4%;
    }

    .twhomepage_left_area__4Hq65 h1 {
        font-size: 30px;
        letter-spacing: 0px;
        padding-bottom: 16px;
    }

    .twhomepage_left_area__4Hq65 p {
        padding-bottom: 16px;
        margin-bottom: 16px;
    }

    .twhomepage_right_area__3ucOm {
        width: 50%;
        margin: 20px;
    }

    .twhomepage_hero_social_links__SHHhC {
        padding: 10px 0;
        margin-top: 40px;
    }

    .twhomepage_hero_social_links__SHHhC i {
        font-size: 24px;
    }

    /************************ about section **************/

    .twhomepage_content_area__5MS5T {
		display: block;
		width: 100%;
		margin: 1% 0 2% 0;
	}

	.twhomepage_about_image__1G65p{
		width: 100%;
		text-align: center;
	}

	.twhomepage_about_image_1__K82F-{
		width: 100%;
		margin-bottom: 10px;
	}
	
	.twhomepage_about_section__51THr{
		display: block;
		margin: 0 4%

	}
	
	.twhomepage_about_content__1cgzY{
		width: 100%;
		margin: 2%;
	}

    /**************** services *********************/
	.twhomepage_our_services__mqJsT{
		margin:1% 0%;
	}
	
	.twhomepage_service_item__25W1Z {
		width:27%; 
		margin: 2% 3%;
	}

    /***************** how it work ********************/
	.twhomepage_Gridlove_section__3Y1yl{
		margin: 1% 2%;
		padding: 2%;		
	}
	
	.twhomepage_main_grid_love__25xvp{
		width: 100%;
	}
	
	.twhomepage_left_girdlove__23r8p{
		width: 100%;
	}

	.twhomepage_description_sidelove__28Owm{
		margin-left: 60px;
		margin-top: -45px;
		margin-bottom: 20px;
		text-align: justify;
	}
		
	.twhomepage_description_sidelove__28Owm{
		width: 90%;
	}

	.twhomepage_right_sidelove__2CwP7{
		width: 96%;
		text-align: center;
		margin:2% 0;
	}

	.twhomepage_right_sidelove__2CwP7 img{ 
		width: 60%; 
        height: 60%;
	}

	
    /************* our team**********************/
	.twhomepage_our_team__3_C5f{
		width: 92%;
		margin:1% 4%;
	}
	
	.twhomepage_profile_image__2f0SP img {
		width: 90%;
	}	

}

@media screen and (max-width: 890px) {
    .twhomepage_Hero_section__22OxE {
        border-radius: 30px;
        margin: 80px 4% 2% 4%;
        flex-direction: column;
        height: auto;
        /* background-image: none; */
        background-color: #fff3d7;
        border-radius: 30px;
    }

    .twhomepage_left_area__4Hq65 {
        order: 2;
        flex: block 1;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .twhomepage_left_area__4Hq65 h1 {
        text-align: center;
        letter-spacing: 1px;
        padding-bottom: 25px;
    }

    .twhomepage_left_area__4Hq65 p {
        font-size: 18px;
        text-align: center;
    }

    a.twhomepage_btn_hero__2f8Af {
        margin-left: 35%;
    }

    a.twhomepage_btn_hero__2f8Af:hover {
        padding: 10px 40px;
    }

    .twhomepage_right_area__3ucOm {
        order: 1;
        width: 100%;
        margin-left: 12%;
    }

    .twhomepage_right_area__3ucOm img {
        border-radius: 10%;
        width: 90%;
        height: auto;
    }

    .twhomepage_hero_social_links__SHHhC {
        text-align: center;
    }

    .twhomepage_hero_social_links__SHHhC a {
        padding: 10px;
        background: none;
        border: none;
    }
    /**************** about section *******************/
	/* .about_section{
		margin: 0 4%;
	} */

	.twhomepage_about_image__1G65p{
		width: 90%;
	}

	.twhomepage_about_image_1__K82F-{
		width: 80%;
		border-radius: 20px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.twhomepage_content_area__5MS5T {
		display: block;
		width: 95%;
		margin: 2% 0 2% 0;
	}
	
	.twhomepage_about_content__1cgzY{
		width: 95%;
		margin: 0;
	}

    /**************** services *********************/
	.twhomepage_our_services__mqJsT{
		margin:1% 3%;
	}
	
	.twhomepage_service_item__25W1Z {
		float: left;
		width: 28%; 
		height:auto; 
		margin: 2% 2%;
		padding:2px;
		border-radius:10px;
	}

	.twhomepage_service_item__25W1Z p {
		font-size: 14px;
		padding: 2px;
	}

	.twhomepage_service_item__25W1Z h2{
		font-size:	20px;
	}

    /**************our team*********************/
	.twhomepage_our_team__3_C5f{
		width: 94%;
		margin:1% 3%;
	}


	.twhomepage_profile_image__2f0SP img {
		width: 90%;
	}	
}

@media screen and (max-width: 700px){

    /**************** services *********************/

    .twhomepage_our_services__mqJsT{
		margin:1% 3%;
	}

	.twhomepage_service_item__25W1Z {
		display: block;
		width:90%; 
		margin:3% 3%;
		/* padding: 2%; */
	}

	.twhomepage_service_item__25W1Z img{
		width: 50%;
		padding: 1px;;
	}

	.twhomepage_service_item__25W1Z p {
		font-size: 16px;
	}

	.twhomepage_service_item__25W1Z h2{
		font-size: 25px;
	}

    /***************** how it work *********************/

    .twhomepage_Gridlove_section__3Y1yl{
		margin: 1% 4%;
	}


    .twhomepage_circle_sidelove__2ekoj{
        margin:0px;
        padding:0px;
        width:40px;
        height:40px;
        line-height:40px;
    }


    /***************** our team *********************/
	.twhomepage_our_team__3_C5f{
		width: 94%;
		margin: 1% 3%;
	}
	
	.twhomepage_profile_image__2f0SP img {
		width: 90%;
	}


}


@media screen and (max-width: 480px){

    .twhomepage_Gridlove_section__3Y1yl{
		margin: 1% 3%;
		padding: 2%;		
	}
	
    .twhomepage_description_sidelove__28Owm p{
       font-size: 15px;

    }

	.twhomepage_main_grid_love__25xvp{
		width: 90%;
	}
	
	.twhomepage_left_girdlove__23r8p{
		width: 90%;
	}

	.twhomepage_description_sidelove__28Owm{
		margin-left: 55px;
		margin-top: -40px;
		margin-bottom: 30px;
		text-align: justify;
	}
		
	.twhomepage_description_sidelove__28Owm{
		width: 90%;
	}

	.twhomepage_right_sidelove__2CwP7{
		width: 96%;
		text-align: center;
		margin:2% 0;
	}

	.twhomepage_right_sidelove__2CwP7 img{ 
		width: 80%; 
        height: 80%;
	}


}
.cropPageStyle_main_wrapper_item__2drz5{
    margin: 90px 10% 2% 10%;
	width: 80%;
	height: auto;
	background-color: #DFE7B4;
	border-radius: 50px;
	padding: 1% 2%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.cropPageStyle_top_item__2tm-S{
	width: 98%;
	height: auto;
	margin: 1%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.cropPageStyle_item_image__3Qb4n{
	width: 60%;
	/* max-height: 350px; */
	text-align: center;
    padding: 2%;
}

.cropPageStyle_item_image__3Qb4n img{
	width: 100%;
	min-width: 180px;
	max-height: 350px;
	border-radius: 30px;
	border: 1px solid lightgrey;
}

.cropPageStyle_item_details__2oeso{
	width: 70%;
	height: auto;
	padding: 1.5% 5%;
}

.cropPageStyle_item_details__2oeso h3{
	font-family: 'Arapey', serif;
    font-size: 40px;
    color: #FB9F00;
    line-height: 50px;
}

.cropPageStyle_item_details__2oeso p{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}

.cropPageStyle_item_details__2oeso span{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    padding: 0.3rem 0.3rem;
}


/* middle button  */

.cropPageStyle_middle_tabs__3e6AP{
    display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 98%;
	height: auto;
	margin: 2%;
	flex-wrap: wrap;
}

.cropPageStyle_tab_btn__2Hjq5{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    padding: 0.5rem 2.5rem;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	background-color: #3F5552;
	color: white;
	border: none;
    border-radius: 90px;
	cursor: pointer;
    /* margin: 3%; */
}

.cropPageStyle_tab_btn__2Hjq5:hover{
	background-color: rgba(63, 85, 82, 0.7);
}

.cropPageStyle_tabs_toggle__iqK0M{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    padding: 0.5rem 2rem;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	background-color: white;
	color:  #3F5552;
	border: none;
    border-radius: 90px;
	cursor: pointer;
    margin: 3%;
    border: 3px solid #3F5552 ;
}

/* under buttom */
.cropPageStyle_bottom_tabs__2Gx9o{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 70%;
	height: auto;
	margin: 1%;
}

.cropPageStyle_tab_btn2__2aZkm{
	padding: 0.8rem 2rem;
	font-size: 1.2rem;
	letter-spacing: 0.2rem;
	background-color: rgba(250, 169, 5, 1);
	color: white;
	border: none;
	border-radius: 50px;
	cursor: pointer;
}

.cropPageStyle_tab_btn2__2aZkm:hover{
	background-color: white;
    color: rgba(250, 169, 5, 1);
    border: 3px solid rgba(250, 169, 5, 1) ;
}


.cropPageStyle_active_btn2__2oWP4{
	padding: 0.8rem 2rem;
	font-size: 1rem;
	letter-spacing: 0.2rem;
	background-color: white;
	color: rgba(250, 169, 5, 1);
	border: none;
	border-radius: 50px;
    border: 3px solid rgba(250, 169, 5, 1) ;
}

.cropPageStyle_active_btn2__2oWP4:hover{
	background-color: rgba(250, 169, 5, 1) ;
	color: white;
    text-decoration: none;
}

/* detail area */

.cropPageStyle_details__3awYQ{
    display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 94%;
	height: 100%;
	margin: 1%;
	overflow-y: scroll;
    margin-left: auto;
    margin-right: auto;
}

.cropPageStyle_details__3awYQ::-webkit-scrollbar{
	width: 15px;
    border-radius: 20px;
}
.cropPageStyle_details__3awYQ::-webkit-scrollbar-track{
	background: #f1f1f1;
}
.cropPageStyle_details__3awYQ::-webkit-scrollbar-thumb{
	background: #3F5552;
}
.cropPageStyle_details__3awYQ::-webkit-scrollbar-thumb:over{
	background: #555;
}

.cropPageStyle_scroll_area__7Av5H{
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	height: 300px;
	overflow-y: scroll;
	margin-left: auto;
	margin-right: auto;
}

.cropPageStyle_scroll_area__7Av5H::-webkit-scrollbar{
	width: 15px;
}
.cropPageStyle_scroll_area__7Av5H::-webkit-scrollbar-track{
	background: white;
	border-radius: 90px;
}
.cropPageStyle_scroll_area__7Av5H::-webkit-scrollbar-thumb{
	background: rgba(62, 110, 30, 1);
	border-radius: 90px;
}
.cropPageStyle_scroll_area__7Av5H::-webkit-scrollbar-thumb:over{
	background: #555;
}

.cropPageStyle_nodata__23Iao{
	display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    width: 300%;
	height: 30px;
	margin: 1%;
}

/* Irrigation */
.cropPageStyle_irrigation_form__24U--{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	margin: 0 3%;
	flex-wrap: wrap;
}

/* Pest & Disease */
.cropPageStyle_title__1HaYe{
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    padding-left: 3%;
}

.cropPageStyle_pestInfo__2QIgj{
    display: grid;
    grid-template-columns: 3fr 2fr;
}

.cropPageStyle_pestName__3qeRB{
    font-size: 25px;
}

.cropPageStyle_pestInfo__2QIgj img{
    height: 180px;
    border-radius: 20px;
}

.cropPageStyle_pestInfoImage__1FYGs{
    text-align: center;
    margin-top: 3%;
}

/* comment component */

.cropPageStyle_messagesAllContainer__3yDww{
    margin-left: 4%;
    margin-right: 4%;
}

.cropPageStyle_comments_section__32_Cz{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 98%;
	margin: 0 auto;
}
.cropPageStyle_comments__3qZac{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	background: rgba(255, 238, 184, 0.9);
	width: 100%;
	padding: 1%;
	margin-bottom: 1%;
	border-radius: 15px;
}
.cropPageStyle_comment_profile__-EaYD{
	width: 20%;
	height: auto;
	text-align: center;
}
.cropPageStyle_comment_profile__-EaYD img{
	border-radius: 50%;
}
.cropPageStyle_comment_body__34l5V{
	width: 80%;
	height: auto;
}
.cropPageStyle_leave_comment__1Hx-F{
	width: 100%;
	height: auto;
}
.cropPageStyle_leave_comment__1Hx-F form textarea{
	width: 100%;
	height: 5em;
	border-radius: 10px;
	font-size: 1.5em;
	padding: 1%;
	border: none;
}



@media screen and (max-width: 1200px){
    .cropPageStyle_main_wrapper_item__2drz5{
		margin: 90px 10% 2% 10%;
	}

	.cropPageStyle_tab_btn__2Hjq5{
		padding: 0.6rem 1.3rem;
		font-size: 1.2rem;
		letter-spacing: 0.150rem
	}
}


@media screen and (max-width: 990px){	
    .cropPageStyle_main_wrapper_item__2drz5{
		margin: 90px 4% 2% 4%;
		width: 92%;
	}
	.cropPageStyle_tab_btn2__2aZkm{
		padding: 0.6rem 1.7rem;
		font-size: 1.5rem;
		letter-spacing: 0.1rem;
	}
}

@media screen and (max-width: 890px){
    .cropPageStyle_main_wrapper_item__2drz5{
		margin: 90px 4% 2% 4%;
	}
	.cropPageStyle_item_image__3Qb4n{
		width: 60%;
		max-height: 300px;
	}
	.cropPageStyle_item_details__2oeso{
		width: 70%;
		height: auto;
		padding: 1rem 1rem;
	}


	.cropPageStyle_item_details__2oeso p{
		font-family: 'Quicksand', sans-serif;
		font-weight: 700;
		font-size: 20px;
		line-height: 20px;
	}
	.cropPageStyle_tab_btn__2Hjq5{
		padding: 0.5rem 1rem;
		font-size: 1.1rem;
		letter-spacing: 0.1;
	}
	.cropPageStyle_tab_btn2__2aZkm{
		padding: 0.5rem 1.3rem;
		font-size: 1.1rem;
		letter-spacing: 0.1rem;
	}
}

@media screen and (max-width: 700px){
    .cropPageStyle_main_wrapper_item__2drz5{
		margin: 90px 4% 2% 4%;
	}
	.cropPageStyle_top_item__2tm-S{
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.cropPageStyle_item_image__3Qb4n{
		width: 60%;
		max-height: 350px;
		margin: 1rem 0;
	}

	.cropPageStyle_item_details__2oeso{
		width: 100%;
		padding: 1rem 1rem;
	}

	.cropPageStyle_item_details__2oeso p{
		font-family: 'Quicksand', sans-serif;
		font-weight: 700;
		font-size: 18px;
		line-height: 20px;
	}

	.cropPageStyle_middle_tabs__3e6AP{
		justify-content: flex-start;
		align-items: flex-start;
	}
	.cropPageStyle_tab_btn__2Hjq5{
		padding: 0.8rem 1.5rem;
		font-size: 1.2rem;
		letter-spacing: 0.2rem;
		margin: 1rem 1rem;
	}
	.cropPageStyle_details__3awYQ h1{
		font-size: 2rem;
	}
	.cropPageStyle_details__3awYQ p{
		line-height: 1.4rem;
		font-size: 1.3rem;
		line-height: 25px;
	}
	.cropPageStyle_details_images__1MUHR img{
		width: 40%;
	}
	.cropPageStyle_tab_btn2__2aZkm{
		padding: 0.8rem 1.5rem;
		font-size: 1.2rem;
		letter-spacing: 0.1rem;
	}
}

@media screen and (max-width: 600px){
    .cropPageStyle_main_wrapper_item__2drz5{
		margin: 90px 3% 2% 3%;
	}
	.cropPageStyle_item_image__3Qb4n{
		width: 90%;
	}

	.cropPageStyle_middle_tabs__3e6AP{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 2rem 0;
	}
	.cropPageStyle_details_all__3TiJ4{
		top: 23rem;
	}

    .cropPageStyle_pestInfo__2QIgj{
        display: grid;
        grid-template-columns: 1fr;
    }

	.cropPageStyle_details_images__1MUHR{
		flex-direction: column;
		justify-content: center;
	}
	.cropPageStyle_details_images__1MUHR img{
		width: 70%;
	}
	.cropPageStyle_bottom_tabs__2Gx9o{
		flex-direction: column;
	}
	.cropPageStyle_tab_btn2__2aZkm{
		padding: 0.8rem 3rem;
		font-size: 1.2rem;
		margin: 20px 0;
	}
}
.irrigation_details_container__1d426{
	width: 100%;
	height: auto;
}

.irrigation_details__3BBf1{
	display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	padding: 1%;
}

.irrigation_details__3BBf1 h1{
	padding: 0.8rem 0.5rem;
}
.irrigation_details__3BBf1 p{
	padding: 0 0.5rem 0.5rem 0.5rem;
	line-height: 1.4rem;
	font-size: 1rem;
}

.irrigation_week_no__111-Z p{
	line-height: 0px;
}

.irrigation_irrigation_form__Ns8C9{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
}


.irrigation_equal_field__3YUet{
	width: 33%;
	min-width: 300px;
	padding: 1%;
}

.irrigation_equal_field__3YUet label{
	width: 100%;
	display: block;
	padding-bottom: 2px;
	font-size: 18px;
}

.irrigation_equal_field__3YUet select{
	width:100%;
	padding: 10px;
	font-size: 16px;
	box-shadow: 0 2px 5px 2px grey;
	border: none;
	border-radius: 10px;
} 

.irrigation_equal_field_input__2Dvw3{
	width:100%;
	padding: 7px;
	font-size: 16px;
	box-shadow: 0 2px 5px 2px grey;
	border: none;
	border-radius: 10px;
} 

.irrigation_scroll_area__1x9Tp{
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 300px;
	overflow-y: scroll;
	border-radius: 10px;
}
.irrigation_scroll_area__1x9Tp::-webkit-scrollbar{
	width: 15px;
}
.irrigation_scroll_area__1x9Tp::-webkit-scrollbar-track{
	background: white;
    border-radius: 35px;
}
.irrigation_scroll_area__1x9Tp::-webkit-scrollbar-thumb{
	background: rgba(62, 110, 30, 1);
    border-radius: 35px;
}
.irrigation_scroll_area__1x9Tp::-webkit-scrollbar-thumb:over{
	background: #555;
}

.irrigation_stage_no__3HQB6{
	width: 100%;
	padding: 0 10px;
	/* border-radius: 20px 20px 0 0; */
    display: grid;
    grid-column: 1fr 1fr;
}
.irrigation_stage_no__3HQB6 > h3{
	padding: 10px 0 10px 0 ;
}

.irrigation_week_no__111-Z{
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 5px 0;
}

.irrigation_stage_no__3HQB6:nth-child(odd){
	background: rgba(255, 238, 184, 0.9);
}
.irrigation_stage_no__3HQB6:nth-child(even){
	background: rgba(252, 252, 212, 1);
}
.irrigation_week_no__111-Z div{
	width: 50%;
}

.irrigation_irrigation_description__23XoN{
    display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	border-radius: 20px;
	margin: 0;
	padding: 0;
	background: rgba(252, 252, 212, 1);
}

@media screen and (max-width: 1200px){
    .irrigation_main_wrapper_item__xnvW6{
		margin: 80px 6% 2% 6%;
		width: 88%;
	}
	.irrigation_item_details__28i6s h1{
		font-size: 2rem;
		letter-spacing: 0.1rem;
		padding: 0 0 0.5rem 0;
	}
	.irrigation_item_details__28i6s h2{
		font-size: 1rem;
		letter-spacing: 0.030rem;
		padding: 0.5rem 0;
	}
	h3{
		font-size: 2rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.irrigation_item_details__28i6s h2 span{
		font-size: 1.2rem;
		letter-spacing: 0.040rem;
		padding: 0.5rem 0.7rem;
	}
	.irrigation_tab_btn__2or1C{
		padding: 0.6rem 1.3rem;
		font-size: 1.2rem;
		letter-spacing: 0.150rem
	}

}

@media screen and (max-width: 990px){	
    .irrigation_main_wrapper_item__xnvW6{
		margin: 80px 4% 2% 4%;
		width: 92%;
	}
	.irrigation_tab_btn2__1JrE4{
		padding: 0.6rem 1.7rem;
		font-size: 1.5rem;
		letter-spacing: 0.1rem;
	}
}


@media screen and (max-width: 890px){
    .irrigation_main_wrapper_item__xnvW6{
		margin: 80px 1% 2% 1%;
		width: 98%;
	}
	.irrigation_item_image__15VBe{
		width: 25%;
		max-height: 300px;
	}
	.irrigation_item_details__28i6s{
		width: 70%;
		height: auto;
		padding: 1rem 1rem;
	}
	.irrigation_item_details__28i6s h1{
		font-size: 2rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.irrigation_item_details__28i6s h2{
		font-size: 1.3rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	h3{
		font-size: 2rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.irrigation_item_details__28i6s h2 span{
		font-size: 1.2rem;
		letter-spacing: 0;
		padding: 0.3rem 0.7rem;
	}
	.irrigation_tab_btn__2or1C{
		padding: 0.5rem 1rem;
		font-size: 1.1rem;
		letter-spacing: 0.1;
	}
	.irrigation_tab_btn2__1JrE4{
		padding: 0.5rem 1.3rem;
		font-size: 1.3rem;
		letter-spacing: 0.1rem;
	}
}


@media screen and (max-width: 700px){
    .irrigation_main_wrapper_item__xnvW6{
		margin: 80px 1% 2% 1%;
		width: 98%;
	}
	.irrigation_top_item__1CfJL{
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.irrigation_tab_btn2__1JrE4{
		padding: 0.6rem 1.7rem;
		font-size: 1.5rem;
		letter-spacing: 0.1rem;
	}
	.irrigation_item_image__15VBe{
		width: 50%;
	}
	.irrigation_item_details__28i6s{
		width: 100%;
		height: auto;
		padding: 1.5% 5%;
	}
	.irrigation_item_details__28i6s h1{
		font-size: 40px;
	}
	.irrigation_item_details__28i6s h2{
		font-size: 22px;
	}

	.irrigation_item_details__28i6s h2 span{
		font-size: 20px;
	}

	h3{
		font-size: 1.5rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.irrigation_middle_tabs__1oe27{
		flex-wrap: wrap;
	}
	.irrigation_tab_btn__2or1C{
		width: 60%;
		margin: 2% auto;
	}
	.irrigation_equal_field__3YUet{
		width: 100%;
		padding: 1%;
	}
	.irrigation_stage_no__3HQB6{
		display: flex;
		flex-direction: column;
	}
	.irrigation_stage_no__3HQB6 h3{
		font-size: 1.3rem;
	}
	.irrigation_stage_no__3HQB6 p{
		font-size: 1.3rem;
		line-height: 2px;
	}
	.irrigation_week_no__111-Z{
		display: flex;
		height: auto;
		flex-direction: column;
	}
	.irrigation_week_no__111-Z div{
		width: 100%;
	}

}

@media screen and (max-width: 600px){
    .irrigation_main_wrapper_item__xnvW6{
		margin: 80px 3% 2% 3%;
	}
	.irrigation_item_image__15VBe{
		width: 60%;
	}

	.irrigation_item_details__28i6s h2{
		font-size: 1.2rem;
		letter-spacing: 0;
		padding: 0.3rem 0;
	}
	.irrigation_item_details__28i6s h2 span{
		font-size: 1.2rem;
		letter-spacing: 0;
		padding: 0.3rem 1rem;
	}
	.irrigation_middle_tabs__1oe27{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 2rem 0;
	}
	.irrigation_details_all__2Evwc{
		top: 23rem;
	}
	.irrigation_details_images__3jgVk{
		flex-direction: column;
		justify-content: center;
	}
	.irrigation_details_images__3jgVk img{
		width: 70%;
	}
	.irrigation_bottom_tabs__2qlXG{
		flex-direction: column;
	}
	.irrigation_tab_btn2__1JrE4{

		padding: 0.8rem 3rem;
		font-size: 1.6rem;
		margin: 20px 0;
	}

}


.irrigation_submitbtn__3jQ4Y{
    background: #EEA21B;
    border: 2px solid #EEA21B;
    box-sizing: border-box;
    border-radius: 50px;
    color: white;
    padding: 2% 6%;
    /* margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%; */
}

.card_stage_no__1-Epl{
	width: 100%;
	padding: 20px 20px;
}

.card_stage_no__1-Epl:nth-child(odd){
	background: rgba(255, 238, 184, 0.9);
}
.card_stage_no__1-Epl:nth-child(even){
	background: rgba(252, 252, 212, 1);
}

.card_stage_no__1-Epl > h3{
	padding: 10px 10px ;
    font-weight: 700;
}

.card_contentGrid__1zCS5{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.card_WeekcontentInCard__2cZXK{
    margin: 1%;
}

.card_WeekcontentInCard__2cZXK p{
    line-height: 1ch;
}

.card_monthContentCards___NOBH p{
    line-height: 1.5ch;
}

.card_weektext__1FOPH{
    font-weight: 700;
    font-size: 20px;
    padding-left: 5px;
}

.card_monthlyCard__VHUv4{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 1200px){  
    .card_monthlyCard__VHUv4{
        display: grid;
        grid-template-columns: 1fr 1fr ;
    }
}

@media screen and (max-width: 800px){
    .card_contentGrid__1zCS5{
        display: grid;
        grid-template-columns: 1fr;
    }
    .card_monthlyCard__VHUv4{
        display: grid;
        grid-template-columns: 1fr ;
    }
}

.fertilize_FertilizeContainer__3H2vW{
    margin-left: 6%;
    margin-right: 6%;
}

.fertilize_title__2rLjd{
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
}

.fertilize_details_container__1-N9t{
	width: 100%;
	height: auto;
}

.fertilize_details__1T1i0{
	display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	padding: 1%;
}

.fertilize_scroll_area__3od4k{
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 300px;
	overflow-y: scroll;
}

.fertilize_scroll_area__3od4k::-webkit-scrollbar{
	width: 15px;
}
.fertilize_scroll_area__3od4k::-webkit-scrollbar-track{
	background: #f1f1f1;
}
.fertilize_scroll_area__3od4k::-webkit-scrollbar-thumb{
	background: rgba(62, 110, 30, 1);
}
.fertilize_scroll_area__3od4k::-webkit-scrollbar-thumb:over{
	background: #555;
}
.comment_details__2_NP7{
    display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 450px;
	margin: 1%;
	overflow-y: scroll;
}

.comment_details__2_NP7.comment_is_active__1YilG{
	display: block;
}
.comment_details__2_NP7::-webkit-scrollbar{
	width: 15px;
}
.comment_details__2_NP7::-webkit-scrollbar-track{
	background: #f1f1f1;
}
.comment_details__2_NP7::-webkit-scrollbar-thumb{
	background: #3F5552;
}
.comment_details__2_NP7::-webkit-scrollbar-thumb:over{
	background: #555;
}

.comment_messagesAllContainer__kxFga{
    margin-left: 4%;
    margin-right: 4%;
}
.comment_messagetitle__xiHfA{
    margin-left: 2%;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
}

.comment_commentContainer__dkBQR{
    display: grid;
    grid-template-columns: 1fr 7fr;
    grid-gap: 2em;
    background-color: #FFF7CD;
    border-radius: 20px;
    margin: 1%;
}

.comment_userInfo__3APUR{
    display: grid;
    grid-template-rows: 3fr 1fr;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}

.comment_userImgContainer__Jmuht{
    display: inline-block; 
    position: relative; 
    width: 80px; 
    height: 80px; 
    overflow: hidden; 
    border-radius: 50%;
    margin:5%;
}

.comment_userImgContainer__Jmuht img{
    width: auto; 
    height: 100%; 
    /* margin-left: -10px; */
}

.comment_userImgContainer__Jmuht span{
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
}

.comment_messageContainer__3d6Q7{
    margin-top: 1%;
    margin-left: 3%;
}

.comment_messageContainer__3d6Q7 p{
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
}
.cropPage_main_wrapper_item__X_MCI{
    margin: 90px 10% 10% 10%;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    background-color: #DFE7B4;
    border-radius: 30px;
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.cropPage_top_item__3-RNV{
	width: 98%;
	height: auto;
	margin: 1%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.cropPage_item_image__xiVqE{
	width: 60%;
	max-height: 350px;
	text-align: center;
    padding: 3%;
}
.cropPage_item_image__xiVqE img{
	width: 100%;
	max-height: 350px;
	border-radius: 30px;
}

.cropPage_item_details__3hIzc{
	width: 65%;
	height: auto;
	padding: 1rem 2rem;
}

.cropPage_item_details__3hIzc h3{
	font-family: 'Arapey', serif;
    font-size: 40px;
    color: #FB9F00;
    line-height: 70px;
}
.cropPage_item_details__3hIzc p{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.cropPage_item_details__3hIzc span{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    padding: 0.3rem 0.3rem;
}

.cropPage_middle_tabs__3s4BY{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 4% ;
}

.cropPage_tab_btn__2tJqs{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    padding: 0.1rem 2rem;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	background-color: #3F5552;
	color: white;
	border: none;
    border-radius: 90px;
	cursor: pointer;
    margin: 3%;
}

.cropPage_tab_btn__2tJqs:hover{
	background-color: rgba(63, 85, 82, 0.7);
}

.cropPage_tabs_toggle__3-Mxu{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    padding: 0.1rem 2rem;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
	background-color: white;
	color:  #3F5552;
	border: none;
    border-radius: 90px;
	cursor: pointer;
    margin: 3%;
    border: 3px solid #3F5552 ;
}

/* under buttom */
.cropPage_bottom_tabs__SjtFz{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 70%;
	height: auto;
	margin: 1%;
}

.cropPage_tab_btn2__1oUrg{
	padding: 0.8rem 2rem;
	font-size: 1rem;
	letter-spacing: 0.2rem;
	background-color: rgba(250, 169, 5, 1);
	color: white;
	border: none;
	border-radius: 50px;
	cursor: pointer;
}

.cropPage_tab_btn2__1oUrg:hover{
	background-color: white;
    color: rgba(250, 169, 5, 1);
    border: 3px solid rgba(250, 169, 5, 1) ;
}


.cropPage_active_btn2__1PGSG{
	padding: 0.8rem 2rem;
	font-size: 1rem;
	letter-spacing: 0.2rem;
	background-color: white;
	color: rgba(250, 169, 5, 1);
	border: none;
	border-radius: 50px;
    border: 3px solid rgba(250, 169, 5, 1) ;
}

.cropPage_active_btn2__1PGSG:hover{
	background-color: rgba(250, 169, 5, 1) ;
	color: white;
    text-decoration: none;
}

.cropPage_scroll_area__1E5Nc{
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 300px;
	overflow-y: scroll;
}

/* .sectionOneContainer{
    display: grid;
    grid-template-columns: 4fr 5fr;
}
.cropPageImageContainer{
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.cropPageImage{
    width: 350px;
    border-radius: 30px;
    
}

.titleContent h3{
    font-family: 'Arapey', serif;
    font-size: 40px;
    color: #FB9F00;
    line-height: 70px;
}

.titleContent p{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.titleContent span{
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
}

.cropButton{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    align-items: center;
    text-align: center;
    background-color: #3F5552;
    color: white;
    border-radius: 90px;
    margin: 5% 10%;
    
}

.cropButton:active{
    font-family: 'Quicksand', sans-serif;
    background-color: white;
    color: #3F5552;
    border: 2px solid #4A5E58;
}

.cropInfoButton{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 4% ;
    
}

.decisionContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.NotMyVegbtn{
    background: #FFFFFF;
    opacity: 0.9;
    border: 2px solid #EEA21B;
    box-sizing: border-box;
    border-radius: 90px;
    color: #EEA21B;
    padding: 4%;
    margin: 5%;
}

.AddToGarden{
    background: #EEA21B;
    opacity: 0.9;
    border: 2px solid #EEA21B;
    box-sizing: border-box;
    border-radius: 90px;
    color: white;
    padding: 4%;
    margin: 5%;
} */


@media screen and (max-width: 990px){	
    .cropPage_main_wrapper_item__X_MCI{
        margin: 80px 6% 2% 6%;
    }

}
/********************* crop page **********************/


/* ################### Start cropBase ############ */
@media screen and (max-width: 990px){	
.cropPage_CropBaseContent__1JRxM{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
}
.cropCreate_growingAndKc__KFgEe{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.cropCreate_databox__1Yb7z{
    padding-left: 10%;
    padding-right: 10%;
}

.cropCreate_calculateContainer__2vK5w{
    text-align: center;
}
.cropCreate_calculateContainer__2vK5w button{
    width: 50%;
}

.cropCreate_tempContaner__29OF4{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
#register_city__38Whl{
	background-color: #fff; 
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-position: 98% center;
	background-size: 15px;
	-webkit-appearance: none;
	appearance: none;
}


.register_signup_wrapper__3LG3-{
	margin: 1% 8%;
	width: 84%;
	margin-top: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1% 0;
	background-repeat: no-repeat;
	background-size: 100%;
	background-attachment: scroll;
	background-position: top center;
	background-origin: border-box;
}
.register_signup_heading__3gRD7 h1{
	font-weight: 500;
	font-size: 45px;
	letter-spacing: 3px;
}
.register_main_signup_form__1ULDh{
	margin: 0 0 0 0;
	width: 70%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.register_left_signup_form__1nop7{
	width: 50%;
	height: 700px;
	border-radius: 30px 0 0 30px;

}
.register_left_signup_form__1nop7 img{
	width: 100%;
	height: 700px;
	border-radius: 30px 0 0 30px;
}
.register_right_signup_form__1hM_-{
	width: 50%;
	height: 700px;
	border: 1px solid black;
	border-radius: 0 30px 30px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background: rgba(63, 85, 82, 1);
}
.register_signup_logo__2a60F img{
	width: 100%;
	border-radius: 0 30px 0 0;
}
.register_signup_form__1yKD6{
	width: 90%;
	color: white;
}
.register_signup_form__1yKD6 form{
	width: 100%;
	text-align: center;
}
.register_signup_form__1yKD6 form label{
	display: block;
	text-align: left;
    font-family: 'Quicksand', sans-serif;
    line-height: 10px;
    font-size: 20px;
}

.register_signup_form__1yKD6 form select{
	width: 100%;
	padding: 3px;
}
.register_half_field__1StOV{
	width: 50%;
	float: left;
	padding: 7px 10px;
}
.register_signup_form__1yKD6 form p{
	font-size: 18px;
}

@media screen and (max-width: 1200px){

.register_signup_wrapper__3LG3-{
    margin: 1% 7%;
    width: 86%;
    margin-top: 80px;
    background-size: 120%;
}
.register_signup_heading__3gRD7 h1{
    letter-spacing: 1px;
}
.register_signup_form__1yKD6{
    width: 95%;
}
.register_main_signup_form__1ULDh{
    width: 100%;
}

}

@media screen and (max-width: 990px){	
    .register_signup_wrapper__3LG3-{
		margin: 1% 6%;
		width: 88%;
		margin-top: 80px;
	}
	.register_signup_heading__3gRD7 h1{
		font-size: 40px;
		letter-spacing: 1px;
	}
	.register_main_signup_form__1ULDh{
		width: 100%;
		height: 680px;
	}
	.register_left_signup_form__1nop7{
		height: 680px;
	}
	.register_left_signup_form__1nop7 img{
		height: 680px;
	}
	.register_right_signup_form__1hM_-{
		height: 680px;
	}
}

@media screen and (max-width: 890px){
    .register_signup_wrapper__3LG3-{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
	}
	.register_signup_heading__3gRD7 h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.register_main_signup_form__1ULDh{
		margin: 0;
		height: 820px;
	}
	.register_signup_form__1yKD6{
		width: 95%;
	}
	.register_left_signup_form__1nop7{
		height: 820px;
	}
	.register_left_signup_form__1nop7 img{
		height: 820px;
	}
	.register_right_signup_form__1hM_-{
		height: 820px;
	}
	.register_half_field__1StOV{
		width: 100%;
	}
}

@media screen and (max-width: 600px){
    .register_signup_wrapper__3LG3-{
		background-size: 100% 100%;
		padding: 0;
		background: none;
	}
	.register_signup_heading__3gRD7 h1{
		font-size: 36px;
		letter-spacing: 0px;
	}
	.register_main_signup_form__1ULDh{
		margin: 3% 0;
		height: 850px;
		display: flex;
		flex-direction: column;
		/* background-image: url(".././images/registerImg1.jpeg"); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-attachment: scroll;
		background-position: top center;
		background-origin: border-box;
		border-radius: 30px;
	}
	.register_left_signup_form__1nop7{
		display: none;
	}
	.register_left_signup_form__1nop7 img{
		height: none;
	}
	.register_right_signup_form__1hM_-{
		width: 80%;
		height: 830px;
		border-radius: 30px;
	}
	.register_signup_logo__2a60F img{
		width: 100%;
		border-radius:30px;
	}
}

@media screen and (max-width: 480px){

    .register_signup_wrapper__3LG3-{
        background-size: 100% 100%;
        padding: 0;
        background: none;
    }
    .register_signup_heading__3gRD7 h1{
        font-size: 36px;
        letter-spacing: 0px;
    }
    .register_main_signup_form__1ULDh{
        margin: 3% 0;
        height: 840px;
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-attachment: scroll;
        background-position: top center;
        background-origin: border-box;
        border-radius: 30px;
    }
    .register_left_signup_form__1nop7{
        display: none;
    }
    .register_left_signup_form__1nop7 img{
        height: none;
    }
    .register_right_signup_form__1hM_-{
        width: 80%;
        height: 820px;
        border-radius: 30px;
    }
    .register_signup_logo__2a60F img{
        width: 100%;
        border-radius:30px;
    }
}
.register_container__16oCL{
    width:100%;
    height:100%; 
    padding-bottom: 80px;
}
.register_formBackgronContainer__2nX64{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    padding-top: 5%;
    padding-bottom: 5%;
}

.register_backendimage__120Wo{
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    text-align: center;
    width: 100%;
}

.register_registerBox__3LhVL{ 
    grid-row: 1;
    grid-column: 1;
    z-index: 7;
}

.register_registerBox__3LhVL h1{
    font-family: 'Arapey', serif;
    text-align: center;
}

.register_formContainer__gCQOu{
    /* border-style: solid; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #3F5552;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 20px;
}

.register_loginleftImg__iEU74 img{
    max-width: 100%;
    max-height: 100%;
    border-top-left-radius:18px;
    border-bottom-left-radius:18px;
}

.register_formbox__2uIos{
    margin-top: 2%;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    color: white;
}

.register_leftrightbox__VtJGI{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
}

.register_inputBox__1BoBB{
    width: 100%;
    height: 32px;
    margin: 0 auto;
    /* border: none; <-- This thing here */
    border:solid 1px #ccc;
    border-radius: 6px;
    font-size: 20px;
}

.register_formbox__2uIos label{
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    line-height: 15px;
}

.register_sighupwith__26HfM{
    border:none;
    background: none;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 4%;
}


.register_loginbtn__1VNk1{
    background-color:#FB9F00;
    width: 60%;
    border-radius: 90px;
    border: none; 
    color: white;
    padding: 8px 30px;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
}

.register_sighupbtn__3eGmM{
    background-color:whitesmoke;
    border-radius: 90px;
    font-size: 20px;
    border: none;
    border:solid 1px #FB9F00;
    color:  #FB9F00;
    padding: 8px 30px;
    width: 60%;

}

.login_container__21tzN{
    width:100%;
    height:100%; 
    background-color: #3F5552;
    padding-top: 80px;
    padding-bottom: 80px;
}

.login_login_wrapper__3AjpC{
	margin: 1% 8%;
	width: 84%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1% 0;
	background-repeat: no-repeat;
	background-size: 100%;
	background-attachment: scroll;
	background-position: top center;
	background-origin: border-box;
}

.login_login_heading__3bdBs h1{
	font-weight: 500;
	font-size: 45px;
	letter-spacing: 3px;
    color: white;
}

.login_main_form__3gHzf{
	margin: 0 0 0 0;
	width: 70%;
	height: 700px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.login_left_form__1r5Ht{
	width: 50%;
	height: 650px;
	border-radius: 30px 0 0 30px;
}
.login_left_form__1r5Ht img{
	width: 100%;
	height: 650px;
	border-radius: 30px 0 0 30px;
}


.login_right_form__1p5GK{
	width: 50%;
	height: 650px;
	border-radius: 0 30px 30px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
    background-color: white;
}

.login_login_logo__1HeXp img{
	width: 100%;
	border-radius: 0 30px 0 0;
    margin-top: 3%;
}

.login_login_form__16tqv form{
    margin-top: 3%;
	width: 100%;
	text-align: center;
}

.login_login_form__16tqv form label{
	display: block;
	text-align: left;
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    line-height: 30px;
    
}

.login_login_form__16tqv form input{
	width: 100%;
}

.login_login_btn__2vxil{
    background-color:#FB9F00;
    border-radius: 90px;
    border: none; 
    color: white;
    padding: 8px 30px;
    font-size: 20px;
    text-align: center;
    margin-top: 3%;
}

.login_otheroption__2QUm0{
    text-align: center;
    margin-top: 10%;
}

.login_otheroption__2QUm0 p{
    margin-top: 5%;
    font-size: 1em;
}

.login_signup_btn__3uAyD{
	font-size: 20px;
	padding: 8px 30px;
	border-radius: 30px;
	border: 2px solid #FB9F00;
	cursor: pointer;
	color: #FB9F00;
    width: 70%;
    margin-top: 7%;
}

a.login_forget_password__36t-7{
	display: block;
	text-align: right;
	text-decoration: none;
	margin-top: 5px;
}

@media screen and (max-width: 1200px){
    .login_login_wrapper__3AjpC{
		margin: 1% 7%;
		width: 86%;
		margin-top: 80px;
		background-size: 120% 100%;
	}
	.login_login_form__16tqv{
		width: 80%;
	}
	.login_login_heading__3bdBs h1{
		letter-spacing: 2px;
	}
	.login_main_form__3gHzf{
		width: 100%;
	}
}

@media screen and (max-width: 990px){	
    .login_login_wrapper__3AjpC{
		margin: 1% 6%;
		width: 88%;
		margin-top: 80px;
	}
	.login_login_heading__3bdBs h1{
		font-size: 40px;
		letter-spacing: 1px;
	}
	.login_main_form__3gHzf{
		width: 100%;
		height: 650px;
	}
	.login_login_form__16tqv{
		width: 85%;
	}
	.login_left_form__1r5Ht{
		height: 620px;
	}
	.login_left_form__1r5Ht img{
		height: 620px;
	}
	.login_right_form__1p5GK{
		height: 620px;
	}
}

@media screen and (max-width: 890px){
    .login_login_wrapper__3AjpC{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		background-size: 100% 100%;
		padding: 0;
	}
	.login_login_heading__3bdBs h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.login_main_form__3gHzf{
		margin: 0;
		height: 650px;
	}
	.login_login_form__16tqv{
		width: 90%;
	}
	.login_left_form__1r5Ht{
		height: 650px;
	}
	.login_left_form__1r5Ht img{
		height: 650px;
	}
	.login_right_form__1p5GK{
		height: 650px;
	}
}


@media screen and (max-width: 700px){
    .login_login_wrapper__3AjpC{
		background-size: 100% 100%;
		padding: 0;
	}
	.login_login_heading__3bdBs h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.login_main_form__3gHzf{
		margin: 0;
		height: 630px;
    }
	.login_left_form__1r5Ht{ 
        height: 580px; 
    }
	.login_left_form__1r5Ht img{ 
        height: 580px; 
    }
	.login_right_form__1p5GK{ 
        height: 580px; 
    }

}

@media screen and (max-width: 600px){
    .login_login_wrapper__3AjpC{
		background-size: 100% 100%;
		padding: 0;
		background: none;
	}
	.login_login_heading__3bdBs h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.login_main_form__3gHzf{
		margin-top: 2%;
		height: 650px;
		display: flex;
		flex-direction: column;
		/* background-image: url(".././images/registerImg1.jpeg"); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-attachment: scroll;
		background-position: top center;
		background-origin: border-box;
		border-radius: 30px;
	}
	.login_left_form__1r5Ht{
		display: none;
	}
	.login_left_form__1r5Ht img{
		height: none;
	}
	.login_right_form__1p5GK{
		width: 80%;
		height: 630px;
		border-radius: 30px;
	}
	.login_login_logo__1HeXp img{
		width: 100%;
		border-radius:30px;
	}
}


@media screen and (max-width: 480px){
    .login_login_wrapper__3AjpC{
		background-size: 100% 100%;
		padding: 0;
		background: none;
	}
	.login_login_heading__3bdBs h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.login_main_form__3gHzf{
		margin-top: 2%;
		height: 640px;
		display: flex;
		flex-direction: column;
		/* background-image: url(".././images/registerImg1.jpeg"); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-attachment: scroll;
		background-position: top center;
		background-origin: border-box;
		border-radius: 30px;
	}
	.login_left_form__1r5Ht{
		display: none;
	}
	.login_left_form__1r5Ht img{
		height: none;
	}
	.login_right_form__1p5GK{
		width: 80%;
		height: 620px;
		border-radius: 30px;
	}
	.login_login_logo__1HeXp img{
		width: 100%;
		border-radius:30px;
	}
}



.login_formBackgronContainer__iexwf{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    padding-top: 5%;
    padding-bottom: 5%;
}

.login_backendimage__1qu_M{
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    text-align: center;
    width: 100%;
}

.login_loginBox__E_d2J{ 
    grid-row: 1;
    grid-column: 1;
    z-index: 7;
}

.login_loginBox__E_d2J h1{
    font-family: 'Arapey', serif;
    color: white;
    text-align: center;
}

.login_formContainer__11MIX{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 20px;
}

.login_loginleftImg__1cMg7 img{
    max-width: 110%;
    border-top-left-radius:18px;
    border-bottom-left-radius:18px;
}

.login_formbox__17gXd{
    margin-top: 10%;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
}

.login_inputBox__18xiW{
    width: 100%;
    height: 35px;
    margin: 0 auto;
    /* border: none; <-- This thing here */
    border:solid 1px #ccc;
    box-shadow: 1px 2px #c8c8c8;
    border-radius: 6px;
    font-size: 20px;
}

.login_formbox__17gXd label{
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    line-height: 15px;
}

.login_sighupwith__1ySrj{
    border:none;
    background: none;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 4%;
	display: inline-block;
}


.login_loginbtn__2o15Z{
    background-color:#FB9F00;
    width: 60%;
    border-radius: 90px;
    border: none; 
    color: white;
    padding: 3%;
    font-size: 20px;
    text-align: center;
}

.login_sighupbtn__1jCV1{
    background-color:whitesmoke;
    border-radius: 90px;
    font-size: 20px;
    border: none;
    border:solid 1px #FB9F00;
    color:  #FB9F00;
    padding: 3%;
    width: 60%;

}

#userProfile_city__1VJS-{
	background-color: #fff; 
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-position: 98% center;
	background-size: 15px;
	-webkit-appearance: none;
	appearance: none;
}

.userProfile_main_profile1__1uRfw{
	margin: 1% 8%;
	width: 84%;
	margin-top: 80px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.userProfile_profile_form__2yysJ{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 70%;
	height: auto;
	border-radius: 20px;
	margin-bottom: 2%;
	padding: 2% 5% 0% 2%;
	background:	#FFF7CD;
}
.userProfile_each_form__NvENB{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 3%;
}
.userProfile_each_form__NvENB h1{
	font-weight: 500;
	font-size: 50px;
	letter-spacing: 3px;
}
.userProfile_each_form__NvENB img{
	border-radius: 50%;
	width: 200px;
	height: 200px;
}
.userProfile_each_form__NvENB div{
	width: 50%;
	height: auto;
}
.userProfile_each_form__NvENB label{
	font-size: 20px;
	font-weight: 700;
}
div.userProfile_each_form__NvENB input, select{
	padding: 8px;
	width: 90%;
	border-radius: 5px;
	border: none;
	box-shadow: 0px 2px 3px 0px black;
	margin-top: 1%;
}
.userProfile_btn2__2bEHe{
	margin-top: 10px;
	padding: 10px 10px;
}
#userProfile_each_form__NvENB h1{
	font-weight: 500;
	font-size: 40px;
}
a.userProfile_forgot_pass__XELQX{
	margin-top: 10px;
}

.userProfile_linkbtn__3jPzs:hover{
	background-color: rgba(250, 169, 5, 0.5);
}

.userProfile_linkbtn__3jPzs{
    font-weight: 400;
    border: none;
    background-color: #FB9F00;
    font-size: 16px;
    text-align: center;
    border-radius: 25px ;
    color: white;
    padding: 2% 5%;
    margin: 3%;
	float: right;
}

.userProfile_linkbtn__3jPzs:hover{
	background-color: rgba(250, 169, 5, 0.5);
	text-decoration: none;
	color: white;

}

.userProfile_editPicBtn__3XkX_{
	border:none;
	background-color:#FFF7CD;
	font-size: 15px;
	color: #FB9F00;
	float: "right"
}

.userProfile_half_field__CHQAv{
	width: 100%;
	float: left;
	padding: 7px 10px;
}

@media screen and (max-width: 1200px){
	.userProfile_main_profile1__1uRfw{
		margin: 1% 6%;
		width: 88%;
		margin-top: 80px;
	}
	.userProfile_profile_form__2yysJ{
		width: 100%;
		padding: 2% 3%;
	}
	h2#userProfile_reset_password__1UXpQ{
		font-size: 40px;
		font-weight: 500;
	}
}

@media screen and (max-width: 990px){	
	.userProfile_main_profile1__1uRfw{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
	}
	.userProfile_profile_form__2yysJ{
		width: 100%;
		padding: 2% 2%;
	}
	h2#userProfile_reset_password__1UXpQ{
		font-weight: 500;
		font-size: 40px;
	}
}

@media screen and (max-width: 890px){
	.userProfile_main_profile1__1uRfw{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		flex-direction: column;
		justify-content: center;
	}
	.userProfile_profile_form__2yysJ{
		width: 100%;
		padding: 2% 3%;
	}
	.userProfile_each_form__NvENB{
		flex-direction: column;
		width: 100%;
	}
	.userProfile_each_form_1__1mUxp{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	.userProfile_each_form_1__1mUxp h1{
		font-weight: 500;
		font-size: 40px;
		letter-spacing: 2px;
		margin-top: 3%;
	}
	#userProfile_img_profile__2CHVe{
		text-align: center;
	}
	.userProfile_each_form_1__1mUxp img{
		border-radius: 100%;
		width: 180px;
		height: 180px;
	}
	span#userProfile_camera__fWjNE{
		position: relative;
		top: 0;
		left: 0%;
		color: black;
	}
	.userProfile_each_form__NvENB div{
		width: 100%;
		margin-bottom: 2%;
	}
	div.userProfile_each_form__NvENB input, select{
		width: 100%;
	}

}

@media screen and (max-width: 480px){
	.userProfile_main_profile1__1uRfw{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	.userProfile_profile_form__2yysJ{
		width: 100%;
		padding: 2% 3%;
	}
	.userProfile_each_form__NvENB{
		flex-direction: column;
		width: 100%;
	}
	.userProfile_each_form_1__1mUxp{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}.userProfile_each_form_1__1mUxp h1{
		font-weight: 500;
		font-size: 50px;
		letter-spacing: 3px;
	}
	span#userProfile_camera__fWjNE{
		position: relative;
		top: 0;
		left: 0%;
		color: black;
	}
	#userProfile_img_profile__2CHVe{
		text-align: center;
	}
	.userProfile_each_form_1__1mUxp img{
		border-radius: 100%;
		width: 180px;
		height: 180px;
	}
	.userProfile_each_form__NvENB div{
		width: 100%;
		margin-bottom: 2%;
	}
	div.userProfile_each_form__NvENB input, select{
		width: 100%;
	}
}
.profileEdit_userInfoEditContainer__1cUJV{
    background-color: #FFF7CD;
    width: 70%;
    padding: 3% 5%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.profileEdit_userTitle__2x9HX{
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding-bottom: 3%;
}

.profileEdit_userTitle__2x9HX h1{
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.profileEdit_userTitle__2x9HX img{
    width: 200px;
    height: 200px;
    border-radius: 50%;

}

.profileEdit_gridtwo__2d4mb{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
}

.profileEdit_buttonContainer__3YIL2{
    text-align: center;
}

.profileEdit_submitbtn__3jclo{
    font-weight: 400;
    border: none;
    background-color: #FB9F00;
    font-size: 16px;
    text-align: center;
    border-radius: 25px ;
    color: white;
    padding: 2% 5%;
    margin: 3%;
   
}

.profileEdit_cancelbtn__F_OFZ{
    font-weight: 400;
    border: 2px solid #FB9F00;
    background-color: white;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    border-radius: 25px ;
    color: #FB9F00;
    padding: 2% 5%;
    margin: 3%;
}


/********************* Update password ***********************/

.profileEdit_UpdatePasswordContainer__3M-4n{
    margin-top: 80px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}


/************************** forgotpassword ******************************/

.forgotpassword_forgetpassword_Container__3LjX8{
	margin-top: 10%;
    text-align: center;
}

.forgotpassword_forgetpassword_Container__3LjX8 p{
    font-size: 20px;
}

.forgotpassword_forgetPasswordBox__1sTCD{
    padding: 3% 3% 5% 3%;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 2%;
    background-color: #3F5552;
    text-align: center;
    border-radius: 10px;
}

.forgotpassword_emailInputBox__H8iTy{
    margin-top: 4%;
    font-size: 20px;
    margin-bottom: 4%;
}

.forgotpassword_emailInputBox__H8iTy input{
    width: 60%;
    border-radius: 5px;
    padding-left: 5px;
    
}

.forgotpassword_emailInputBox__H8iTy label{
    color: white;
    padding-right: 10px;
}

.forgotpassword_sendEmailbtn__2N9aY{
    color: white;
    background-color: #FB9F00;
    padding: 1% 8%;
    border: none;
    border-radius: 25px;
    font-size: 20px;
}

.forgotpassword_sendEmailbtn__2N9aY:disabled{
    border: 2px solid #FB9F00;
    background-color: #f8d18e;
   
}

.forgotpassword_forgotpasswordback__3Xk06{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
    height: 100vh;
}

.forgotpassword_ResetPasswordInputBox__FO6rQ{
    margin-top: 3%;
    font-size: 20px;
    margin-bottom: 4%;
    margin-right: 5%;
    text-align: right;
}


.forgotpassword_ResetPasswordInputBox__FO6rQ input{
    width: 60%;
    border-radius: 5px;
    padding-left: 5px;
    margin-bottom: 4%;
    
}

.forgotpassword_ResetPasswordInputBox__FO6rQ label{
    color: white;
    padding-right: 10px;
}

@media screen and (max-width: 990px){
    .forgotpassword_logoimage__2ePTI{
        width: 400px;
    }
    .forgotpassword_ResetPasswordInputBox__FO6rQ{
        text-align: center;
        margin-right: 0%;
        
    }
    .forgotpassword_ResetPasswordInputBox__FO6rQ input{
        width: 70%;
    }
}



@media screen and (max-width: 780px){
    .forgotpassword_forgetpassword_Container__3LjX8{
        margin-top: 20%;
        margin-left: 4%;
        margin-right: 4%;
    }
    .forgotpassword_logoimage__2ePTI{
        width: 400px;
    }

    .forgotpassword_forgetPasswordBox__1sTCD{
        width: 90%;
        margin-top: 5%;
        padding-bottom: 7%;
    }

    
}

@media screen and (max-width: 450px){
    .forgotpassword_forgetpassword_Container__3LjX8{
        margin-top: 30%;
        margin-left: 2%;
        margin-right: 2%;
    }

    .forgotpassword_logoimage__2ePTI{
        width: 300px;
    }

    .forgotpassword_forgetPasswordBox__1sTCD{
        width: 90%;
        margin-top: 8%;
        padding-bottom: 7%;
    }
}
.question_forum_wrapper__20RoH{
	margin-top: 1% 6%;
    margin-left: auto ;
    margin-right: auto;
	width: 80%;
	margin-top: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	padding: 1% 0;
	/* background-image: url(".././images/undraw_sunny.png");  */
	background-size: 92% 100%;
	background-repeat: no-repeat;
	background-origin: border-box;
	background-position: top center;
	background-attachment: scroll;
}
.question_forum__3o9Xp{
	width: 84%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;	
}
.question_left_forum__3hykE{
	width: 32%;
	height: auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding: 0 2%;
}
.question_forum_profile_image__3xalP{
	width: 90%;
	height: 90%;
	padding: 1%;
	text-align: center;
}

.question_forum_profile_image__3xalP img{
	width: 200px;
	height: 200px;
	border-radius: 50%;
}
.question_forum_profile_status__2JFEt{
	margin-top: 10%;
	width: 100%;
}
.question_forum_profile_status__2JFEt span{
	display: block;
	margin: 3% 0;
	font-size: 18px;
	letter-spacing: 2px; 
}
.question_forum_profile_community__uaw7P{
	margin-top: 10%;
	width: 100%;
}
.question_forum_profile_community__uaw7P a, p{
	display: block;
	margin: 3% 0;
	letter-spacing: 2px;
	font-size: 18px;
}
.question_forum_profile_question__3PV5M{
	margin-top: 10%;
	width: 100%;
}

.question_forum_questions__3rMef{
    margin-top: 10%;
	width: 100%;
}
.question_forum_profile_question__3PV5M p{
	font-size: 18px;
	letter-spacing: 2px;
}
.question_forum_profile_question__3PV5M form{
	width: 100%;
}
.question_forum_profile_question__3PV5M form textarea{
	width: 100%;
	height: 400px;
	resize: none;
	border: 1px solid black;
	border-radius: 20px;
	padding: 5%;
	font-size: 18px;
}
.question_right_forum__1_rC4{
	width: 68%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}
.question_right_forum__1_rC4 h1{
	margin-top: 15%;
	font-size: 40px;
	letter-spacing: 2px;
}
.question_comment_box__-RWs6{
	width: 100%;
	height: auto;
	margin: 2% 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}
.question_question_image_btn__1ggEV{
	width: 100%;
	text-align: right;
	padding: 2%;
}
.question_question_send_btn__2v-Rq{
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 50px;
	border: 1px solid grey;
	margin-left: 10px;
	box-shadow: 0px 1px 3px grey; 
	color: #fff;
	background: orange;
	cursor: pointer;
	float: right;
}
.question_question_image_btn__1ggEV i{
	font-size: 30px;
	display: block;
	float: right;
}
.question_lets_talk__3bIzK{
	width: 100%;
	margin-bottom: 1%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}
.question_lets_talk__3bIzK a{
	padding: 10px 20px;
	font-size: 16px;
	border-radius: 50px;
	border: 1px solid grey;
	margin-left: 10px;
	box-shadow: 0px 1px 3px grey; 
	cursor: pointer;
}
.question_grey_btn__1m5jv{
	background-color: #4A5E58;
	color: #fff;
    text-decoration: none;
	width: 30px;
    /* background-color: #4A5E58; */
}

.question_grey_btn__1m5jv:hover{
	background-color: #4A5E58;
	text-decoration: none;
}

.question_comment_details__2xThi{
	width: 100%;
	display: flex;
    background: rgba(255, 235, 125, 0.5);
    border-radius: 20px 20px 0 0;
    padding: 2% 0;
}
.question_comment_profile__27CWE{
	width: 20%;
	height: auto;
    border-radius: 20px 0px 0 0;
	padding: 1% 0;
}
.question_comment_profile__27CWE img{
	width: 80%;
	height: 100px;
}
.question_comment_body__1UyPR{
	width: 80%;
	height: auto;
	padding: 1%;
    border-radius: 0px 20px 0 0;
    line-height: 25px;
}.question_comment_body__1UyPR span{
	position: relative;
	top: 0;
	left: 85%;
	font-size: 20px;
	padding-bottom: 2%;
}
.question_comment_area__tKhRi{
	width: 100%;
	height: 100px;	
	margin-bottom: 10%;
}
.question_comment_area__tKhRi form{
	width: 100%;
	height: 100%;
}
.question_comment_area__tKhRi form textarea{
	width: 100%;
	height: 100%;
	resize: none;
	font-size: 20px;
	padding: 1%;
	border: 1px solid grey;
	box-shadow: 0 1px 3px grey;
	border-radius: 0 0 20px 20px;
}
.question_send_btn__re-LH{
	padding: 10px 20px;
	font-size: 16px;
	border-radius: 50px;
	border: 1px solid grey;
	margin-left: 10px;
	box-shadow: 0px 1px 3px grey; 
	color: #fff;
	background: orange;
	position: relative;
	top: 0;
	left: 80%;
	cursor: pointer;
}
.question_sub_comment_details__3NXa5{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
    background: rgba(254, 231, 125, 0.2);
}
.question_sub_comment_p__1aGuY{
	width: 20%;
	padding: 2% 0;
	font-weight: bold;
}
.question_sub_comment_profile__3BJOw{
	width: 15%;
	text-align: center;
	padding: 2% 0;
}
.question_sub_comment_profile__3BJOw img{
	width: 80%;
	height: 70px;
	border-radius: 50%;
}
.question_comment_body__1UyPR img{
	width: 30%;
	height: 120px;
	border: 1px solid lightgrey;
	border-radius: 20px;
}
.question_sub_comment_body__11-F7{
	width: 75%;
	padding: 0  2%;
}
.question_sub_comment_body__11-F7 p{
	font-size: 16px;
	line-height: 22px;
}
.question_sub_comment_body__11-F7 i{
	 position: relative;
	 top: 0; 
	 left: 90%; 
	 font-size: 20px;
	 padding-bottom: 2%;
}


@media screen and (max-width: 1200px){
    .question_forum_wrapper__20RoH{
		margin: 1% 6%;
		width: 88%;
		margin-top: 80px;
		background-size: 100% 100%;
	}
	.question_forum__3o9Xp{
		width: 100%;	
	}
	.question_left_forum__3hykE{
		width: 30%;
		padding: 0;
	}
	.question_forum_profile_image__3xalP{
		width: 84%;
	}
	.question_forum_profile_image__3xalP img{
		width: 70%;
        height: 70%;
	}
	.question_forum_profile_status__2JFEt span{
		letter-spacing: 1px; 
	}
	.question_forum_profile_community__uaw7P a, p{
		letter-spacing: 1px;
	}
	.question_forum_profile_question__3PV5M{
		width: 90%;
	}
	.question_right_forum__1_rC4{
		width: 70%;
	}
	.question_right_forum__1_rC4 h1{
		letter-spacing: 1px;
	}
}

@media screen and (max-width: 990px){	
    .question_forum_wrapper__20RoH{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
	}
	.question_forum_profile_image__3xalP{
		width: 70%;
		height: 70%;
	}
	.question_forum_profile_image__3xalP img{
		width:70%;
		height: 70%;
	}
}

@media screen and (max-width: 700px){
    .question_forum_wrapper__20RoH{
		background-image: none;
	}
	.question_forum__3o9Xp{
		width: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;	
	}
	.question_left_forum__3hykE{
		width: 100%;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		padding: 0 2%;
	}
	.question_forum_profile_image__3xalP{
		width: 80%;
		height: 80%;
        text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	.question_forum_profile_image__3xalP img{
        width: 80%;
		/* height: 80%; */
        text-align: center;
	}
	.question_forum_profile_status__2JFEt{
		margin-top: 5%;
		text-align: center;
	}
	.question_forum_profile_status__2JFEt span{
		display: block;
		margin: 3% 0;
		font-size: 25px;
		letter-spacing: 2px; 
	}
	.question_forum_profile_community__uaw7P{
		margin-top: 5%;
		text-align: center;
	}
	.question_forum_profile_community__uaw7P a, p{
		margin: 3% 0;
		letter-spacing: 2px;
		font-size: 25px;
	}
	.question_forum_profile_question__3PV5M{
		margin-top: 5%;
		text-align: center;
	}
	.question_forum_profile_question__3PV5M p{
		font-size: 25px;
		letter-spacing: 2px;
	}
	.question_forum_profile_question__3PV5M form textarea{
		height: 200px;
		padding: 5%;
		font-size: 22px;
	}
	.question_right_forum__1_rC4{
		width: 100%;
		height: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
	}
	.question_right_forum__1_rC4 h1{
		margin-top: 10%;
		margin-bottom: 5%;
		font-size: 40px;
		letter-spacing: 2px;
	}
	.question_comment_box__-RWs6{
		margin: 2% 0;
		align-items: center;
	}
	.question_question_image_btn__1ggEV{
		width: 100%;
		text-align: right;
		padding: 2%;
	}
	.question_lets_talk__3bIzK{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.question_lets_talk__3bIzK div{
		margin: 2% 0;
	}
	.question_lets_talk__3bIzK div h2{
		font-size: 30px;
		letter-spacing: 1px;
	}
	.question_lets_talk__3bIzK button{
		font-size: 18px;
		padding: 10px 30px;
		font-size: 20px;	
	}
	.question_comment_details__2xThi{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.question_comment_profile__27CWE{
		width: 100%;
	}
	.question_comment_profile__27CWE img{
		width: 45%;
		height: 240px;
	}
	.question_comment_body__1UyPR{
		width: 100%;
	    line-height: 24px;
	}
	.question_comment_body__1UyPR p{
		font-size: 20px;
	}
	.question_comment_body__1UyPR span{
		font-size: 18px;
	}

	.question_sub_comment_profile__3BJOw p{
		font-size: 16px;
	}
	.question_sub_comment_profile__3BJOw img{
		width: 80%;
		height: 70px;
		border-radius: 50%;
	}
	.question_comment_body__1UyPR img{
		text-align: center;
		width: 50%;
		height: 200px;
		border: 1px solid lightgrey;
		border-radius: 20px;
	}
	.question_sub_comment_details__3NXa5 .question_sub_comment_p__1aGuY p{
		font-size: 16px;
	}
}







/* askQuestion.js */
.question_question_container__26G7W{
    display: grid;
    grid-template-columns: 2fr 2fr 4fr 3fr;
    grid-gap: 1em;
}


.question_user_info__3J4u9{
    grid-column: 2;
}
.question_question_map__26b5J{
    grid-column: 3;
}

/* userProfile */
.question_userImage__3swzp{
    text-align: center;
    margin-bottom: 30px;
}

.question_nameEdit__1n6e5{
    display: grid;
    grid-template-columns: 1fr 2fr
}


/* quesiton input box */
.question_questionInput__3Evss{
    margin-top: 10%;
    width: 90%;
}

.question_questionInput__3Evss textarea{
    font-size: .8rem;
    letter-spacing: 1px;
    width: 100%;
    height: 300px;
    line-height: 1.5;
    padding: 10px;
    border: 1px solid #3F5552;
    border-radius: 10px;
}

.question_like_and_message__3jqec{
    display: inline;
}

.question_image_sent__wZSej{
    /* display: inline; */
    float:right;
}


/* questionCard */
.question_allAndMyQuestionBtn__Ok2tN{
    text-align: right;
    text-decoration: none;
}

.question_allQuestion__2ja6E{
    font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: white;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    width: 100px;
    color: #4A5E58;
    padding: 1% 5%;
    
}

.question_myQuestion__14CjT{
    font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: #4A5E58;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    padding: 1% ;
    color: white;
    margin: 1%;

}

.question_allquestioninAll__8llbj{
	color: #4A5E58;
}



.question_allquestioninAll__8llbj:hover{
	text-decoration: none;
	font-weight: 800;
	color: #4A5E58;;
}


.question_myquestionInAll__KT4Qz:hover{
	text-decoration: none;
	font-weight: 700;
	color: white;
}

.question_myquestionInAll__KT4Qz{
	font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: #4A5E58;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    padding: 1% ;
    color: white;
}

.question_allQuestionInMy__tpk8a{
    font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: #4A5E58;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    padding: 1% 5%;
    color: white;
    margin: 1%;
}

.question_myQuestionInMy__pDHJp{
    font-weight: 400;
    border: 2px solid #4A5E58;
    background-color: white;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    border-radius: 20px ;
    width: 100px;
    color: #4A5E58;
    padding: 1% ;
}

.question_questionnbutton__TPBhm{
    display: block;
    width: 100%;
    margin-top : 3%;
    cursor: pointer;
    text-align: left;
    background-color: #FFF7CD;
    border: none;
    padding: 1%;
    border-top-right-radius: 20px 20px;
    border-top-left-radius: 20px 20px;
}



.question_textContent__1aq-0{
    display: grid;
    grid-template-columns: 1fr 4fr;

}

.question_commonCount__1GGel{
    text-align: right;
    
}

.question_commonCountinner__1bEHF{
}

.question_answertextarea__BDSAi textarea{
    border-bottom-right-radius: 20px 20px;
    border-bottom-left-radius: 20px 20px;
    height: 80px;
    line-height: 1.5;
}

.question_submitbtn__meQnj{
    text-align: right;
}

.question_submitbtn__meQnj input{
    background: #FB9F00;
    opacity: 0.9;
    border-radius: 20px;
    border: none;
    color: white;
    padding: 3px 2%;
    box-shadow: 2px 2px #c1c1c1;
    margin: 1% 0px;
    text-align: right;
}

.question_questionSubmitbtn__sUvi5{
    background: #FB9F00;
    opacity: 0.9;
    border-radius: 20px;
    width: 100px;
    border: none;
    color: white;
    padding: 3% 4%;
}

.question_contentOnly__2ObMQ{
    margin-top: 1%;
}

.question_questionImage__3K5LL{
    display: block;
    width: 150px;
    border-radius: 20px;
}

.question_questionnbutton__TPBhm img{
    max-width: 100%; 
    display:block;

}

.question_answerdiv__1aEb3{
    background-color: rgba(255, 247, 205, 0.6);
    display: grid;
    grid-template-columns: 1fr 1fr 4fr;
    padding-top: 1%;
}

.question_answeruser__3evmf{
    grid-column: 2;
    text-align: center;
}

/* userCard */
.question_userImage__3swzp{
    width: 100px;
}
.userCrop_garden_plan__zRJVC{
	margin: 3% 8%;
	width: 80%;
	height: auto;
	margin-top: 80px;
	background-repeat: no-repeat;
	background-position: 15% 100%;
	background-origin: border-box;
	background-attachment: scroll;
	background-size: 40% 50%;
}
.userCrop_planmygarden__1eR8Z{
    margin-top: 5%;
    margin-left: 10%;
    font-size: 50px;
    font-family: 'Arapey', serif;
}

.userCrop_garden_plan__zRJVC h1{
	font-weight: 500;
	font-size: 40px;
	padding: 10px;
}
.userCrop_my_veg_id__1TBaL{
	width: 60%;
	height: auto;
	position: relative;
	top: 0;
	left: 40%;
	margin: 2% 0;
	background: #FFF6C8;
	border-radius: 20px;
	padding: 4%;
}

.userCrop_vegID__3rGXn{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 30px;

}
form.userCrop_veg_form__2PZG0{
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-end;
}
.userCrop_each_field_plan__kysqn{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	flex-direction: row;
	flex-wrap: nowrap;
}
.userCrop_each_field_plan__kysqn h1{
	padding: 5% 0;
	color: orange;
	letter-spacing: 3px;
	font-weight: 500;
	font-size: 40px;
}
.userCrop_each_field_plan__kysqn div{
	width: 50%;
	margin-bottom: 3%;
	padding: 0 2%;
}
div.userCrop_each_field_plan__kysqn label{
	font-size: 22px
}
div.userCrop_each_field_plan__kysqn select{
	padding: 8px;
	width: 95%;
	border-radius: 5px;
	border: none;
	box-shadow: 0px 2px 3px 0px black;
	margin: 1%;
}

.userCrop_useCropinputbox__1QvQb{
    padding: 8px;
	width: 95%;
	border-radius: 5px;
	border: none;
	box-shadow: 0px 2px 3px 0px black;
	margin: 1%;
}



.userCrop_each_field_plan__kysqn img{
	width: 80%;
	height: 180px;
	border-radius: 10px;
	padding: 1%;
}
.userCrop_each_field_plan__kysqn .userCrop_btn_2__2xbRA{
	padding: 0.8rem 2rem;
	font-size: 1.5rem;
	letter-spacing: 0.2rem;
	background-color: rgba(250, 169, 5, 1);
	color: white;
	border: none;
	border-radius: 50px;
	cursor: pointer;
	text-align: center;
}
.userCrop_each_field_plan__kysqn .userCrop_btn_2__2xbRA:hover{
	background-color: rgba(250, 169, 5, 0.8);
}
.userCrop_each_field_plan__kysqn .userCrop_btn_1__1W7ej{
	padding: 0.8rem 2rem;
	font-size: 1.5rem;
	letter-spacing: 0.2rem;
	background-color: rgba(245, 240, 250, 0.5);
	color: rgba(250, 169, 5, 1);
	border: none;
	border-radius: 50px;
	box-shadow: 0 2px 1px 1px rgba(250, 169, 5, 1);
	cursor: pointer;
}
.userCrop_each_field_plan__kysqn .userCrop_btn_1__1W7ej:hover{
	background-color: rgba(245, 240, 250, 0.8);
}

#userCrop_city__1yffp{
	background-color: #fff; 
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-position: 98% center;
	background-size: 15px;
	-webkit-appearance: none;
	appearance: none;
}

@media screen and (max-width: 1200px){
    .userCrop_garden_plan__zRJVC{
		margin: 1% 5%;
		width: 90%;
		margin-top: 80px;
		background-position: 12% 95%;
		background-size: 30% 35%;
	}
	.userCrop_garden_plan__zRJVC h1{
		font-size: 38px;
	}
	.userCrop_my_veg_id__1TBaL{
		width: 70%;
		left: 30%;
	}
	.userCrop_each_field_plan__kysqn img{
		width: 75%;
		height: 170px;
		border-radius: 10px;
		padding: 1%;
		border: 1px solid lightgrey;
	}
	.userCrop_each_field_plan__kysqn .userCrop_btn_2__2xbRA{
		padding: 0.8rem 1.5rem;
		font-size: 1.3rem;
	}
	.userCrop_each_field_plan__kysqn .userCrop_btn_1__1W7ej{
		padding: 0.8rem 1.5rem;
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 990px){
    .userCrop_garden_plan__zRJVC{
		margin: 1% 3%;
		width: 94%;
		margin-top: 80px;
		background-position: 0 97%;
		background-size: 30% 35%;
	}
	.userCrop_garden_plan__zRJVC h1{
		font-size: 36px;
	}
	.userCrop_my_veg_id__1TBaL{
		width: 80%;
		left: 20%;
	}
}

@media screen and (max-width: 890px){
    .userCrop_garden_plan__zRJVC{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		background: none;
	}
	.userCrop_garden_plan__zRJVC h1{
		font-size: 36px;
	}
	.userCrop_my_veg_id__1TBaL{
		width: 100%;
		left: 0;
	}
}

@media screen and (max-width: 600px){
    .userCrop_garden_plan__zRJVC{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		background: none;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.userCrop_my_veg_id__1TBaL{
		width: 100%;
		margin: 2% 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.userCrop_my_veg_id__1TBaL > h1{
		padding: 4% 0;
	}
	form.userCrop_veg_form__2PZG0{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.userCrop_each_field_plan__kysqn{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.userCrop_each_field_plan__kysqn div{
		width: 100%;
	}
	#userCrop_o-1__-2ij2{
		order: 1;
	}
	#userCrop_o-1__-2ij2 img{
		width: 60%;
		height: 220px;
	}
	#userCrop_o-2__3tvWJ{
		order: 2;
	}
	#userCrop_o-2__3tvWJ h1{
		text-align: center;
		font-weight: bold;
	}
	.userCrop_each_field_plan__kysqn .userCrop_btn_2__2xbRA{
		width: 60%;
		padding: 0.8rem 2rem;
		font-size: 1.3rem;
	}
	.userCrop_each_field_plan__kysqn .userCrop_btn_1__1W7ej{
		width: 60%;
		padding: 0.8rem 2.6rem;
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 480px){
    .userCrop_garden_plan__zRJVC{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		background: none;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.userCrop_my_veg_id__1TBaL{
		width: 100%;
		margin: 2% 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.userCrop_my_veg_id__1TBaL > h1{
		padding: 4% 0;
	}
	form.userCrop_veg_form__2PZG0{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
	.userCrop_each_field_plan__kysqn{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.userCrop_each_field_plan__kysqn div{
		width: 100%;
	}
	#userCrop_o-1__-2ij2{
		order: 1;
	}
	#userCrop_o-1__-2ij2 img{
		width: 60%;
		height: 220px;
	}
	#userCrop_o-2__3tvWJ{
		order: 2;
	}
	#userCrop_o-2__3tvWJ h1{
		text-align: center;
		font-weight: bold;
	}
	.userCrop_each_field_plan__kysqn .userCrop_btn_2__2xbRA{
		width: 60%;
		padding: 0.8rem 2rem;
		font-size: 1.3rem;
	}
	.userCrop_each_field_plan__kysqn .userCrop_btn_1__1W7ej{
		width: 60%;
		padding: 0.8rem 2.6rem;
		font-size: 1.3rem;
	}
}



.userGarden_garden_wrapper__3fqyv{
	/* margin: 1% 6%; */
	/* width: 89%; */
	width: 89%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 80px;
	align-items: center;
	/* display: flex;
	justify-content: center;
	
	flex-direction: row;
	padding: 1% 0; */
	/* background-image: url(".././images/undraw_sunny.png");  */
	background-size: 92% 100%;
	background-repeat: no-repeat;
	background-origin: border-box;
	background-position: top center;
	background-attachment: scroll;
}

.userGarden_garden_detail_wrapper__lW8Ew{
	margin: 1% 8%;
	width: 89%;
	margin-top: 80px;
}

.userGarden_garden__374uy{
	/* width: 84%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;	 */
	display: grid;
	grid-template-columns: 2fr 4fr;
	grid-gap: 1em;
}

.userGarden_left_garden__24hu-{
	/* width: 100%; */
	height: auto;
	/* display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding: 0 2%; */
}
.userGarden_garden_profile_image__1Ryp2{
	width: 90%;
	height: 200px;
	padding: 1%;
	text-align: center;
}
.userGarden_garden_profile_image__1Ryp2 img{
	/* width: 90%;
	height: 200px;
	border-radius: 50%; */
	float: center;
    width:  150px;
    height: 150px;
    object-fit: cover;
	border-radius: 50%;
}

.userGarden_userlikeImages__1gzo3{
	float: center;
    width:  150px;
    height: 150px;
    object-fit: cover;
}

.userGarden_garden_profile_status__2vg55{
	margin-top: 10%;
	width: 100%;
}
.userGarden_garden_profile_status__2vg55 span{
	display: block;
	margin: 3% 0;
	font-size: 18px;
	letter-spacing: 2px; 
}
.userGarden_garden_profile_community__37Omr{
	margin-top: 10%;
	width: 100%;
}
.userGarden_garden_profile_community__37Omr a, p{
	display: block;
	margin: 3% 0;
	letter-spacing: 2px;
	font-size: 18px;
}
.userGarden_garden_profile_forum__23aFj{
	margin-top: 10%;
	width: 100%;
}
.userGarden_garden_profile_forum__23aFj p{
	font-size: 18px;
	letter-spacing: 2px;
}
.userGarden_right_garden__2g54d{
	/* width: 100%; */
	height: auto;
	/* display: flex; */
	/* justify-content: center;
	align-items: flex-start;
	flex-direction: column; */
}
.userGarden_right_garden__2g54d h1{
	font-size: 40px;
	margin-top: 13%;
	letter-spacing: 2px;
}
.userGarden_garden_box__hCkuv{
	/* width: 100%; */
	height: auto;
	margin: 2% 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}
.userGarden_your_garden__27FYR{
	margin: 6% 0 3% 0;
}
.userGarden_your_garden__27FYR > h1{
	font-size: 36px;
}
.userGarden_garden_details__lriIU{
	/* width: 800px; */
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2%;
    background: rgba(255, 235, 125, 0.5);
    border-radius: 20px;
    margin-bottom: 5%;
}
.userGarden_top_garden__gWCeB{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;	
}
.userGarden_top_garden__gWCeB div{
	width: 46%;
	height: auto;
}
.userGarden_top_garden_name__1tPSf p{
	font-size: 35px;
	font-weight: 600;
	letter-spacing: 2px;
}
.userGarden_top_garden_btn__3kxWA button{
	padding: 10px 20px;
	font-size: 18px;
	border-radius: 50px;
	border: 1px solid grey;
	box-shadow: 0px 1px 3px grey; 
	color: #fff;
	background: orange;
	cursor: pointer;
	float: right;
}
.userGarden_bottom_garden__2T7kM{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 1% 0;
}
.userGarden_bottom_garden_img__k6gfV{
	width: 25%;
	height: auto;
}
.userGarden_bottom_garden_img__k6gfV img{
	width: 100%;
	height: 160px;
	border-radius: 20px;
	border: 1px solid lightgrey;
}
.userGarden_bottom_garden_details__3gebw{
	width: 70%;
	height: auto;
}
.userGarden_bottom_garden_details__3gebw p{
	font-size: 20px;
	margin-bottom: 5%;
}
.userGarden_bottom_garden_details__3gebw img{
	margin: 0 5px;
	width: 40px;
	height: 40px;
}
img.userGarden_line1__3bEgK{
	height: 10px;
	margin-bottom: 15px;
}
.userGarden_wishing_list__15GAH{
	/* width: 70%; */
	/* display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; */
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	text-align: center;
}
.userGarden_wishing_title__3GS3R p{
	font-size: 30px;
	font-weight: 600;
	letter-spacing: 2px;
}
.userGarden_wishing_images__32Z-_{
	width: 300px;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 1% 0;
}
.userGarden_wishing_images__32Z-_ div{
	width: 90%;
	margin-right: auto;
	margin-left: auto;
	height: auto;
	text-align: center;
}
.userGarden_wishing_images__32Z-_ div img{
	width: 100%;
	height: auto;
	border: 1px solid lightgrey;
	border-radius: 30px;
}

.userGarden_userCropBox__2_lWI{
	display: grid;
	grid-template-columns: 1fr 1fr;
}


/* _________________________________________________________________ */

.userGarden_stageCard__9-m_n{
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	width: 85px;

}

.userGarden_stageCard__9-m_n img{
	margin-left: auto;
	margin-right: auto;
}

.userGarden_stageContainer__3E-SF{
	text-align:center;
	
}
.userGarden_in_same_line__3VFHW{
	display: inline-block;
	margin: 5px;
	border: 2px solid orange;
	border-radius: 10px ;
	padding: 2px;
}


@media only screen and (max-width: 1500px){
	.userGarden_garden_details__lriIU{
		width: 700px;
		
	}
}


@media only screen and (max-width: 1300px){
	.userGarden_garden_details__lriIU{
		width: 550px;
	}
	.userGarden_wishing_list__15GAH{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

}

@media only screen and (max-width: 900px){
	.userGarden_garden_details__lriIU{
		width: 450px;
	}
	.userGarden_wishing_list__15GAH{
		display: grid;
		grid-template-columns: 1fr ;
	}
}	

@media only screen and (max-width: 500px){
	.userGarden_garden_details__lriIU{
		width: 350px;
	}

	.userGarden_garden__374uy{
		/* width: 84%;
		height: auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;	 */
		display: grid;
		grid-template-columns: 1fr;
		/* grid-gap: 1em; */
		text-align: center;
	}
	
}
.annual_inputContainer__3Yj3S{
    width: 60%;
    padding: 3%;
    /* border: 4px solid gray; */
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    background-color: #FFF7CD;
}

.annual_charContainer__3Syo0{
    /* padding: 3% 5% 0% 5%; */
}
.annual_dispalyContainer__BRyUl{
    padding-top: 3%;
}

.annual_chartBtn__XxuVX{
    border: 2px solid #3F5552;
    color: #3F5552;
    padding: 1% 2% ;
    background-color: white;
    border-radius: 40px;
    margin: 1%;
}

.annual_cardsBtn__1mKba{
    border: none;
    color: white;
    padding: 1% 2%  ;
    background-color:  #3F5552;
    border-radius: 40px;
    margin: 1%;
}

.annual_annualContainer__3FnbB{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.annual_annualConfirmBtn__2z-4p{
    background-color: #FB9F00;
    border: none;
    padding: 2%;
    border-radius: 50px;
    color: white;
}

.annual_bottomspace__1iqAS{
    height: 80px;
}

.annual_graphBoarder__1k4A-{
    border: 8px solid #DFE7B4;
    padding: 3%;
    margin: 3%;
    border-radius: 20px;
}
.ibmdata_weekdataContainer__wnwyk{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
    margin-top: 5%;
    padding-bottom: 3%;
}

.ibmdata_IconContainer__2cHBS{
    display: grid;
    grid-template-columns: 2fr 1fr;
}
/* .weatherImage{
    width: 100px;
    height: 100px;
} */

.ibmdata_nightImage__2rcz1{
    width: 50px;
}

.ibmdata_weatherImage__osTO1 img{
    width: 100px;
}

.ibmdata_weaterCard__1yM1_{
    text-align: center;
}

.ibmdata_hourlyContainer__1WFab{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.ibmdata_locationSetting__2DxW-{
    margin: 3%;
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.ibmdata_locationSelect__2DSWM{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #FFF7CD;
    border-radius: 25px;
    padding: 3%;
}

.ibmdata_IBMWeatherContent__1sGHc{
    text-align: center;
}

.ibmdata_moredailyinfo__3wRKu{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.ibmdata_dayWeatherData__3yVbq{
    padding-bottom: 3%;
}
.weatherPage_weatherContainer__p6Va7{
    margin-top: 80px;
    margin-left: 3%;
    margin-right: 3%;
}



.weatherPage_locationSetting__2m2Dv{
    margin: 3%;
    display: grid;
    grid-template-columns: 1fr 3fr;
}


.weatherPage_locationSelect__2-FLZ{
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    background-color: #FFF7CD;
    border-radius: 25px;
    padding: 3%;
}

.weatherPage_IBMWeatherContent__XCoCT{
    text-align: center;
}
/* https://www.youtube.com/watch?v=BwhTKJFpKSw */

.loader_loaderbackground__1lQ1g{
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
}

.loader_loader__3ZO3k{
    height: 50px;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-animation: loader_rotate__3VKaD 3s linear infinite;
            animation: loader_rotate__3VKaD 3s linear infinite;
}

.loader_circle__M9aEs{
    display: inline-block;
    background-color: #fff8ce;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation: loader_grow__aIjvE 1.5s linear infinite;
            animation: loader_grow__aIjvE 1.5s linear infinite;
    margin: -10px;
}

.loader_circle__M9aEs:nth-child(2){
    background-color: #DFE7B4;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
}

@-webkit-keyframes loader_rotate__3VKaD {
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes loader_rotate__3VKaD {
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes loader_grow__aIjvE {
    50%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes loader_grow__aIjvE {
    50%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}


/* Loader2 */

.loader_loader2__2Er84{
    font-family: 'ZCOOL KuaiLe' cursive;
    font-size: 40px;
    color: palevioletred;
}

.loader_loader2__2Er84::after{
    content: '\2026';
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
    -webkit-animation: loader_dots__3k9gi steps(4, end) 2s infinite;
            animation: loader_dots__3k9gi steps(4, end) 2s infinite;
    width: 0px;
}

@-webkit-keyframes loader_dots__3k9gi {
    to{
        width: 1.25em;
    }
}

@keyframes loader_dots__3k9gi {
    to{
        width: 1.25em;
    }
}
.Navbar_NavbarContainer__G523d{
    width: 100%;
    height: 80px;
    display: flex;
    background-color: white;
    position: fixed;
    top:0;
    z-index: 10;
}

.Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc{
    flex: 60% 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5%;
}

.Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc .Navbar_links__1AFoR{
    max-height: 80px;
}

.Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc button{
    display: none;
}

.Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc #Navbar_hidden__2bWx8{
    display: flex;
}

.Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc .Navbar_links__1AFoR a{
    text-decoration: none;
    font-size: 20px;
    margin-right: 20px;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
}

.Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc .Navbar_links__1AFoR a:hover{
    text-decoration: none;
    color: white;
	padding: 25px 13px;
	background: darkgreen;
	transition: all .5s ease;
}

.Navbar_NavbarContainer__G523d .Navbar_leftNav__25eIT{
    flex: 40% 1;
    justify-content: center;
    align-items: center;
}

.Navbar_logoImage__2PCVy{
	width: 250px;
    margin-top: 10px;
}

@media only screen and (max-width: 850px){
    .Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc .Navbar_links__1AFoR a{
        font-size: 18px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 750px){
    .Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc .Navbar_links__1AFoR a{
        font-size: 18px;
        margin-right: 10px;
    }
}



@media only screen and (max-width: 980px){
    .Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc button{
        display: flex;
    }

    .Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc .Navbar_links__1AFoR{
        display: none;
    }

    .Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc #Navbar_hidden__2bWx8 {
        position:absolute;
        left: 0px;
        top:80px;
        width: 100%;
		max-height: 100vh;
        background: #DFE7B4;
        z-index: 20;
        align-items: center;
        display: flex;
        flex-direction: column;
        transition: all .5s ease;
        text-align: center;
        
    }

    .Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc #Navbar_hidden__2bWx8 a{
        margin: 0px;
        font-size: 25px;
        color: black;
        display:inline-block;
        width: 100%;
        padding: 5%;
    }

    .Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc #Navbar_hidden__2bWx8 a:hover{
        display: block;
        background-color:  #FFF6C8;
    }
    

    .Navbar_NavbarContainer__G523d .Navbar_rightNav__vOWcc button{
        border: none;
        font-size: 25px;
        background-color: white;
    }
}

@media only screen and (max-width: 980px){
    
}
.langage_langagebar__XjW51{
    z-index: 10;
    position: fixed;
    padding: 1%; 
    right: 3%; 
}

.langage_langagedropdown__2Fl4u{
    /* position: relative; */
    background-color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}


/* .editDiaryImageBox{
	display: grid;
	grid-template-columns: 1fr 2fr;
} */

.plantedCropDetail_DiaryCard__18ZJl {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
	width: 100%;
}

.plantedCropDetail_DiaryCard__18ZJl:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.plantedCropDetail_diarybtn__1pCOI{
	background: #FB9F00;
    opacity: 0.9;
    border-radius: 20px;
    border: none;
    color: white;
    padding: 3px 2%;
    box-shadow: 2px 2px #c1c1c1;
    margin: 1% 0px;
    text-align: right;
	padding:3% 5%;
}

.plantedCropDetail_diaryDeleteBtn__yKBdz{
	background: white;
	border:solid red;
    opacity: 0.9;
    border-radius: 20px;
    border: none;
    color: red;
	font-weight: 700;
    padding: 3px 2%;
    box-shadow: 2px 2px #c1c1c1;
    margin: 1% 0px;
    text-align: right;
	margin: 5%;
	padding:3% 5%;
}

/*##################### Product Section ########################*/
.plantedCropDetail_product_section__3w1rA {
	margin: 80px 8% 2% 8%;
	width: 84%;
	height: auto;
	border: none;
}

.plantedCropDetail_product_page__2U8N5 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 8rem;
	width: 90%;
	background: rgba(240, 240, 240, 0.4);
	height: auto;
	border-radius: 50px;
}

.plantedCropDetail_product_top__2cwHk {
	width: 60%;
	height: auto;
	padding: 0 4%;
}

.plantedCropDetail_product_description__3wBei h1 {
	text-transform: uppercase;
	margin: 10% 0 5% 0;
}

.plantedCropDetail_product_description__3wBei p {
	font-size: 17px;
	line-height: 25px;
	margin: 5% 0;
}

/* .product_order {
	margin-top: 40px;
} */

/* .product_order h2 {
	font-size: 30px;
	margin: 4% 0;
	color: rgba(242, 71, 65, 1);
} */

.plantedCropDetail_product_order__3f4Zx a {
	background: rgba(242, 71, 65, 1);
	padding: 10px 30px;
	text-transform: uppercase;
	color: white;
	font-weight: bold;
	text-decoration: none;
	border-radius: 5px;
}

.plantedCropDetail_product_description__3wBei span {
	padding: 10px;
	color: rgba(242, 71, 65, 1);
	font-weight: bold;

}

.plantedCropDetail_product_bottom__3CPvz {
	display: flex;
	justify-content: space-between;
	width: 96%;
	height: auto;
	margin: 3% 2% 2% 2%;
}

.plantedCropDetail_product_items__KAd2G {
	width: 40%;
	height: 100%;
	padding: 1%;
}

.plantedCropDetail_product_items__KAd2G h1 {
	padding: 1% 0 0 0;
	text-transform: uppercase;
}

.plantedCropDetail_product_items__KAd2G li {
	list-style: none;
	padding-top: 2%;
}

.plantedCropDetail_product_items__KAd2G li i {
	padding-right: 2%;
	color: rgba(242, 71, 65, 1);
}

.plantedCropDetail_product_divider__2e0wm {
	border: 3px solid black;
	width: 100px;
	margin-bottom: 10px;
	border-radius: 10px;
}

.plantedCropDetail_product_video__2z6To {
	width: 40%;
	height: 100%;
	padding: 1%;
}

.plantedCropDetail_product_video__2z6To h1 {
	padding: 1% 0 0 0;
	text-transform: uppercase;
}

.plantedCropDetail_product_video__2z6To video {
	margin-top: 2%;
	width: 280px;
	height: 200px;
}

.plantedCropDetail_product_video__2z6To h2 {
	padding: 2% 0;
}

.plantedCropDetail_carousel_container__1grUo {
	display: flex;
	border-radius: 30px;
	overflow: hidden;
	width: 40%;
	z-index: 0;
	height: 55%;
	position: absolute;
	top: 80px;
	left: 52%;
}

/* Hide the images by default */
.plantedCropDetail_mySlides__2T7Ex {
	/* display: none; */
	height: 100%;
	width: 100%;
}

.plantedCropDetail_mySlides__2T7Ex img {
	display: block;
	height: 100%;
	width: 100%;
}

/* image gradient overlay [optional] */
/* .mySlides::after {
content: "";
position: absolute;
inset: 0 0 0 0;
background-image: linear-gradient(-45deg, rgba(110, 0, 255, .1), rgba(70, 0, 255, .2));
} */

/* Next & previous buttons */
.plantedCropDetail_prev__1Vq07,
.plantedCropDetail_next__2m4Ce {
	cursor: pointer;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
	width: auto;
	padding: 20px;
	color: white;
	font-weight: bold;
	font-size: 24px;
	border-radius: 0 8px 8px 0;
	background: rgba(173, 216, 230, 0.1);
	-webkit-user-select: none;
	        user-select: none;
}

.plantedCropDetail_next__2m4Ce {
	right: 0;
	border-radius: 8px 0 0 8px;
}

.plantedCropDetail_prev__1Vq07:hover,
.plantedCropDetail_next__2m4Ce:hover {
	ackground-color: rgba(173, 216, 230, 0.3);
}

/* Caption text */
.plantedCropDetail_text__2H_A3 {
	color: #f2f2f2;
	background-color: rgba(10, 10, 20, 0.4);
	-webkit-backdrop-filter: blur(6px);
	        backdrop-filter: blur(6px);
	border-radius: 10px;
	font-size: 20px;
	padding: 8px 12px;
	position: absolute;
	bottom: 60px;
	left: 50%;
	-webkit-transform: translate(-50%);
	        transform: translate(-50%);
	text-align: center;
}

/* Number text (1/3 etc) */
.plantedCropDetail_number__1IpXr {
	color: #f2f2f2;
	font-size: 16px;
	background-color: rgba(173, 216, 230, 0.3);
	-webkit-backdrop-filter: blur(6px);
	        backdrop-filter: blur(6px);
	border-radius: 10px;
	padding: 8px 12px;
	position: absolute;
	top: 10px;
	left: 10px;
}

.plantedCropDetail_dots_container__12sNX {
	position: absolute;
	bottom: 10px;
	left: 50%;
	-webkit-transform: translate(-50%);
	        transform: translate(-50%);
}

/* The dots/bullets/indicators */
.plantedCropDetail_dots__1mK5K {
	cursor: pointer;
	height: 14px;
	width: 14px;
	margin: 0 4px;
	background-color: rgba(173, 216, 230, 0.8);
	-webkit-backdrop-filter: blur(2px);
	        backdrop-filter: blur(2px);
	border-radius: 50%;
	display: inline-block;
	transition: background-color 0.3s ease;
}

.plantedCropDetail_active__1BkRw,
.plantedCropDetail_dots__1mK5K:hover {
	background-color: rgba(173, 216, 230, 0.8);
}

/* transition animation */
.plantedCropDetail_animate__3ryqW {
	-webkit-animation-name: plantedCropDetail_animate__3ryqW;
	-webkit-animation-duration: 1s;
	animation-name: plantedCropDetail_animate__3ryqW;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
}

@-webkit-keyframes plantedCropDetail_animate__3ryqW {
	from {
		-webkit-transform: scale(1.1) rotateY(10deg);
		        transform: scale(1.1) rotateY(10deg);
	}

	to {
		-webkit-transform: scale(1) rotateY(0deg);
		        transform: scale(1) rotateY(0deg);
	}
}

@keyframes plantedCropDetail_animate__3ryqW {
	from {
		-webkit-transform: scale(1.1) rotateY(10deg);
		        transform: scale(1.1) rotateY(10deg);
	}

	to {
		-webkit-transform: scale(1) rotateY(0deg);
		        transform: scale(1) rotateY(0deg);
	}
}

@media screen and (max-width: 1200px) {
	.plantedCropDetail_product_section__3w1rA {
		margin: 50px 6% 2% 6%;
		width: 88%;
	}

	.plantedCropDetail_product_page__2U8N5 {
		margin-top: 3rem;
	}

	.plantedCropDetail_product_top__2cwHk {
		width: 58%;
	}

	.plantedCropDetail_product_description__3wBei h1 {
		font-size: 28px;
	}

	.plantedCropDetail_product_description__3wBei p {
		font-size: 16px;
	}

	.plantedCropDetail_product_order__3f4Zx {
		margin-top: 20px;
	}

	.plantedCropDetail_product_order__3f4Zx h2 {
		font-size: 26px;
	}

	.plantedCropDetail_product_order__3f4Zx a {
		padding: 10px 20px;
	}

	.plantedCropDetail_product_order__3f4Zx span {
		padding: 10px;
		font-size: 16px;
	}

	.plantedCropDetail_product_items__KAd2G h1 {
		font-size: 28px;
	}

	.plantedCropDetail_product_items__KAd2G li {
		font-size: 15px;
	}

	.plantedCropDetail_product_divider__2e0wm {
		width: 85px;
	}

	.plantedCropDetail_product_video__2z6To video {
		width: 280px;
		height: 200px;
	}

	.plantedCropDetail_product_video__2z6To h1 {
		font-size: 28px;
	}

	.plantedCropDetail_product_video__2z6To h2 {
		font-size: 22px;
	}

	.plantedCropDetail_carousel-container__1Bc4I {
		width: 42%;
		height: 45%;
	}
}


@media screen and (max-width: 990px) {

	.plantedCropDetail_carousel_container__1grUo {
		display: block;
		border-radius: 10px;
		overflow: hidden;
		width: 80%;
		z-index: 0;
		height: auto;
		position: relative;
		top: 20px;
		left: 10%;
	}

	.plantedCropDetail_DiaryCard__18ZJl {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		transition: 0.3s;
		width: 88%;
	} 

	.plantedCropDetail_product_section__3w1rA {
		margin: 80px 4% 2% 4%;
		width: 92%;
	}

	.plantedCropDetail_product_top__2cwHk {
		width: 60%;
	}

	.plantedCropDetail_product_description__3wBei h1 {
		font-size: 25px;
	}

	.plantedCropDetail_product_description__3wBei p {
		font-size: 20px;
		line-height: 23px;
		margin: 2% 0;
		;
	}

	.plantedCropDetail_product_order__3f4Zx {
		margin-top: 10px;
	}

	.plantedCropDetail_product_order__3f4Zx h2 {
		font-size: 22px;
	}

	.plantedCropDetail_product_order__3f4Zx a {
		padding: 8px 16px;
		font-size: 14px;
	}

	.plantedCropDetail_product_order__3f4Zx span {
		padding: 10px;
		font-size: 14px;
	}

	.plantedCropDetail_product_items__KAd2G h1 {
		font-size: 25px;
	}

	.plantedCropDetail_product_items__KAd2G li {
		font-size: 14px;
	}

	.plantedCropDetail_product_divider__2e0wm {
		width: 85px;
	}

	.plantedCropDetail_product_video__2z6To video {
		width: 280px;
		height: 200px;
	}

	.plantedCropDetail_product_video__2z6To h1 {
		font-size: 25px;
	}

	.plantedCropDetail_product_video__2z6To h2 {
		font-size: 18px;
	}

	.plantedCropDetail_carousel-container__1Bc4I {
		width: 44%;
		height: 40%;
	}
}

@media screen and (max-width: 890px) {
	.plantedCropDetail_product_section__3w1rA {
		margin: 10px 3% 2% 3%;
		width: 94%;
	}

	.plantedCropDetail_product_top__2cwHk {
		width: 59%;
	}

	.plantedCropDetail_product_description__3wBei h1 {
		font-size: 25x;
	}

	.plantedCropDetail_product_description__3wBei p {
		font-size: 20px;
		margin: 1% 0;
		;
	}

	.plantedCropDetail_product_order__3f4Zx {
		margin-top: 8px;
	}

	.plantedCropDetail_product_order__3f4Zx h2 {
		font-size: 18px;
	}

	.plantedCropDetail_product_order__3f4Zx a {
		padding: 7px 10px;
		font-size: 13px;
	}

	.plantedCropDetail_product_order__3f4Zx span {
		padding: 6px;
		font-size: 13px;
	}

	.plantedCropDetail_product_items__KAd2G h1 {
		font-size: 20px;
	}

	.plantedCropDetail_product_items__KAd2G li {
		font-size: 13px;
	}

	.plantedCropDetail_product_divider__2e0wm {
		width: 60px;
	}

	.plantedCropDetail_product_video__2z6To video {
		width: 280px;
		height: 200px;
	}

	.plantedCropDetail_product_video__2z6To h1 {
		font-size: 20px;
	}

	.plantedCropDetail_product_video__2z6To h2 {
		font-size: 15px;
	}

	.plantedCropDetail_carousel-container__1Bc4I {
		width: 45%;
		height: 40%;
	}
}


@media screen and (max-width: 700px) {
	.plantedCropDetail_product_section__3w1rA {
		margin: 50px 3% 2% 3%;
		width: 94%;
	}

	.plantedCropDetail_product_page__2U8N5 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 0;
		width: 100%;
	}

	.plantedCropDetail_product_top__2cwHk {
		width: 100%;
	}

	.plantedCropDetail_product_description__3wBei h1 {
		font-size: 28px;
	}

	.plantedCropDetail_product_description__3wBei p {
		font-size: 16px;
		margin: 2% 0;
		;
	}

	.plantedCropDetail_product_order__3f4Zx {
		margin-top: 20px;
	}

	.plantedCropDetail_product_order__3f4Zx h2 {
		font-size: 22px;
	}

	.plantedCropDetail_product_order__3f4Zx a {
		padding: 10px 30px;
		font-size: 15px;
	}
/* 
	.product_order span {
		padding: 10px;
		font-size: 15px;
	} */

	.plantedCropDetail_product_bottom__3CPvz {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.plantedCropDetail_product_items__KAd2G {
		margin-top: 15px;
		width: 100%;
		height: auto;
		order: 2;
	}

	.plantedCropDetail_product_items__KAd2G h1 {
		font-size: 28px;
	}

	.plantedCropDetail_product_items__KAd2G li {
		font-size: 15px;
	}

	.plantedCropDetail_product_divider__2e0wm {
		width: 80px;
	}

	.plantedCropDetail_product_video__2z6To {
		margin-top: 15px;
		width: 100%;
		height: 100%;
		order: 1;
	}

	.plantedCropDetail_product_video__2z6To video {
		width: 96%;
		height: auto;
	}

	.plantedCropDetail_product_video__2z6To h1 {
		font-size: 28px;
	}

	.plantedCropDetail_product_video__2z6To h2 {
		font-size: 20px;
	}

	.plantedCropDetail_carousel-container__1Bc4I {
		position: relative;
		top: 0;
		left: 0;
		margin: 1% auto;
		width: 100%;
		height: 60vh;
	}
}
.pestbaseInfo_profileCardContainer__ddu1K{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    border: 5px solid #e2bb38;;
    background-color: #fff3d7;
    border-radius: 20px;
    padding: 2%;
}

.pestbaseInfo_cardChild__16oPN{
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.pestbaseInfo_cardChild__16oPN img{
    width: 300px;
}

.pestbaseInfo_cropCards__WPFkG{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
}

.pestbaseInfo_cropimg__2TqO3 {
    float: center;
    width:  150px;
    height: 150px;
    object-fit: cover;
}

.pestbaseInfo_cropComponent__ZqVjT{
    display: grid;
    grid-template-rows: 3fr 1fr;
    text-align: center;
}

@media only screen and (max-width: 980px){
    .pestbaseInfo_profileCardContainer__ddu1K{
        width: 90%;
        padding: 1%;
    }

    .pestbaseInfo_cardChild__16oPN{
        display: grid;
        grid-template-columns: 1fr;
    }

    .pestbaseInfo_cropCards__WPFkG{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }

    .pestbaseInfo_cropimg__2TqO3 {
        width:  250px;
        height: 250px;
    }
}
