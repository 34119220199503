.details{
    display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 450px;
	margin: 1%;
	overflow-y: scroll;
}

.details.is_active{
	display: block;
}
.details::-webkit-scrollbar{
	width: 15px;
}
.details::-webkit-scrollbar-track{
	background: #f1f1f1;
}
.details::-webkit-scrollbar-thumb{
	background: #3F5552;
}
.details::-webkit-scrollbar-thumb:over{
	background: #555;
}

.messagesAllContainer{
    margin-left: 4%;
    margin-right: 4%;
}
.messagetitle{
    margin-left: 2%;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
}

.commentContainer{
    display: grid;
    grid-template-columns: 1fr 7fr;
    grid-gap: 2em;
    background-color: #FFF7CD;
    border-radius: 20px;
    margin: 1%;
}

.userInfo{
    display: grid;
    grid-template-rows: 3fr 1fr;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}

.userImgContainer{
    display: inline-block; 
    position: relative; 
    width: 80px; 
    height: 80px; 
    overflow: hidden; 
    border-radius: 50%;
    margin:5%;
}

.userImgContainer img{
    width: auto; 
    height: 100%; 
    /* margin-left: -10px; */
}

.userImgContainer span{
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
}

.messageContainer{
    margin-top: 1%;
    margin-left: 3%;
}

.messageContainer p{
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
}