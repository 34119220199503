.stage_no{
	width: 100%;
	padding: 20px 20px;
}

.stage_no:nth-child(odd){
	background: rgba(255, 238, 184, 0.9);
}
.stage_no:nth-child(even){
	background: rgba(252, 252, 212, 1);
}

.stage_no > h3{
	padding: 10px 10px ;
    font-weight: 700;
}

.contentGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.WeekcontentInCard{
    margin: 1%;
}

.WeekcontentInCard p{
    line-height: 1ch;
}

.monthContentCards p{
    line-height: 1.5ch;
}

.weektext{
    font-weight: 700;
    font-size: 20px;
    padding-left: 5px;
}

.monthlyCard{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 1200px){  
    .monthlyCard{
        display: grid;
        grid-template-columns: 1fr 1fr ;
    }
}

@media screen and (max-width: 800px){
    .contentGrid{
        display: grid;
        grid-template-columns: 1fr;
    }
    .monthlyCard{
        display: grid;
        grid-template-columns: 1fr ;
    }
}