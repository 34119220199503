
.Hero_section{
	margin: 80px 7% 2% 7%;
	background-size:100% 100%;
	height:75vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding:20px;
    background-color: #fff3d7;
	border-radius: 30px;
}

.left_area{
	margin: 20px;
	width: 50%;
}

.left_area h1{
	font-family: 'Quicksand', sans-serif;
	font-weight: 700;
	line-height: 50px;
	letter-spacing: 1px;
	padding-bottom: 20px;
}

.left_area p{
	font-size: 16px;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

a.btn_hero{
	text-decoration: none;
	padding: 15px;
	border: 3px solid green;
	border-radius: 50px;
	color: black;
	font-weight: 700;
}

a.btn_hero:hover{
	border-radius: 50px;
	background: green;
	color: white;
}


.right_area{
	width: 50%;
    /* height: 70%; */
	margin-left: 40px;
    margin-right: 20px;
}

.right_area img{
	border-radius:20px 20px 100px 20px;
	width: 90%;
	/* height:450px; */
}

.hero_social_links {
	 padding: 15px 0; 
	 margin-top: 50px;
}

.hero_social_links i{
	font-size: 30px;
	color: blue;
	border-radius: 5px;
	z-index: 10;
}
.hero_social_links a{
	padding: 0 20px;
}


/* ************************* About Section ****************** */

.about_section{
	margin: 0 8%;
	display: flex;
	justify-content: center;
	border-radius: 30px;
}

.about_image{
	margin: 1% 1%;
	width: 48%;
	height: auto;	
	float: left;
}

.about_image_1{
	width: 100%;
	height:auto; 
	border-radius: 20px;
}
	
.content_area {
	width: 48%;
	margin: 1% 1%;
}

.about_content{
	padding: 0% 1%;
	border-radius: 20px;
	width: 100%;
	height: auto;	
	float: right;
}

.about_content h1 {
	color: orange; 
	font-weight:bold;
	line-height: 40px;
	padding: 10px 10px 0 10px;
	text-transform: uppercase;
}

.divider_about {
	border: 3px solid #2fe7eb;
	width: 110px;
	margin-left: 10px;
	border-radius:10px;
}

.about_content h2 {
	font-size: 18px;
	font-weight:bold;
	padding: 15px 0px 10px 10px;
}

.about_content p {
	line-height: 24px;
	font-size: 16px;
	text-align: justify; 
	padding: 10px 10px;
}


/*********************** our services *****************************/

.our_services{
	padding: 10px;
	margin:1% 7%;
	text-align: center;
	height: auto;
	display: block;
}

.our_services h1 {
	text-align: center; 
	font-weight:bold;
	line-height: 40px;
	padding: 10px 10px 0 10px;
	text-transform: capitalize;
}

.divider_services {
	border: 3px solid #2016db;
	width: 150px;
	margin-left: 10px;
	border-radius: 10px;
	margin:0 auto;
}

.our_services p {
	font-size: 20px;
	font-weight: 500;
	text-align: center; 
	padding: 20px 0;
}

.service_item {
	float: left;
	width:26%; 
	height:auto; 
	margin: 2% 3%;
	padding:5px;
	border-radius:10px;
}

.service_item:hover {
	box-shadow: 0px 0px 2px 5px #f1f2e9 inset;
}

.service_item img{
	width: 75%;
	padding: 5px;;
}

.service_item p {
	font-size: 16px;
	text-align: justify;
	line-height: 24px;
	padding: 5px;
}

.service_item h2{
	padding: 20px 0 0 0;
	text-transform: capitalize;
}

/*******************************Start of Number Section ****************************/

.Gridlove_section{
	display: block;
	padding:1% 1%;
	height: auto;
	margin: 1% 8%; 
	border-radius: 30px;
}

.number-title h1{
	padding: 10px 0px 10px 80px;
	font-weight:bold;
	line-height: 40px;
}

.description_sidelove p{
	line-height:25px;
}


.left_girdlove{
	width: 48%;
	height: auto;
	display: block;
	float: left;
}

.circle_sidelove{
	margin:0px;
	padding:0px;
	width:50px;
	height:50px;
	color:white;
	background-color:#e2bb38;
	border:1px solid white;
	border-radius:50%;
	text-align:center;
	font-weight:700;
	line-height:50px;
}

.description_sidelove{
	margin-left: 80px;
	margin-top: -45px;
	margin-bottom: 20px;
	text-align: justify;
}

.main_grid_love{ 
	margin: 30px 0;
}

.right_sidelove{
	float:right;
	width: 48%;

}

.right_sidelove img{ 
	width: 100%; 
	height: 400px;
	border-radius: 20px;
}


/******************************* Our Team ************************************/

.our_team{
	width: 84%;
	text-align: center;
	padding: 1% 1%;
	margin:1% 8%;
	border-radius:30px;
}

.our_team h1{
	padding:10px 10px 0 10px;
}

.divider_team {
	border: 3px solid #2016db;
	width: 100px;
	margin: 0 auto;
	border-radius:10px;	
}

.profile_area {
	padding: 10px 0 0px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.main_profile {
	margin: 3%;
	width: 25%;
	padding:1%;
	
}

.profile_image img {
	border-radius: 90%;
}

.profile_name{
	font-weight:bold;
	font-size: 18px;
	padding: 10px 5px 5px 5px;
}

.profile_designation{
	font-size: 16px;
	padding:5px;
}

.profile_social_links{
	padding: 10px;
}

.profile_social_links span{
	padding: 0px 10px;
}

/************************************* Newsletter Section ***************************/
.news_letter{
	width: 84%;
	margin: 2% 8%;
	padding: 2% 5%;
	border-radius: 50px;
	background:rgba(255, 243, 215, 0.8);
}
.main_box{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.left_area_box{
	width: 40%;
}
.left_area_box img{
	width: 100%;
	border-radius: 30px;
}
.right_area_box{
	width: 50%;
	padding: 20px;
}
.right_area_box h1{
	font-size: 30px;
	padding: 10px 0;
}
.right_area_box p{
	font-size: 16px;
	padding: 0 0 10px 0;
}
.sub_input{
	width: 70%;
	padding: 10px;
	margin: 10px 0;
	border: 1px solid #ccc;
	box-sizing: border-box;
	border-radius: 20px;
}
.sub_btn {
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 20px;
  border: none;
  margin: 10px;
}

.sub_btn:hover {
  opacity: 0.6;
}

/*************************************End of Newsletter Section ***************************/


/*############################ Footer ############################*/

.footer{
	margin:0 5%;
	/* background-image: url("../images/footer.png");  */
	width: 90%;
	min-height: 40vh;
	/* background-size: 100% 100%; */
	padding-left: 8%;
	padding-right: 8%;
	background-color: #f0f6ed;
	border-radius: 50px;
}

.footer_area{
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 6% 0 5% 0;
}

.footer_box{
	width: 25%;
	margin: 1%;
}

.footer_brand {
	text-align: center;
	line-height: 25px;
}

.footer_brand h1{
	font-size: 26px;
	padding-bottom: 20px;
}

.footer_brand p{
	font-size: 14px;
	padding-bottom: 15px;
}

.footer_social_links {
	 padding:20px 5px 10px 0px;; 
}

.footer_social_links a{
	padding: 0 10px;
	font-size: 30px;
	border-radius: 5px;
	z-index: 10;
}

.footer_social_links a:hover{
	color: darkgreen;
}

.footer_company {
	text-align: center;
	width: 25%;
	margin: 1%;
}
.footer_company h1{
	font-size: 20px;
	padding-bottom: 15px;
}
.footer_company li{
	list-style: none;
}
.footer_company  li{
	padding: 10px;
}
.footer_company  li a{
	text-decoration: none;
	color: black;
}
.footer_company  li a:hover{
	color: darkgreen;
	font-weight: bold;
}

.footer_services{
	text-align: center;
	width: 25%;
	margin: 1%;
}

.footer_services h1{
	font-size: 20px;
	padding-bottom: 15px;
}
.footer_services li {
	list-style: none;
}
.footer_services  li{
	padding: 10px;
}
.footer_services  li a{
	text-decoration: none;
	color: black;
}
.footer_services  li a:hover{
	color: darkgreen;
	font-weight: bold;
}

.footer_address {
	text-align: center;
	width: 25%;
	margin: 1%;
}
.footer_address h1{
	font-size: 20px;
	padding-bottom: 15px;
}

.footer_address p{
	font-size: 16px;
	padding-bottom: 15px;
}

footer{
	margin-top: 10px;
	height: 50px;
	width: 100%;
	background: white;;
	/* text-align: ; */
}
footer p{
	text-align: center;
	padding: 20px;
	font-weight: bold;
}


@media screen and (max-width: 1200px){
    label.logo{
		font-size: 23px;
	}
	
	label.logo img{
		width: 180px;
	}
	
	nav{
		width: 100%;
	}
	
	nav ul{
		margin: 0;
	}

	nav ul li{
		margin: 0;
	}

	nav ul li a{
		font-size: 15px;
	}
	
/**********************************/
	.Hero_section{
		margin: 80px 6% 2% 6%;
	}

	.left_area h1{
		line-height: 40px;
		letter-spacing: 1px;
		padding-bottom: 18px;
	}

	.left_area p{
		font-size: 15px;
		padding-bottom: 18px;
		margin-bottom: 18px;
	}
	.right_area{
		width: 50%;
		margin: 20px;
	}
	.hero_social_links {
		 padding: 15px 0; 
		 margin-top: 50px;
	}
	a.btn_hero{
		padding: 10px;
	}

	.hero_social_links i{
		font-size: 26px;
	}
	
/**********************************/
	.about_section{
		margin: 0 6%;
	}
	
	.about_image{
		margin: 0;
		width: 50%;
	}
	
	.content_area {
		width: 50%;
		margin: 0;
	}
	
	.about_content{
		padding: 2%;
	}

	.about_content h1 {
		line-height: 30px;
		font-size: 30px;
		padding: 8px;
	}

	.about_content h2 {
		font-size: 16px;
		font-weight:bold;
		padding: 15px 0px 10px 10px;
	}

	.about_content p {
		line-height: 24px;
		font-size: 16px;
		text-align: justify; 
		padding: 5px;
	}

/************** our team **********************/
	.our_team{
		width: 88%;
		margin:1% 6%;
	}

/*************** services *********************/
	.our_services{
		margin:1% 6%;
		text-align: center;
	}

	.our_services p{
		padding-top: 16px;
	}
	
	.profile_area {
		padding-top: 15px;
	}
	
/**************************************/
	.Gridlove_section{
		margin: 1% 0%; 
	}
	
	.number-title h1{
		font-size: 30px;
		line-height: 35px;
	}

	.description_sidelove p{
		line-height:22px;
	}

/**************************************/
	.profile_image img {
		/* width: 90%; */
	}	

/**************************************/
	.news_letter{
		width: 88%;
		margin: 2% 6%;
	}
	.sub_input{
		width: 70%;
	}
	.right_area_box h1{
		font-size: 25px;
		padding: 10px 0;
	}
/**************************************/
	.footer{
		margin:0 1%;
		width: 98%;
	}
/**************************************/
	.product_section{
		margin: 80px 6% 2% 6%;
		width: 88%;
	}
	.product_page{
		margin-top: 3rem;
	}
	.product_top{
		width: 58%;
	}
	.product_description h1{
		font-size: 28px;
	}

	.product_description p{
		font-size: 16px;
	}
	.product_order{
		margin-top: 20px;
	}

	.product_order h2{
		font-size:26px;
	}

	.product_order a{
		padding: 10px 20px;
	}

	.product_order span{
		padding: 10px;
		font-size: 16px;
	}
	.product_items h1{
		font-size: 28px;
	}
	.product_items li{
		font-size: 15px;
	}
	.product_divider {
		width: 85px;
	}
	.product_video video {
		width: 280px;
		height: 200px;
	}
	.product_video h1{
		font-size: 28px;
	}
	.product_video h2{
		font-size: 22px;
	}
	.carousel-container {
		width: 42%;
		height: 45%;
	}
}

@media screen and (max-width: 990px){	
    label.logo{
		font-size: 25px;
	}
	
	label.logo img{
		width: 150px;
	}
	
	nav{
		width:100%;
	}
	
	nav ul{
		margin: 0;
	}

	nav ul li{
		margin: 0;
	}

	nav ul li a{
		font-size: 12px;
	}
	
/**********************************/
	.Hero_section{
		margin: 80px 4% 2% 4%;
	}

	.left_area h1{
		font-size: 30px;
		letter-spacing: 0px;
		padding-bottom: 16px;
	}

	.left_area p{
		padding-bottom: 16px;
		margin-bottom: 16px;
	}
	.right_area{
		width: 50%;
		margin: 20px;
	}
	.hero_social_links {
		 padding: 10px 0; 
		 margin-top: 40px;
	}
	
	.hero_social_links i{
		font-size: 24px;
	}
		
/**************************************/
	.content_area {
		display: block;
		width: 100%;
		margin: 1% 0 2% 0;
	}

	.about_image{
		width: 100%;
		text-align: center;
	}

	.about_image_1{
		width: 75%;
		margin-bottom: 10px;
	}
	
	.about_section{
		display: block;
		margin: 0 4%

	}
	
	.about_content{
		width: 100%;
		margin: 2%;
	}

/**************** services *********************/
	.our_services{
		margin:1% 0%;
	}
	
	.service_item {
		width:27%; 
		margin: 2% 3%;
	}
	
/***************** how it work ********************/
	.Gridlove_section{
		margin: 1% 0%;
		padding: 2%;		
	}
	
	.main_grid_love{
		width: 100%;
	}
	
	.left_girdlove{
		width: 80%;
	}

	.description_sidelove{
		margin-left: 60px;
		margin-top: -45px;
		margin-bottom: 20px;
		text-align: justify;
	}
		
	.description_sidelove{
		width: 90%;
	}

	.right_sidelove{
		width: 96%;
		text-align: center;
		margin:2% 0;
	}

	.right_sidelove img{ 
		width: 80%; 
	}

/***********************************/
	.our_team{
		width: 92%;
		margin:1% 4%;
	}
	
	.main_profile {
		margin: 3%;
		width: 30%;
	}

	.profile_image img {
		/* width: 90%; */
	}	
	
/***********************************/
	.news_letter{
		width: 92%;
		margin: 2% 4%;
	}
	.right_area_box h1{
		font-size: 23px;
		padding: 10px 0;
	}
	.right_area_box p{
		font-size: 15px;
	}
/***********************************/
	.footer{
		margin:0 1%;
		width: 98%;
	}
	.footer_brand h1{
		font-size: 22px;
	}

	.footer_brand p{
		font-size: 14px;
	}
	.footer_social_links i{
		padding: 0 5px;
		font-size: 20px;
	}
	.footer_company h1{
		font-size: 18px;
	}
	.footer_company ul{
		font-size: 14px;
	}
	.footer_services h1{
		font-size: 18px;
	}
	.footer_services ul{
		font-size: 14px;
	}
	.footer_address h1{
		font-size: 18px;
	}

	.footer_address p{
		font-size: 14px;
	}
/***********************************/
	.product_section{
		margin: 80px 4% 2% 4%;
		width: 92%;
	}
	.product_top{
		width: 60%;
	}
	.product_description h1{
		font-size: 25px;
	}

	.product_description p{
		font-size: 15px;
		line-height: 23px;
		margin: 2% 0;; 
	}
	.product_order{
		margin-top: 10px;
	}

	.product_order h2{
		font-size:22px;
	}

	.product_order a{
		padding: 8px 16px;
		font-size: 14px;
	}

	.product_order span{
		padding: 10px;
		font-size: 14px;
	}
	.product_items h1{
		font-size: 25px;
	}
	.product_items li{
		font-size: 14px;
	}
	.product_divider {
		width: 85px;
	}
	.product_video video {
		width: 280px;
		height: 200px;
	}
	.product_video h1{
		font-size: 25px;
	}
	.product_video h2{
		font-size: 18px;
	}
	.carousel-container {
		width: 44%;
		height: 40%;
	}
}

@media screen and (max-width: 890px){
    .checkbtn{
		display: block;
	}
	
	ul{
		position: fixed;
		width: 100%;
		height: 100vh;
		background: #2c3e50;
		top: 80px;
		left: -100%;
		text-align: center;
		transition: all .5s ease;
	}
	nav{
		width: 100%;
	}
	nav ul li{
		display: block;
		margin:50px 0;
		line-height: 30px;
	}
	
	nav ul li a{
		font-size: 18px;
		color: white;
	}
	nav a:hover{
		background: black;
		color: white;
		padding:20px;
		border: 2px solid yellow;
		border-radius:10px;
	}
	
	#check:checked ~ ul{
		left: 0;
	}
	
	#hidden{
		position: absolute;
		left : 0px;
		top: 80px;
		width: 100%;
		background-color: #DFE7B4;
		display: flex;
		flex-direction: column;
		align-items: center;

	}
/**********************************/
	
	.Hero_section{
		border-radius: 30px;
		margin: 80px 4% 2% 4%;
		flex-direction: column;
		height: auto;
		/* background-image: none; */
		background-color: #fff3d7;
		border-radius: 30px;
	}

	.left_area{
		order: 2;
		flex: block;
		justify-content:center;
		align-items:center;
		width: 100%;
	}

	.left_area h1{
		text-align: center;
		letter-spacing: 1px;
		padding-bottom: 25px;
	}

	.left_area p{
		font-size: 18px;
		text-align: center;
	}

	a.btn_hero{
		margin-left: 35%;
	}

	a.btn_hero:hover{
		padding: 10px;
	}

	.right_area{
		order: 1;
		width: 100%;
		margin-left: 12%;
	}

	.right_area img{
		border-radius:10%;
		width: 90%;
		height:auto;
	}

	.hero_social_links {
		text-align: center;
	}

	.hero_social_links a{
		padding: 10px;
		background: none;
		border: none;
	}

/***********************************/
	.about_section{
		margin: 0 3%;
	}

	.about_image{
		margin: 0;
		width: 100%;
	}

	.about_image_1{
		width: 75%;
		border-radius: 20px;
	}
	
	.content_area {
		display: block;
		width: 100%;
		margin: 2% 0 2% 0;
	}
	
	.about_content{
		width: 100%;
		margin: 0;
	}

/**************** services *********************/
	.our_services{
		margin:1% 3%;
	}
	
	.service_item {
		float: left;
		width: 28%; 
		height:auto; 
		margin: 2% 2%;
		padding:2px;
		border-radius:10px;
	}

	.service_item p {
		font-size: 14px;
		padding: 2px;
	}

	.service_item h2{
		font-size:	20px;
	}
/***********************************/
	.right_sidelove img{ 
		width: 90%;;
	}
/***********************************/
	.our_team{
		width: 94%;
		margin:1% 3%;
	}
	
	.main_profile {
		margin: 3%;
		width: 35%;
	}

	.profile_image img {
		/* width: 90%; */
	}	
/***********************************/
	.news_letter{
		width: 94%;
		margin: 2% 3%;
	}
	.main_box{
		flex-direction: column;
	}
	.left_area_box{
		width: 100%;
		margin: 2%;
		text-align: center;
	}
	.left_area_box img{
		width: 75%;
	}
	.right_area_box{
		width: 100%;
		margin: 2%;
		text-align: center;
	}
	.right_area_box h1{
		font-size: 32px;
	}
	.right_area_box p{
		font-size: 16px;
	}
/***********************************/
	.footer{
		margin:0 1%;
		width: 98%;
	}
	.footer_brand h1{
		font-size: 20px;
	}
	.footer_company h1{
		font-size: 16px;
	}
	.footer_company li{
		font-size: 13px;
	}
	.footer_services h1{
		font-size: 16px;
	}
	.footer_services li{
		font-size: 13px;
	}
	.footer_address h1{
		font-size: 16px;
	}
	.footer_address p{
		font-size: 13px;
	}
/**************************************/
	.product_section{
		margin: 80px 3% 2% 3%;
		width: 94%;
	}
	.product_top{
		width: 59%;
	}
	.product_description h1{
		font-size: 20px;
	}

	.product_description p{
		font-size: 13px;
		margin: 1% 0;; 
	}
	.product_order{
		margin-top: 8px;
	}

	.product_order h2{
		font-size:18px;
	}

	.product_order a{
		padding: 7px 10px;
		font-size: 13px;
	}

	.product_order span{
		padding: 6px;
		font-size: 13px;
	}
	.product_items h1{
		font-size: 20px;
	}
	.product_items li{
		font-size: 13px;
	}
	.product_divider {
		width: 60px;
	}
	.product_video video {
		width: 280px;
		height: 200px;
	}
	.product_video h1{
		font-size: 20px;
	}
	.product_video h2{
		font-size: 15px;
	}
	.carousel-container {
		width: 45%;
		height: 40%;
	}
}

@media screen and (max-width: 700px){
    .our_services{
		margin:1% 3%;
	}

	.service_item {
		display: block;
		width:90%; 
		margin:3% 3%;
		/* padding: 2%; */
	}

	.service_item img{
		width: 50%;
		padding: 1px;;
	}

	.service_item p {
		font-size: 16px;
	}

	.service_item h2{
		font-size: 25px;
	}

/**************************************/
	.Gridlove_section{
		margin: 1% 0%;
	}
/**************************************/
	.our_team{
		width: 94%;
		margin: 1% 3%;
	}
	
	.main_profile {
		margin: 3%;
		width: 50%;
	}

	.profile_image img {
		/* width: 90%; */
	}
/**************************************/
	.right_area_box h1{
		font-size: 25px;
	}
/**************************************/
	.footer{
		background-image: none; 
		height: 530px;
		background: #f0f6ed;
		border-radius: 50px;
	}
	.footer_area{
		display: block;
	}
	.footer_box{
		float: left;
		width: 40%;
		margin: 1% 5%;
	}
	.footer_brand h1{
		font-size: 24px;
	}
	.footer_brand p{
		font-size: 16px;
	}
	.footer_brand i{
		font-size: 24px;
	}

	.footer_company{
		float: left;
		width: 40%;
		margin: 1% 5%;
	}

	.footer_company h1{
		font-size: 20px;
	}

	.footer_company li{
		font-size: 16px;
	}

	.footer_services{
		float: left;
		width: 40%;
		margin: 1% 5%;
	}

	.footer_services h1{
		font-size: 20px;
	}
	.footer_services li{
		font-size: 16px;
	}

	.footer_address{
		float: left;
		width: 40%;
		margin: 1% 5%;
	}
	.footer_address h1{
		font-size: 20px;
	}
	.footer_address p{
		font-size: 16px;
	}

/*************************************/
	.product_section{
		margin: 80px 3% 2% 3%;
		width: 94%;
	}
	.product_page{
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 1% 0;
		width: 100%;
	}
	.product_top{
		width: 100%;
	}
	.product_description h1{
		font-size: 28px;
	}
	.product_description p{
		font-size: 16px;
		margin: 2% 0;; 
	}
	.product_order{
		margin-top: 20px;
	}
	.product_order h2{
		font-size:22px;
	}
	.product_order a{
		padding: 10px 30px;
		font-size: 15px;
	}
	.product_order span{
		padding: 10px;
		font-size: 15px;
	}
	.product_bottom{
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.product_items{
		margin-top: 15px;
		width:100%;
		height: auto;
		order: 2;
	}
	.product_items h1{
		font-size: 28px;
	}
	.product_items li{
		font-size: 15px;
	}
	.product_divider {
		width: 80px;
	}
	.product_video{
		margin-top: 15px;
		width: 100%;
		height: 100%;
		order: 1;
	}
	.product_video video {
		width: 96%;
		height: auto;
	}
	.product_video h1{
		font-size: 28px;
	}
	.product_video h2{
		font-size: 20px;
	}
	.carousel-container {
		position: relative;
		top: 0;
		left: 0;
		margin: 1% auto;
		width: 100%;
		height: 60vh;
	}
}


@media screen and (max-width: 600px){
    .footer{
		height:auto;
	}
	.footer_area{
		display: block;
	}
	.footer_box{
		float: none;
		width: 70%;
		margin: 0 15% 5% 15%;
	}

	.footer_company{
		float: none;
		width: 70%;
		margin: 0 15% 5% 15%;
	}

	.footer_services{
		float: none;
		width: 70%;
		margin: 0 15% 5% 15%;
	}

	.footer_address{
		float: none;
		width: 70%;
		margin: 0 15% 5% 15%;
	}
	section .thumbnail{
	    width: 100%;
	}

	section .thumbnail img{
	    width: 100%;
	    height: AUTO;
	}
	.product-details h2{
	    font-size: 1.5rem;
	}
	section.m_container .m_thumbnail{
	    width: 70%;
	}
	.month{
	    padding: 1.5rem 2.3rem;
	}

	.month h2{
	    font-size: 1.6rem;
	}

}


@media screen and (max-width: 480px){
	.description_sidelove{
		margin-left: 20%;
		margin-top: -45px;
		margin-bottom: 20px;
		text-align: justify;
	}
}


/**************** comming up Page *****************/
.commingupImg {
	margin-top: 80px;
	text-align: center;
}

.commingupImg img{
	width:500px;
	margin-left: auto;
	margin-right: auto;
}

@media screen and (max-width: 680px){
	.commingupImg img{
		width:300px;
		
	}
}