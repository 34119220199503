@import url('https://fonts.googleapis.com/css?family=Lato|ZCOOL+KuaiLe&display=swap');
/* https://www.youtube.com/watch?v=BwhTKJFpKSw */

.loaderbackground{
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
}

.loader{
    height: 50px;
    transform-origin: bottom center;
    animation: rotate 3s linear infinite;
}

.circle{
    display: inline-block;
    background-color: #fff8ce;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    transform: scale(0);
    animation: grow 1.5s linear infinite;
    margin: -10px;
}

.circle:nth-child(2){
    background-color: #DFE7B4;
    animation-delay: 0.75s;
}

@keyframes rotate {
    to{
        transform: rotate(360deg);
    }
}

@keyframes grow {
    50%{
        transform: scale(1);
    }
}


/* Loader2 */

.loader2{
    font-family: 'ZCOOL KuaiLe' cursive;
    font-size: 40px;
    color: palevioletred;
}

.loader2::after{
    content: '\2026';
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
    animation: dots steps(4, end) 2s infinite;
    width: 0px;
}

@keyframes dots {
    to{
        width: 1.25em;
    }
}