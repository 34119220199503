.weekdataContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
    margin-top: 5%;
    padding-bottom: 3%;
}

.IconContainer{
    display: grid;
    grid-template-columns: 2fr 1fr;
}
/* .weatherImage{
    width: 100px;
    height: 100px;
} */

.nightImage{
    width: 50px;
}

.weatherImage img{
    width: 100px;
}

.weaterCard{
    text-align: center;
}

.hourlyContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.locationSetting{
    margin: 3%;
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.locationSelect{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #FFF7CD;
    border-radius: 25px;
    padding: 3%;
}

.IBMWeatherContent{
    text-align: center;
}

.moredailyinfo{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.dayWeatherData{
    padding-bottom: 3%;
}