#city{
	background-color: #fff; 
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-position: 98% center;
	background-size: 15px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/* .image_details p{ margin:0; padding: 0;} */

.main_search_wrapper{
	margin: 0 8%;
	margin-top: 80px;
	width: 84%;
}
.search_heading h1{
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
	margin: 0.7rem 0;
	font-size: 2.6rem;
	padding: 1% 1% 0 1%;
}
.search_wrapper{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}
.search_wrapper h1{
	font-size: 2.3rem;
	padding: 1rem 0;
    padding: 0.3rem 0 1rem 0;
}
.search_wrapper p{
	font-size: 18px;
}
.search_filter{
	width: 30%;
	height: auto;
	padding: 1% 0 1% 1%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.search_section_1, .search_section_2{
	width: 100%;
}
.search_timing i{
	font-size: 2.5rem;
	margin-left: 2rem;
	margin-bottom: 1.5rem;
	color: #fff;
	background-color: black;
	border-radius: 50px;
	padding: 1px 3px;
}
.search_demand i{
	font-size: 2.5rem;
	margin-left: 2rem;
	margin-bottom: 1.5rem;
	color: #000;
}
.search_form{
	width: 100%;
	height: auto;
}
form.search_corp_form{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.each_search_field{
	width: 100%;
	margin-bottom: 1%;
}
.each_search_field label{
	width: 100%;
	font-size: 18px;
	letter-spacing: 2px;
}
form.search_corp_form select{
	width: 100%;
	padding: 0.4rem 0.5rem;
	margin-bottom: 1%;
	border-radius: 5px;
	border: none;
	box-shadow: 1px 3px 5px 1px rgb(154, 153, 153);
}
.search_content{
	width: 68%;
	height: auto;
	padding: 1% 0 1% 1%;
	display: flex;
	flex-direction: column;
}
.each_content{
	width: 100%;
	height: auto;
	display: flex;
	margin-bottom: 4%;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	padding: 3%;
	background: #fff8ce;
	border-radius: 20px;
}
.search_image{
	width: 230px;
	height: auto;
}
.search_image img{
	width: 100%;
	height: auto;
	border-radius: 20px;
}
.image_details{
	width: 65%;
	height: auto;
}
i.heart_icon_black{
	position: relative;
	top: 0;
	left: 90%;
	font-size: 2rem;
	color: red;
}
.heart_icon_red{
	position: relative;
	top: 0;
	left: 90%;
	font-size: 1.5rem;
	color: red;
	background-color: transparent;
    background-repeat: no-repeat;
    border: none;
}
.image_details h5{
    font-family: 'Arapey', serif;
	font-size: 2rem;
	letter-spacing: 0.2rem;
}
.image_details p{
	font-size: 1rem;
    /* line-height: 20px; */
}
.image_details p i{
	font-size: 2rem;
	color: #386dd7;
	margin: 0 1rem 0 0.3rem;
}
i.emot{
	position: relative;
	left: 2%;
	background: rgb(230, 132, 5);
	border-radius: 50px;
	padding: 1px 2px;
	margin: 0 auto;
}
.read_more{
	font-size: 1rem;
	padding: 0.5rem 2rem;
	text-align: end;
	margin: 2%;
	position: relative;
	bottom: 0;
	left: 65%;
	border-radius: 50px;
	border: none;
	color: white;
	background: #FB9F00;
	box-shadow: 0px 1px 1px 0px gray;
	cursor: pointer;
}
.read_more:hover{
	background: rgba(222, 165, 65, 0.5);
    text-decoration: none;
}


.searchBtn{
	background-color:#FB9F00;
    border-radius: 90px;
    border: none; 
    color: white;
    padding: 8px 30px;
    font-size: 20px;
    text-align: center;
    margin-top: 3%;
}

@media screen and (max-width: 1200px){
    .main_search_wrapper{
		margin: 0 2%;
		margin-top: 80px;
		width: 96%;
	}
	.main_search_wrapper > h1{
		margin: 0.5rem 0;
		font-size: 2.3rem;
	}
	.search_wrapper h1{
		font-size: 2rem;
	}
	.search_wrapper p{
		font-size: 18px;
	}
	.search_timing i{
		font-size: 2.2rem;
		margin-left: 1.5rem;
	}
	.search_demand i{
		font-size: 2.2rem;
		margin-left: 1.5rem;
	}
	.search_wrapper label{
		font-size: 1.3rem;
	}
	.search_form form select{
		width: 100%;
	}
	.each_content{
		padding: 2% 2%;
	}
	i.heart_icon_black{
		font-size: 2.2rem;
	}
	i.heart_icon_red{
		font-size: 2.2rem;
	}
	.image_details h5{
		font-size: 2rem;
	}
	.image_details p{
		font-size: 1rem;
		padding: 0 0 0.7rem 0;
        /* line-height: 7px; */
	}
	.image_details p i{
		font-size: 1.6rem;
	}
	.read_more{
		font-size: 1.2rem;
		padding: 0.5rem 1.8rem;
	}
}

@media screen and (max-width: 990px){	
    .main_search_wrapper{
		margin: 0 1%;
		margin-top: 80px;
		width: 98%;
	}
	.search_wrapper h1{
		font-size: 1.8rem;
	}
	.search_wrapper p{
		font-size: 1.2rem;
	}
	.search_timing i{
		font-size: 2rem;
		margin-left: 1.3rem;
	}
	.search_demand i{
		font-size: 2rem;
		margin-left: 1.3rem;
	}
	.search_wrapper label{
		font-size: 1.2rem;
	}
	.each_content{
		padding: 2% 2%;
	}
	i.heart_icon_black{
		font-size: 2rem;
	}
	i.heart_icon_red{
		font-size: 2rem;
	}
	.image_details h5{
		font-size: 2rem;
		letter-spacing: 0.1rem;
        padding-left: 0.6em;
	}
	.image_details p{
		font-size: 1rem;
		/* padding: 0 0 0.6rem 0; */
        /* line-height: 7px; */
        padding-left: 1.5em;
	}
	.image_details p i{
		font-size: 1.6rem;
	}
	.read_more{
		font-size: 1.1rem;
		padding: 0.4rem 1.6rem;
	}
	
}


@media screen and (max-width: 890px){
    .main_search_wrapper{
		margin: 0 1%;
		margin-top: 80px;
		width: 98%;
	}
	.main_search_wrapper > h1{
		font-size: 2.6rem;
		padding: 1%;
		text-align: center;
	}
	.search_wrapper{
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.search_wrapper h1{
		font-size: 2.3rem;
		padding: 1rem 0;
	}
	.search_wrapper p{
		font-size: 1.6rem;
		padding: 0.3rem 0 1rem 0;
	}
	.search_filter{
		width: 100%;
		padding: 1%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.search_section_1{
		width: 40%;
	}
	.search_section_2{
		width: 60%;
		margin-top: 3%;	
	}
	.each_search_field label{
		width: 60%;
		font-size: 24px;
	}
	.search_content{
		width: 100%;
		padding: 1%;
	}

    .image_details h5{
        padding-left: 0.8em;
		font-size: 2em;
	}
	.image_details p{
        padding-left: 1.5em;
		font-size: 1rem;
		/* line-height: 1px; */
	}

	.image_details p i{
		font-size: 2rem;
	}


}

@media screen and (max-width: 700px){
    .search_section_1{
		width: 40%;
	}
	.search_section_2{
		width: 60%;
		margin-top: 4%;
	}
	.search_content{
	width: 100%;
	padding: 1%;
	}
	.each_content{
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2%;
	}
	.search_image{
		width: 100%;
		height: 180px;
		text-align: center;
	}
	.search_image img{
		width: auto;
		height: 180px;
	}
	.image_details{
		width: 100%;
        text-align: center;
	}
	i.heart_icon_black{
		font-size: 2.5rem;
	}
	i.heart_icon_red{
		font-size: 2.5rem;
	}
	.image_details h5{
		text-align: center;
        padding-left: 1em;
		font-size: 2em;
	}
	.image_details p{
		font-size: 1.2rem;
		/* line-height: 7px; */
	}

	.image_details p i{
		font-size: 2rem;
	}
	i.emot{
		padding: 1px 1px;
	}
	.read_more{
		font-size: 1.2rem;
		padding: 0.6rem 2.5rem;
		position: relative;
		bottom: 0;
		left: 0%;
	}

}

@media screen and (max-width: 600px){
    .search_filter{
		width: 100%;
		padding: 1%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.search_section_1{
		width: 100%;
		text-align: center;
	}
	.search_section_2{
		width: 100%;
		margin-top: 4%;
	}
	.search_content{
		width: 100%;
		padding: 1%;
	}
	.each_content{
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2%;
	}
	.search_image{
		width: 100%;
		height: 180px;
		text-align: center;
	}
	.search_image img{
		width: auto;
		height: 180px;
	}
	.image_details{
		width: 100%;
        text-align: center;
	}
	i.heart_icon_black{
		font-size: 2.5rem;
	}
	i.heart_icon_red{
		font-size: 2.5rem;
	}
	.image_details h5{
		text-align: center;
		font-size: 2rem;
	}
	.image_details p{
		font-size: 1.3rem;
		letter-spacing: 0.1rem;
        /* line-height: 7px; */
	}
	.image_details p i{
		font-size: 2rem;
       
	}
	i.emot{
		padding: 1px 2px;
	}
	.read_more{
		font-size: 1.2rem;
		padding: 0.5rem 2.3rem;
		position: relative;
		bottom: 0;
		left: 0%;
	}

}

@media screen and (max-width: 480px){
    .search_filter{
		width: 100%;
		padding: 1%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.search_section_1{
		width: 100%;
		text-align: center;
	}
	.search_section_2{
		width: 100%;
		margin-top: 4%;
	}
	.search_content{
		width: 100%;
		padding: 1%;
	}
	.each_content{
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2%;
	}
	.search_image{
		width: 100%;
		height: 200px;
		text-align: center;
	}
	.search_image img{
		width: 60%;
		height: 200px;
	}
	.image_details{
		width: 100%;
	}
	i.heart_icon_black{
		font-size: 2.5rem;
	}

	.heart_icon_red{
		position: relative;
		top: 0;
		left: 45%;
		font-size: 1.5rem;
		color: red;
	}

	i.heart_icon_red{
		font-size: 2.5rem;
	}
	.image_details h5{
		text-align: center;
		font-size: 2rem;
	}
	.image_details p{
		font-size: 1rem;
		letter-spacing: 0.1rem;
	}
	.image_details p i{
		font-size: 2rem;
	}
	i.emot{
		padding: 1px 2px;
	}
	.read_more{
		font-size: 1rem;
		padding: 0.5rem 2.3rem;
		position: relative;
		bottom: 0;
		left: 0%;
	}
	
}

.cropListContainer{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.cropListContainer h3{
    font-family: 'Arapey', serif;
    font-size: 50px;
    color: black;
    line-height: 70px;
    margin-bottom: 3%;
}

.cropContainer{
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.formContainer{
    text-align: center;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}



