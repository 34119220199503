#city{
	background-color: #fff; 
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-position: 98% center;
	background-size: 15px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}


.signup_wrapper{
	margin: 1% 8%;
	width: 84%;
	margin-top: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1% 0;
	background-repeat: no-repeat;
	background-size: 100%;
	background-attachment: scroll;
	background-position: top center;
	background-origin: border-box;
}
.signup_heading h1{
	font-weight: 500;
	font-size: 45px;
	letter-spacing: 3px;
}
.main_signup_form{
	margin: 0 0 0 0;
	width: 70%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.left_signup_form{
	width: 50%;
	height: 700px;
	border-radius: 30px 0 0 30px;

}
.left_signup_form img{
	width: 100%;
	height: 700px;
	border-radius: 30px 0 0 30px;
}
.right_signup_form{
	width: 50%;
	height: 700px;
	border: 1px solid black;
	border-radius: 0 30px 30px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background: rgba(63, 85, 82, 1);
}
.signup_logo img{
	width: 100%;
	border-radius: 0 30px 0 0;
}
.signup_form{
	width: 90%;
	color: white;
}
.signup_form form{
	width: 100%;
	text-align: center;
}
.signup_form form label{
	display: block;
	text-align: left;
    font-family: 'Quicksand', sans-serif;
    line-height: 10px;
    font-size: 20px;
}

.signup_form form select{
	width: 100%;
	padding: 3px;
}
.half_field{
	width: 50%;
	float: left;
	padding: 7px 10px;
}
.signup_form form p{
	font-size: 18px;
}

@media screen and (max-width: 1200px){

.signup_wrapper{
    margin: 1% 7%;
    width: 86%;
    margin-top: 80px;
    background-size: 120%;
}
.signup_heading h1{
    letter-spacing: 1px;
}
.signup_form{
    width: 95%;
}
.main_signup_form{
    width: 100%;
}

}

@media screen and (max-width: 990px){	
    .signup_wrapper{
		margin: 1% 6%;
		width: 88%;
		margin-top: 80px;
	}
	.signup_heading h1{
		font-size: 40px;
		letter-spacing: 1px;
	}
	.main_signup_form{
		width: 100%;
		height: 680px;
	}
	.left_signup_form{
		height: 680px;
	}
	.left_signup_form img{
		height: 680px;
	}
	.right_signup_form{
		height: 680px;
	}
}

@media screen and (max-width: 890px){
    .signup_wrapper{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
	}
	.signup_heading h1{
		font-size: 40px;
		letter-spacing: 2px;
	}
	.main_signup_form{
		margin: 0;
		height: 820px;
	}
	.signup_form{
		width: 95%;
	}
	.left_signup_form{
		height: 820px;
	}
	.left_signup_form img{
		height: 820px;
	}
	.right_signup_form{
		height: 820px;
	}
	.half_field{
		width: 100%;
	}
}

@media screen and (max-width: 600px){
    .signup_wrapper{
		background-size: 100% 100%;
		padding: 0;
		background: none;
	}
	.signup_heading h1{
		font-size: 36px;
		letter-spacing: 0px;
	}
	.main_signup_form{
		margin: 3% 0;
		height: 850px;
		display: flex;
		flex-direction: column;
		/* background-image: url(".././images/registerImg1.jpeg"); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-attachment: scroll;
		background-position: top center;
		background-origin: border-box;
		border-radius: 30px;
	}
	.left_signup_form{
		display: none;
	}
	.left_signup_form img{
		height: none;
	}
	.right_signup_form{
		width: 80%;
		height: 830px;
		border-radius: 30px;
	}
	.signup_logo img{
		width: 100%;
		border-radius:30px;
	}
}

@media screen and (max-width: 480px){

    .signup_wrapper{
        background-size: 100% 100%;
        padding: 0;
        background: none;
    }
    .signup_heading h1{
        font-size: 36px;
        letter-spacing: 0px;
    }
    .main_signup_form{
        margin: 3% 0;
        height: 840px;
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-attachment: scroll;
        background-position: top center;
        background-origin: border-box;
        border-radius: 30px;
    }
    .left_signup_form{
        display: none;
    }
    .left_signup_form img{
        height: none;
    }
    .right_signup_form{
        width: 80%;
        height: 820px;
        border-radius: 30px;
    }
    .signup_logo img{
        width: 100%;
        border-radius:30px;
    }
}
.container{
    width:100%;
    height:100%; 
    padding-bottom: 80px;
}
.formBackgronContainer{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    padding-top: 5%;
    padding-bottom: 5%;
}

.backendimage{
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    text-align: center;
    width: 100%;
}

.registerBox{ 
    grid-row: 1;
    grid-column: 1;
    z-index: 7;
}

.registerBox h1{
    font-family: 'Arapey', serif;
    text-align: center;
}

.formContainer{
    /* border-style: solid; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #3F5552;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 20px;
}

.loginleftImg img{
    max-width: 100%;
    max-height: 100%;
    border-top-left-radius:18px;
    border-bottom-left-radius:18px;
}

.formbox{
    margin-top: 2%;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    color: white;
}

.leftrightbox{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
}

.inputBox{
    width: 100%;
    height: 32px;
    margin: 0 auto;
    /* border: none; <-- This thing here */
    border:solid 1px #ccc;
    border-radius: 6px;
    font-size: 20px;
}

.formbox label{
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    line-height: 15px;
}

.sighupwith{
    border:none;
    background: none;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 4%;
}


.loginbtn{
    background-color:#FB9F00;
    width: 60%;
    border-radius: 90px;
    border: none; 
    color: white;
    padding: 8px 30px;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
}

.sighupbtn{
    background-color:whitesmoke;
    border-radius: 90px;
    font-size: 20px;
    border: none;
    border:solid 1px #FB9F00;
    color:  #FB9F00;
    padding: 8px 30px;
    width: 60%;

}
