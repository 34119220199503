

.garden_wrapper{
	/* margin: 1% 6%; */
	/* width: 89%; */
	width: 89%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 80px;
	align-items: center;
	/* display: flex;
	justify-content: center;
	
	flex-direction: row;
	padding: 1% 0; */
	/* background-image: url(".././images/undraw_sunny.png");  */
	background-size: 92% 100%;
	background-repeat: no-repeat;
	background-origin: border-box;
	background-position: top center;
	background-attachment: scroll;
}

.garden_detail_wrapper{
	margin: 1% 8%;
	width: 89%;
	margin-top: 80px;
}

.garden{
	/* width: 84%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;	 */
	display: grid;
	grid-template-columns: 2fr 4fr;
	grid-gap: 1em;
}

.left_garden{
	/* width: 100%; */
	height: auto;
	/* display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding: 0 2%; */
}
.garden_profile_image{
	width: 90%;
	height: 200px;
	padding: 1%;
	text-align: center;
}
.garden_profile_image img{
	/* width: 90%;
	height: 200px;
	border-radius: 50%; */
	float: center;
    width:  150px;
    height: 150px;
    object-fit: cover;
	border-radius: 50%;
}

.userlikeImages{
	float: center;
    width:  150px;
    height: 150px;
    object-fit: cover;
}

.garden_profile_status{
	margin-top: 10%;
	width: 100%;
}
.garden_profile_status span{
	display: block;
	margin: 3% 0;
	font-size: 18px;
	letter-spacing: 2px; 
}
.garden_profile_community{
	margin-top: 10%;
	width: 100%;
}
.garden_profile_community a, p{
	display: block;
	margin: 3% 0;
	letter-spacing: 2px;
	font-size: 18px;
}
.garden_profile_forum{
	margin-top: 10%;
	width: 100%;
}
.garden_profile_forum p{
	font-size: 18px;
	letter-spacing: 2px;
}
.right_garden{
	/* width: 100%; */
	height: auto;
	/* display: flex; */
	/* justify-content: center;
	align-items: flex-start;
	flex-direction: column; */
}
.right_garden h1{
	font-size: 40px;
	margin-top: 13%;
	letter-spacing: 2px;
}
.garden_box{
	/* width: 100%; */
	height: auto;
	margin: 2% 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}
.your_garden{
	margin: 6% 0 3% 0;
}
.your_garden > h1{
	font-size: 36px;
}
.garden_details{
	/* width: 800px; */
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2%;
    background: rgba(255, 235, 125, 0.5);
    border-radius: 20px;
    margin-bottom: 5%;
}
.top_garden{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;	
}
.top_garden div{
	width: 46%;
	height: auto;
}
.top_garden_name p{
	font-size: 35px;
	font-weight: 600;
	letter-spacing: 2px;
}
.top_garden_btn button{
	padding: 10px 20px;
	font-size: 18px;
	border-radius: 50px;
	border: 1px solid grey;
	box-shadow: 0px 1px 3px grey; 
	color: #fff;
	background: orange;
	cursor: pointer;
	float: right;
}
.bottom_garden{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 1% 0;
}
.bottom_garden_img{
	width: 25%;
	height: auto;
}
.bottom_garden_img img{
	width: 100%;
	height: 160px;
	border-radius: 20px;
	border: 1px solid lightgrey;
}
.bottom_garden_details{
	width: 70%;
	height: auto;
}
.bottom_garden_details p{
	font-size: 20px;
	margin-bottom: 5%;
}
.bottom_garden_details img{
	margin: 0 5px;
	width: 40px;
	height: 40px;
}
img.line1{
	height: 10px;
	margin-bottom: 15px;
}
.wishing_list{
	/* width: 70%; */
	/* display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; */
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	text-align: center;
}
.wishing_title p{
	font-size: 30px;
	font-weight: 600;
	letter-spacing: 2px;
}
.wishing_images{
	width: 300px;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 1% 0;
}
.wishing_images div{
	width: 90%;
	margin-right: auto;
	margin-left: auto;
	height: auto;
	text-align: center;
}
.wishing_images div img{
	width: 100%;
	height: auto;
	border: 1px solid lightgrey;
	border-radius: 30px;
}

.userCropBox{
	display: grid;
	grid-template-columns: 1fr 1fr;
}


/* _________________________________________________________________ */

.stageCard{
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	width: 85px;

}

.stageCard img{
	margin-left: auto;
	margin-right: auto;
}

.stageContainer{
	text-align:center;
	
}
.in_same_line{
	display: inline-block;
	margin: 5px;
	border: 2px solid orange;
	border-radius: 10px ;
	padding: 2px;
}


@media only screen and (max-width: 1500px){
	.garden_details{
		width: 700px;
		
	}
}


@media only screen and (max-width: 1300px){
	.garden_details{
		width: 550px;
	}
	.wishing_list{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

}

@media only screen and (max-width: 900px){
	.garden_details{
		width: 450px;
	}
	.wishing_list{
		display: grid;
		grid-template-columns: 1fr ;
	}
}	

@media only screen and (max-width: 500px){
	.garden_details{
		width: 350px;
	}

	.garden{
		/* width: 84%;
		height: auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;	 */
		display: grid;
		grid-template-columns: 1fr;
		/* grid-gap: 1em; */
		text-align: center;
	}
	
}