#city{
	background-color: #fff; 
	background-repeat: no-repeat;
	background-origin: padding-box;
	background-position: 98% center;
	background-size: 15px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.main_profile1{
	margin: 1% 8%;
	width: 84%;
	margin-top: 80px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.profile_form{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 70%;
	height: auto;
	border-radius: 20px;
	margin-bottom: 2%;
	padding: 2% 5% 0% 2%;
	background:	#FFF7CD;
}
.each_form{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 3%;
}
.each_form h1{
	font-weight: 500;
	font-size: 50px;
	letter-spacing: 3px;
}
.each_form img{
	border-radius: 50%;
	width: 200px;
	height: 200px;
}
.each_form div{
	width: 50%;
	height: auto;
}
.each_form label{
	font-size: 20px;
	font-weight: 700;
}
div.each_form input, select{
	padding: 8px;
	width: 90%;
	border-radius: 5px;
	border: none;
	box-shadow: 0px 2px 3px 0px black;
	margin-top: 1%;
}
.btn2{
	margin-top: 10px;
	padding: 10px 10px;
}
#each_form h1{
	font-weight: 500;
	font-size: 40px;
}
a.forgot_pass{
	margin-top: 10px;
}

.linkbtn:hover{
	background-color: rgba(250, 169, 5, 0.5);
}

.linkbtn{
    font-weight: 400;
    border: none;
    background-color: #FB9F00;
    font-size: 16px;
    text-align: center;
    border-radius: 25px ;
    color: white;
    padding: 2% 5%;
    margin: 3%;
	float: right;
}

.linkbtn:hover{
	background-color: rgba(250, 169, 5, 0.5);
	text-decoration: none;
	color: white;

}

.editPicBtn{
	border:none;
	background-color:#FFF7CD;
	font-size: 15px;
	color: #FB9F00;
	float: "right"
}

.half_field{
	width: 100%;
	float: left;
	padding: 7px 10px;
}

@media screen and (max-width: 1200px){
	.main_profile1{
		margin: 1% 6%;
		width: 88%;
		margin-top: 80px;
	}
	.profile_form{
		width: 100%;
		padding: 2% 3%;
	}
	h2#reset_password{
		font-size: 40px;
		font-weight: 500;
	}
}

@media screen and (max-width: 990px){	
	.main_profile1{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
	}
	.profile_form{
		width: 100%;
		padding: 2% 2%;
	}
	h2#reset_password{
		font-weight: 500;
		font-size: 40px;
	}
}

@media screen and (max-width: 890px){
	.main_profile1{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		flex-direction: column;
		justify-content: center;
	}
	.profile_form{
		width: 100%;
		padding: 2% 3%;
	}
	.each_form{
		flex-direction: column;
		width: 100%;
	}
	.each_form_1{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	.each_form_1 h1{
		font-weight: 500;
		font-size: 40px;
		letter-spacing: 2px;
		margin-top: 3%;
	}
	#img_profile{
		text-align: center;
	}
	.each_form_1 img{
		border-radius: 100%;
		width: 180px;
		height: 180px;
	}
	span#camera{
		position: relative;
		top: 0;
		left: 0%;
		color: black;
	}
	.each_form div{
		width: 100%;
		margin-bottom: 2%;
	}
	div.each_form input, select{
		width: 100%;
	}

}

@media screen and (max-width: 480px){
	.main_profile1{
		margin: 1% 1%;
		width: 98%;
		margin-top: 80px;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	.profile_form{
		width: 100%;
		padding: 2% 3%;
	}
	.each_form{
		flex-direction: column;
		width: 100%;
	}
	.each_form_1{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}.each_form_1 h1{
		font-weight: 500;
		font-size: 50px;
		letter-spacing: 3px;
	}
	span#camera{
		position: relative;
		top: 0;
		left: 0%;
		color: black;
	}
	#img_profile{
		text-align: center;
	}
	.each_form_1 img{
		border-radius: 100%;
		width: 180px;
		height: 180px;
	}
	.each_form div{
		width: 100%;
		margin-bottom: 2%;
	}
	div.each_form input, select{
		width: 100%;
	}
}